/* eslint-disable jsx-a11y/alt-text */
import Page from "./Page"
import logo from "../../../app/common/contract/Taxcess logo.png";
import headerImg from "../../../app/common/contract/header account agreement.png";
import footerImg from "../../../app/common/contract/footer account agreement.png";
import backgroundImg from "../../../app/common/contract/Header Footer.png";
import IPAImge from "../../../app/common/contract/IPA.png";
import ipa from "../../../app/common/contract/Institute of Public Accountants.png";
import '../../../app/styles/scss/contractsV2/contract-page.css';

const ContractPage = (props) => {
    return (
        <Page className={`contract-page ${props.firstPage ? "" : "long-page"}`}>
            <div className="content">
                {props.children}
            </div>
            <div className="footer-text">Accounts Direct Pty Ltd (ABN 16 155 811 448). Registered Tax Agent 2243 3005. ASIC Agent 44905. Liability limited by a scheme approved under professional standards legislation</div>
            <img className="logo" src={logo} />
            <div className="ipa">
                <img className="ipa-logo" src={IPAImge} /><img className="ipa-word" src={ipa} />
            </div>
            {props.firstPage ?
                <img className="backgroud-image" src={backgroundImg} /> :
                <>
                    <img className="header-image" src={headerImg} />
                    <img className="footer-image" src={footerImg} />
                </>
            }
        </Page>
    )
}

export default ContractPage;