import { Grid } from 'semantic-ui-react';
import InveterviewLabel from '../../../../src/app/styled-element/InterviewLabel';

interface Props {
    firstColumn: string | JSX.Element
    secondColumn?: JSX.Element
    thirdColumn?: JSX.Element
    style?: any,
    isGridStack?: boolean
}

export default function GridRow(props: Props) {

    return (
        <Grid.Row style={{ ...props.style, paddingTop: props.isGridStack ? 0 : '.3rem', paddingBottom: props.isGridStack ? 0 : '.3rem' }} >
            <Grid.Column textAlign='right' className='interview' style={{ paddingBottom: props.isGridStack ? '0 !important' : '.3rem' }} width={7} >{typeof props.firstColumn === "string" ? <InveterviewLabel isMobile={props.isGridStack} content={props.firstColumn} /> : props.firstColumn}</Grid.Column>
            <Grid.Column className='interview' style={{ paddingTop: props.isGridStack ? '0 !important' : '.3rem' }} width={5}>{props.secondColumn}</Grid.Column>
            {props.thirdColumn && <Grid.Column style={{ paddingTop: props.isGridStack ? 0 : '.3rem' }} className='interview' width={4}>{props.thirdColumn}</Grid.Column>}
        </Grid.Row>
    );
}
