import { Card, Icon, Image } from "semantic-ui-react";

const VideoContainer = (props: any) => {
    return (
        <div onClick={() => props.handleClick(props.item.source)} key={'video-' + props.index} className='video-container'>
            <Card fluid>
                <Icon name='play circle' className='play-button' />
                <Image style={{ minHeight: 30 }} className='thumbnail' src={`thumbnail/${props.item.title}.jpg`} wrapped ui={false} />
                <Card.Content style={{ minHeight: 89 }}>
                    <Card.Description>{props.item.title}</Card.Description>
                </Card.Content>
            </Card>
        </div>
    )
}

export default VideoContainer;