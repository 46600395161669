import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStore } from '../../stores/store';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import Version from '../../../features/version/Version';

//#region "Styling"

const StyledSidebar = styled.div<{ isCollapse: boolean, isMobile?: boolean }>`
    z-index: 11 !important;
    position: absolute;
    left: ${p => (p.isCollapse ? '-240px' : '0')};
    bottom: 0;
    top: 45px;
    width: 200px;
    background: #edf7ff !important;;
    border-bottom-left-radius: 20px;
    transition: all 0.5s ease;

    & ul {
        display: ${p => (p.isCollapse ? 'none' : '')};
        padding: 0 10px 10px 15px !important;
        margin-top: 100px !important;
    }

    ${p => (p.isCollapse && `

        & ul li a {
            display: flex !important;
            flex-direction: column !important;
            align-content: center !important;
            justify-content: center !important;
            height: 50px !important;
            margin-bottom: 15px !important;


            & span.icon {
                margin: 0 !important;
                text-align: center !important;
                font-size: 1.7rem !important;
            }

            & span.title {
                font-size: 0.6rem !important;
                text-align: center !important;
                display: none  !important;
            }
        }
    `)};
`;

const StyledProfile = styled.div<{ isCollapse: boolean }>`

        & .user-profile {
            cursor: 'default'
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--primary-text-color);
            font-size: 1rem;
            padding: 1rem 0 1rem 1.5rem;
            font-weight: 600;
            align-content: flex-start;
            justify-content: center;
            margin-top: 40px;

            & .user-details {
                margin-top: 10px;

                & label {
                    font-size: 1.2em !important;
                }
            }

          ${p => (p.isCollapse && `
                padding: .4em;

                & .user-details {
                    font-size: 0.6rem;
                    text-align: center;
                    display: none;
                }
            
            `)};
        }
`;

//#endregion "Styling"

function useOuterClick(callback: any) {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(ev: MouseEvent | TouchEvent) {
            if (innerRef.current && callbackRef.current &&
                !innerRef!.current!.contains!(ev.target)
            ) callbackRef!.current!(ev);

            return false;
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

const SidebarMenu = () => {
    const { commonStore: { isCollapseSideBar, setSubNav, isMobile }, authStore: { user, hasPermission } } = useStore();

    const innerRef = useOuterClick(
        (ev: any) => {
            setSubNav(0);
        }
    );

    return (
        <StyledSidebar
            isCollapse={isCollapseSideBar}
            isMobile={isMobile}
            ref={innerRef}
        >
            <StyledProfile key={user?.displayName} isCollapse={isCollapseSideBar}>
                {/*<div className='user-profile' >*/}
                {/*    <div className='user-photo'>*/}
                {/*        <AvatarImage userId={user?.id!} hasPhoto={user?.hasProfilePicture!} size='tiny' avatar circular />*/}
                {/*    </div>*/}
                {/*    <div className='user-details'>*/}
                {/*        <label className="user-name">{user?.displayName}</label>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </StyledProfile>
            <ul>
                {SidebarData.map((item, index) => {
                    if (hasPermission(item.permission)) {
                        if (item.permission === 'Help' && !hasPermission(SidebarData[index - 1].permission))
                            return <SubMenu item={item} key={item.key} isSettings={true} />;

                        return <SubMenu item={item} key={item.key} isSettings={item.text === 'Settings'} />;
                    }
                })}
                <Version
                    style={{
                        position: "absolute",
                        top: 530,
                    }}
                />
            </ul>
        </StyledSidebar>
    );
}

export default observer(SidebarMenu);