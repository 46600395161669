import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Icon, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/store';

const LoginForm = () => {
    const { authStore } = useStore();
    const history = useHistory();

    return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, { setErrors }) => authStore.forgotPassword(values).catch(e => setErrors({ email: 'Invalid email' }))}
            validationSchema={Yup.object({
                email: Yup.string().required('Email field is required.').email('The Email is not a valid e-mail address.'),
            })}
        >
            {
                ({ handleSubmit, isSubmitting }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: 436,
                            position: 'relative',
                            top: -20,
                            padding: '5.5rem 3em',
                            boxShadow: '0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%)',
                            borderRadius: 5,
                            backgroundColor: '#ffffff',
                            height: 480
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                <Image src={`assets/taxcess-logo.svg`} style={{ width: '26em' }} centered />
                            </div>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <div style={{ textAlign: 'left', marginBottom: 20 }}>
                                    <label style={{
                                        display: 'block',
                                        margin: '0 0 0.28571429rem 0',
                                        color: 'rgba(0, 0, 0, .87)',
                                        fontSize: '.92857143em',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}>Forgot Password</label>
                                </div>
                                <TextInput name='email' placeholder='Email' type='text' />
                                <Grid columns='equal' style={{ alignItems: 'center', marginTop: 70 }}>
                                    <Grid.Column style={{ textAlign: 'left' }}>
                                        <div style={{ color: '#6a7e99', textAlign: 'left', width: 150, borderRadius: 30, height: 42, fontSize: 15, fontWeight: 500, padding: '0.6em 0', cursor: 'pointer' }}>
                                            <Icon color='blue' className='angle left icon' />
                                            <a onClick={() => history.push('/login')}>Back</a >
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button loading={isSubmitting} color='blue' style={{ backgroundColor: 'rgb(21, 189, 254)', borderRadius: '8px', height: '42px' }} content='Reset Password' type='submit' size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default observer(LoginForm);