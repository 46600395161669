import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, Header } from "semantic-ui-react";
import styled from "styled-components";
import { Status } from "../../app/constants/agreementStatus";
import { ClientContract } from "../../app/models/client";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 10 : '1em 2em'};
`;

const ClientContractList = () => {
    const { id } = useParams<{ id: string }>();
    const { contractStore, commonStore, clientStore } = useStore();
    const { contracts, total, setPageNumber, pageNumber, isLoading, setSearchQuery, setClientId, setFilterValues } = contractStore;
    const { get: getClient, isLoading: clientLoading } = clientStore;
    const history = useHistory();

    const [client, setClient] = useState({
        name: '',
        email: '',
        mobileNumber: ''
    })

    useEffect(() => {
        getClient(id).then((client) => {
            setClientId(client.id!);
            setClient({
                name: client.fullName,
                email: client.email,
                mobileNumber: client.mobileNumber
            })
            setFilterValues({ pageNumber: 1, pageSize: 10, searchKeyword: "" })
        })

    }, [setFilterValues]);

    const onClickViewHandler = ({ data }: any) => {
        if (data.status === Status.InProgress)
            history.push('/agreements/interview/bankInformation/' + data.agreementId);
        else
            history.push('/agreements/interview/bankInformation/' + data.agreementId + '/View');
    }

    return (
        <>
            <StyledContainer isMobile={commonStore.isMobile}>
                < PaginatedTable <ClientContract>
                    localization={{
                        header: {
                            actions: 'Options'
                        }
                    }}
                    toolbarHeader={
                        <Grid stackable className='cardContractsDivided'>
                            <Grid.Row divided>
                                <Grid.Column textAlign='center' width={5}>
                                    <Header as='h4'>Name: {client.name}</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={5}>
                                    <Header as='h4'>Email Address: {client.email}</Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={5}>
                                    <Header as='h4'>Mobile Number: {client.mobileNumber}</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                    title="All Contracts"
                    columns={
                        [
                            { title: 'id', field: 'agreementId', hidden: true },
                            { title: 'Year', field: 'year' },
                            { title: 'Status', field: 'status' },
                            { title: 'Net Refund', field: 'netRefund' },
                        ]}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: async (event) => {
                            }
                        }),
                    ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={p => setPageNumber(p)}
                    onSearchChange={query => setSearchQuery(query)}
                    data={contracts}
                    isLoading={isLoading || clientLoading}
                    toolbar={true}
                    search={true}
                    components={
                        {
                            Action: (props) => (
                                <Button
                                    onClick={() => onClickViewHandler(props)}
                                    size="small"
                                    color='blue'
                                    content="View"
                                />
                            ),
                        }
                    }
                />
            </ StyledContainer >
        </>
    )
}

export default observer(ClientContractList);
