/* eslint-disable jsx-a11y/alt-text */
import Container from '../Container';
import ContractPage from '../ContractPage';
import './../../../../app/styles/scss/contractsV2/agreement/agreement-contract.css';
import { useStore } from '../../../../app/stores/store';
import steveSignature from '../../../../app/common/contract/Steve Signature Transparent.png';
import { Agreement } from '../../../../app/models/Agreement';

const AgreementContract = () => {
    const { agreementStore, commonStore: { dateNow }, authStore: { user } } = useStore();
    const { agreement } = agreementStore;

    const round = (value: number, decimals: number) => {
        return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
    }

    const addDollarSign = (value: number | undefined) => {
        return value ? '$' + round(value, 2) : '';
    }

    const computeFee = (agreement: Agreement | null) => {
        let fee = 0;

        if (!agreement)
            return fee;

        fee = agreement.interview.interviewFourthPage.discountedFees;

        if (agreement.interview.interviewThirdPage.paymentOptionId === "3f45d8fa-a0fc-4f34-a0c4-1c9a8cbba809") //IF FEE FROM REFUND
            fee -= agreement.interview.interviewThirdPage.feeFromRefundCharge;

        return addDollarSign(fee);
    }

    const columns = [
        {
            key: "Date:",
            value: dateNow
        },
        {
            key: "Provider Name:",
            value: "Accountant Direct Pty Ltd ABN 16 155 811 448"
        },
        {
            key: "Tax Consultant Email",
            value: user?.email
        },
        {
            key: "Services:",
            value: "As described in the Provider’s invoice"
        },
        {
            key: "Tax Year / Return Year",
            value: agreement?.interview.interviewSecondPage.annualTaxReturnYears
        },
        {
            key: "Customer, you, your",
            value: agreement?.client.fullName
        },
        {
            key: "Customer Postcode",
            value: agreement?.client.postalCode
        },
        {
            key: "Email",
            value: agreement?.client.email
        },
        {
            key: "Account Name",
            value: agreement?.bankInformation.accountName
        },
        {
            key: "BSB",
            value: agreement?.bankInformation.bsb
        },
        {
            key: "Account Number",
            value: agreement?.bankInformation.bankAccount
        },
        {
            key: "Estimated Gross Tax Refund or Payable",
            value: addDollarSign(agreement?.interview.interviewFourthPage.estimatedGrossTaxRefund)
        },
        {
            key: "Fee",
            value: computeFee(agreement)
        },
        {
            key: "Charges",
            value: addDollarSign(agreement?.interview.interviewThirdPage.feeFromRefundCharge)
        },
        {
            key: "Estimated Government Debt",
            value: addDollarSign(agreement?.interview.interviewFourthPage.estimatedGovernmentDebt)
        },
        {
            key: "Estimated Net Refund",
            value: addDollarSign(agreement?.interview.interviewFourthPage.estimatedNetRefund)
        },
        {
            key: "Jurisdiction",
            value: "Queensland, Australia."
        },
    ];
    return (
        <div style={{ position: 'absolute', left: -1500 }}>
            <iframe id='iframe-pdf-agreement' width='100%' height='100%'></iframe>
            <Container id='agreement-pdf' className="agreement-contract">
                <ContractPage firstPage>
                    <div className="header-title">Accounting Client</div>
                    <div className="header-title">Services Agreement</div>
                    <div className="review-section">
                        <div className="paragraph-1">This Agreement governs the terms and conditions under which the Provider provides its professional services to you as our Customer. The first part of the Agreement is the Contract Details, followed by the Legal Terms (Agreement).</div>
                        <div className="paragraph-2">Please review all pages of this Agreement to confirm that all items are true and correct and that you are satisfied with its terms. If you are satisfied, click the 'Begin' button at the top right to direct you so you can electronically sign this Agreement. After the Provider receives this Agreement signed and all items completed we will submit your tax return under the terms of this Agreement. Please keep this Agreement for your records.</div>
                    </div>
                    <table className="details-table">
                        <tr className="header">
                            <th className="title">CONTRACT DETAILS</th>
                            <th></th>
                        </tr>
                        {columns.map(data => {
                            return (
                                <tr key={data.key}>
                                    <td>{data.key}</td>
                                    <td>{data.value}</td>
                                </tr>
                            )
                        })}
                    </table>
                </ContractPage>
                <ContractPage>
                    <div className="header-title">AGREEMENT SUMMARY </div>
                    <div className="row title">
                        <div className="col">
                            <div className="paragraph">
                                The Provider specialises in accounting and tax services. The Customer has engaged the Provider to provide the Services as the Customer’s tax agent under the Tax Agent Services Act 2009 (Cth) (and all subsidiary legislation), authorising the Provider to act on your behalf with the Australian Taxation Office on all matters relating to your personal taxation matters.
                                <br />By signing this Agreement, the Provider accepts the appointment pursuant to the terms and conditions of this Agreement and agrees to provide the Customer with the Services.
                            </div>
                            <div className="paragraph bold">LEGAL TERMS</div>

                            <div className="paragraph bold">1. Definitions and Interpretation</div>

                            <div className="paragraph "><span className="bold ">1.1. Definitions</span></div>

                            <div className="paragraph">The Items specified in the Contract Details have the same meaning as described in the Contract Details, unless otherwise defined below. The following words have these meanings in this Agreement unless the contrary intention appears:</div>

                            <div className="paragraph"><span className="bold">Agreement</span> means the Contract Details and the Legal Terms;</div>

                            <div className="paragraph"><span className="bold">ATO</span> means the Australian Taxation Office;</div>

                            <div className="paragraph"><span className="bold">Business Day</span> means any day excluding Saturday, Sunday, a public holiday in Queensland and/or a Commonwealth public holiday;</div>

                            <div className="paragraph"><span className="bold">Confidential Information</span> means any information that is:</div>

                            <div className="paragraph">(a) Information, ideas forms, specifications, processes, statements, formulae, trade secrets, drawings and data (and copies and extracts made of or from that information and data) that is not in public domain is considered confidential information; and</div>

                            <div className="paragraph">(b) confidential by nature, stipulated as confidential or any other information that would be reasonably considered to be confidential due to the value of the information;</div>

                            <div className="paragraph"><span className="bold">Considerations</span> has the meaning given to it in clause 3.1 of these Legal Terms;</div>
                        </div>
                        <div className="col">
                            <div className="paragraph"><span className="bold">Contract Details</span> means the information in the contract details table and the described Services provided to the Customer by the Provider;</div>

                            <div className="paragraph"><span className="bold">Dispute</span> is defined in clause 8.4(b);</div>

                            <div className="paragraph"><span className="bold">Intellectual Property</span> means the statutory or other proprietary right including but not limited to registered and unregistered copyright, patents, registered designs, and registered and unregistered trademarks;</div>

                            <div className="paragraph"><span className="bold">Intellectual Property Rights</span> means all present and future rights in relation to copyright, trademarks, designs, patents or other proprietary rights, or any rights to registration of such rights, whether created, written, developed or brought to existence by the Provider in the provision of the Services;</div>

                            <div className="paragraph"><span className="bold">Notice</span> is defined in clause 8.4(b);</div>

                            <div className="paragraph"><span className="bold">Online Account</span> means the online account that is opened by Payou (on agreement by you) via the online payments platform provider Zepto pursuant to the Online Account Payments Terms and Conditions;</div>

                            <div className="paragraph"><span className="bold">Online Account Payments Terms and Conditions</span> means the Online Account Payments Terms and Conditions that govern the terms of the Online Account;</div>

                            <div className="paragraph"><span className="bold">Payou</span> means Payou Pty Ltd ACN 651 634 650;</div>

                            <div className="paragraph"><span className="bold">Parties</span> is defined in clause 8.4(c);</div>

                            <div className="paragraph"><span className="bold">Privacy Policy</span> means as applicable the privacy policy on:
                                <span className="block">(a)	the Provider’s website;</span>
                                <span className="block">(b)	Payou’s website; and</span>
                                <span className="block">(c)	Zepto’s website.</span>
                            </div>
                            <div className="paragraph"><span className="bold">Services</span> means the services specified in Item 4 of the Contract Details or as further agreed between the Parties from time to time; and</div>

                            <div className="paragraph"><span className="bold">Zepto</span> means Zepto Payments Pty Ltd ACN 604 057 598 holder of AFSL number 541011.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph"><b>1.2. Interpretation</b></div>

                            <div className="paragraph">In this Agreement:</div>

                            <div className="paragraph">(a)	references to a person include an individual, form or a body, whether incorporated or unincorporated;</div>

                            <div className="paragraph">(b)	clause headings are for references only and shall not form part of this Agreement nor used in the interpretation of this Agreement;</div>

                            <div className="paragraph">(c)	if the time of doing an act or thing under this Agreement falls on a day which is not a Business Day, then the time of doing that act or thing shall be deemed to be the next Business Day;</div>

                            <div className="paragraph">(d)	words in the singular include the plural and vice versa in accordance with the context of which that word is used;</div>

                            <div className="paragraph">(e)	words importing a gender include other genders;</div>

                            <div className="paragraph">(f)	a reference to a clause is a reference to a clause in this Agreement;</div>

                            <div className="paragraph">(g)	a reference to any of the words 'include', 'includes' and 'including' is to be read as if followed by the words "without limitation";</div>

                            <div className="paragraph">h)	a reference to a statute, ordinance, code or law includes regulations and other instruments under it and any consolidations, amendments, re-enactments or replacements of any of them;</div>

                            <div className="paragraph">(i)	a reference to any party include that party's executors, administrators, substitutes, successors and permitted assigns; and</div>

                            <div className="paragraph">(j)	each party has participated in the negotiating and drafting of this document and in the event of ambiguity or a question of interpretation arising, this Agreement is to be construed as if the Agreement was drafted jointly.</div>

                            <div className="paragraph"><span className="bold">2. Commencing and completing the Services</span></div>

                            <div className="paragraph"><span className="bold">2.1. Commencing the Services</span></div>

                            <div className="paragraph">(a)	The Provider will commence the Services on the Date of this Agreement.</div>

                            <div className="paragraph">(b)	This Agreement remains in place until either party terminates this Agreement in writing, or the Services are completed in accordance with clause 2.5.</div>
                        </div>
                        <div className="col">
                            <div className="paragraph"><span className="bold">2.2. Provision of the Services</span></div>

                            <div className="paragraph">(a)	In order for the Provider to provide the Services, the Customer acknowledges that it is obligated to disclose to the Provider all relevant information and material for completion of the Services.</div>

                            <div className="paragraph">(b)	The Customer authorises the Provider to prepare from information supplied by the Customer (or obtained by the Provider as authorised in clause 2.2(e) below) tax returns and other associated documents on the Customer’s behalf, and lodge them with the ATO.</div>

                            <div className="paragraph">(c)	In order for the Provider to provide the Services the Customer acknowledges that it is obligated to disclose to the Provider all relevant information and material for completion of the Services.</div>

                            <div className="paragraph">(d)	The Customer must provide as soon as possible (within 5 Business Days after receiving written notice from the Provider requesting additional information) such additional reasonably requested, relevant information and material for completion of the Services. If no written notice is provided by the Provider, it is implied that as far as the Provider is aware all relevant information and materials have been supplied by the Customer.</div>

                            <div className="paragraph">(e)	In order for the Provider to provide the Services to the Customer, the Customer authorises the Provider to:</div>

                            <div className="paragraph">(i)	obtain, where necessary, further personal information about you from the ATO portal, financial institutions, other businesses and government departments for the purposes of completing your tax return;</div>

                            <div className="paragraph">(ii)	where necessary, respond to any queries made by the ATO in relation to the taxation return prepared on your behalf;</div>

                            <div className="paragraph">(iii)	sign the taxation return and/or any ancillary documents on your behalf based on your instruction; and</div>

                            <div className="paragraph">(iv)	make any application to any relevant party the Provider deems necessary in relation to your taxation return including obtaining at your cost copies of documents requested by the ATO.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph highlight"><span className="bold">(f)	PLEASE NOTE:</span> The calculation of estimated net refund or payable has been based on the information you have supplied to the Provider and current legislation, proposals and information provided by the ATO. This estimate is only a guide and should not be taken as financial or investment advice. Do not rely on this estimate to make any decisions until you have received a notice of assessment from the ATO and/or obtained professional advice. Numerous factors that may affect the accuracy of this assessment including but not limited to:</div>

                            <div className="paragraph highlight">(i) changes in tax legislation and ATO practices and rulings;</div>

                            <div className="paragraph highlight">(ii) any prior year tax assessments that may be applicable;</div>

                            <div className="paragraph highlight">(iii) any audit or action by the ATO; and</div>

                            <div className="paragraph highlight">(iv) adjustments relating to other Government Departments (i.e. the Department of Human Services or HECS debts).</div>

                            <div className="paragraph"><span className="bold">2.3. Rejection of the Services</span></div>

                            <div className="paragraph">(a) Notwithstanding clause 2.3 of this Agreement, the Customer may not accept the completed Services provided by the Provider, if the Customer deems the completed Services have not been completed in accordance with the description provided in Item 4 of the Contract Details.</div>

                            <div className="paragraph">(b) If the Services are deemed incomplete by the Customer, the Provider agrees to rectify the Services within fourteen (14) days. If the Services are not rectified or are still deemed to be not in accordance as described within Item 4 of the Contract Details, the Customer may cancel the Services.</div>

                            <div className="paragraph"><span className="bold">2.4. Alterations to the Services</span></div>

                            <div className="paragraph">If the Provider is required to alter the description of the Services, the Provider must confirm the instructions with the Customer in writing.</div>

                            <div className="paragraph"><span className="bold">2.5. Completion of Work</span></div>

                            <div className="paragraph">(a) Once the Services are completed by the Provider it is deemed that the Services are completed to the Customer’s satisfaction and the fees and charges as disclosed in the Contract Details are due and payable to the Provider.</div>

                            <div className="paragraph">(b) This Agreement will end on the date that occurs later, either:</div>
                        </div>
                        <div className="col">
                            <div className="paragraph">(i) when the tax returns and other associated documents are lodged with the ATO; or</div>

                            <div className="paragraph">ii) the tax refund has been received in the Online Account and has been disbursed in accordance clause 3.2(f) of this Agreement.</div>

                            <div className="paragraph"><span className="bold">2.6. Australian  Consumer Laws</span></div>

                            <div className="paragraph">(a) Certain legislation, including the Australian Consumer Law, may imply warranties, conditions, or obligations, or impose remedies or guarantees that cannot be fully excluded, restricted, or modified. These statutory provisions may, for instance, impose non-excludable guarantees concerning the quality of the rendering of services with due care and skill, with specific remedies available to consumers for breaches of such guarantees.</div>

                            <div className="paragraph">(b) The above Services come with guarantees that cannot be excluded under the Australian Consumer Law. For major failures with the service, you are entitled:</div>

                            <div className="paragraph">(i) to cancel this Agreement with us and receive a refund for the Consideration payable for the unused portion of the Services; or</div>

                            <div className="paragraph">(ii) keep the Agreement with us and receive compensation for any other reasonably foreseeable loss or damage.</div>

                            <div className="paragraph">(c)	If the failure does not amount to a major failure, you are entitled to have problems with the Service rectified in a reasonable time and, if this is not done, to cancel your Agreement and obtain a refund for the unused portion of the Service.</div>

                            <div className="paragraph">(d)	Please note that under no circumstances will the Provider be liable to you for any tax liability that you incur to the ATO as a result of the Services provided to you.</div>

                            <div className="paragraph">(e)	The Provider will under no circumstances be liable to you for any tax liability that you incur to the ATO as a result of the Services provided to you. If an error has been made by the Provider on the documents that has increased your tax liability, the Provider will correct such documents and will appropriately advise the ATO. The Provider will not charge any fees for any corrections that it needs to make under this clause as a result of its own error.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph"><span className="bold">3. Payment for the Services</span></div>

                            <div className="paragraph"><span className="bold">3.1. Consideration</span></div>

                            <div className="paragraph">For the provision of the Services by the Provider, the Customer agrees to pay the Provider fees and charges as set out in the Contract Details (the 'Consideration').</div>

                            <div className="paragraph"><span className="bold">3.2.	Time and Method for Payment</span></div>

                            <div className="paragraph">(a) The Customer will make Payment of the Consideration pursuant to the terms and method outlined in the Contract Details.</div>

                            <div className="paragraph">(b) The Online Account Payments Terms and Conditions will apply when an Online Account is opened by Payou (on agreement by you) via the online payments platform provider Zepto pursuant to the Online Account Payments Terms and Conditions, in order for your taxation refund to be received and the processing of your monies to make payments on your behalf in accordance with clause 3.2(f) below.</div>

                            <div className="paragraph">(c) When an Online Account is created for you, you will be legally bound by the Online Account Payments Terms and Conditions.</div>

                            <div className="paragraph">(d) The provision and operation of the Online Account is subject to Zepto’s Direct Entry Solution Terms and Target Market Determination which can be found on Zepto’s website at https://www.zepto.com.au/legal/. You should review these for your information.</div>

                            <div className="paragraph">(e) If you agree to pay the Consideration from the proceeds of your tax refund, you irrevocably authorise the Provider (once it receives your tax refund in the Online Account) to apply on your behalf, the proceeds of your tax refund in the following order:</div>

                            <div className="paragraph">i) firstly, to pay any fees or charges due to the Provider in respect of any Service provided to you; .</div>

                            <div className="paragraph">(ii)	secondly, pay a nominated third party as per your instruction; and</div>

                            <div className="paragraph">(iii) thirdly, deposit the residual in a bank account nominated by you.</div>

                            <div className="paragraph">(f) Subject to this clause 3.2, You authorise the Provider to provide to the ATO on your behalf such authority as the ATO requires enabling the payment of any money due to you be paid to your Online Account. For this purpose, you authorise the Provider to sign any document on your behalf and appoint the Provider as your attorney for such purpose.</div>
                        </div>
                        <div className="col">
                            <div className="paragraph">(g) You acknowledge that you are obliged to pay the Consideration owing to the Provider. If you do not pay the amount immediately after the Service has been provided or you have not authorised for payment to be made from your tax refund pursuant to clause 3.2(f) , we may obtain the fees using your Direct Debit Request, or we may demand payment in writing.</div>

                            <div className="paragraph">(h)	If you still do not pay after our written demand under clause 3.2(h), then the Provider may take such action as it thinks necessary, including, where appropriate, the use of a direct debit authority, or deduction from your tax refund, or both, to recover the amount owing to the Provider.</div>

                            <div className="paragraph">(i)	The Provider may take enforcement action to recover any unpaid amount remaining and owing to it.</div>

                            <div className="paragraph"><span className="bold">3.3. Goods and Services Tax</span></div>

                            <div className="paragraph">Unless otherwise stated, all amounts, including out of pocket expenses, expressed and described on or in connection with this Agreement and/or its Contract Details, are listed in Australian Dollars and are GST inclusive, being goods and services tax as defined in A New Tax System (Goods and Services Tax) Act 1999 (Cth), inclusive amounts. Where the services are provided outside Australia, GST is inapplicable.</div>

                            <div className="paragraph"><span className="bold">4. Default</span></div>

                            <div className="paragraph"><span className="bold">4.1. Payment Default</span></div>

                            <div className="paragraph">You acknowledge that if you are in a payment default under this Agreement, you may also be required to pay all reasonable enforcement expenses we reasonably incur or expend resulting from any payment default.</div>

                            <div className="paragraph"><span className="bold">4.2. Legal Fees and Disbursements</span></div>

                            <div className="paragraph">(a)	While we will attempt to minimise these expenses at all times, your actions may contribute to you incurring additional costs if we are required to take further action to protect our rights under this Agreement. In the case of legal fees and disbursements where we undertake such work ourselves, these may be payable on the higher of a full indemnity basis or a solicitor and own client basis. These costs will not exceed our reasonable enforcement expenses including internal costs.</div>

                            <div className="paragraph">(b) These amounts may be debited to your account, and they are payable when they are debited.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph"><span className="bold">5. Copyright and Intellectual Property Rights</span></div>

                            <div className="paragraph"><span className="bold">5.1. Intellectual Property Rights</span></div>

                            <div className="paragraph">(a)	The Provider recognises that all Customer Intellectual Property is the property of the Customer, and the Provider will take all such steps as practicable to ensure that the Intellectual Property will vest in and remain vested in the Customer.</div>

                            <div className="paragraph">(b)	The Provider warrants that the Provider owns the Intellectual Property Rights in the Intellectual Property delivered to the Customer in the provision of the Services.</div>

                            <div className="paragraph"><span className="bold">5.2. Intellectual Property Licence</span></div>

                            <div className="paragraph">(a)	The Customer grants to the Provider a non-exclusive, transferable, royalty-free, perpetual licence to use the Intellectual Property owned by the Customer which have been used in completion of the Services, that were already in existence prior to commencing the Services.</div>

                            <div className="paragraph"><span className="bold">6. Termination of this Agreement</span></div>

                            <div className="paragraph"><span className="bold">6.1. For the Customer</span></div>

                            <div className="paragraph">(a)	The Customer cannot terminate this Agreement if the events described in clause 2.5 have occurred or are in process of being satisfied.</div>

                            <div className="paragraph">(b)	Subject to clause 6.1(a), the Customer may terminate this Agreement with the Provider for any breach of this Agreement by providing fourteen (14) days written notice to the Provider. At the Customer's discretion, the Customer may allow the Provider to remedy the breach within fourteen (14) days' notice, or another timeframe as the Customer elects, and in being satisfied with the remedy of the breach by the Provider, the Customer will not terminate this Agreement.</div>

                            <div className="paragraph">(c)	Subject to clause 6.1(a), the Customer may terminate this Agreement by providing thirty (30) days written notice to the Provider of the Customer's intent to terminate this Agreement.</div>

                            <div className="paragraph">(d)	Subject to clause 6.1(a), if The Customer terminates this Agreement for reasons other than a material breach of this Agreement by the Provider, the Customer will pay the Provider for the portion of the Services completed to date and time of cancellation.</div>

                            <div className="paragraph"><span className="bold">6.2. For the Provider</span></div>
                        </div>
                        <div className="col">
                            <div className="paragraph">(a)	The Provider may terminate this Agreement by providing thirty (30) days written notice to the Customer of the Provider's intent to terminate this Agreement.</div>

                            <div className="paragraph">(b)	The Provider may terminate this Agreement for a breach by the Customer of this Agreement by providing fourteen (14) days' written notice of the breach to The Customer. During the fourteen (14) day notice period, The Customer reserves the right to remedy the breach. If The Customer remedies the breach which was the cause of the notice, this Agreement will not be terminated at the lapse of the fourteen (14) days on the notice's basis.</div>

                            <div className="paragraph">(c)	Upon providing notice of the Provider's intent to terminate this Agreement, the Provider agrees to continue providing the Services until the cessation of the notice period unless otherwise instructed by the Customer to cease work.</div>

                            <div className="paragraph">(d)	If the Provider provides notice of intent to terminate this Agreement, The Customer will pay for the work which has been done and at the time the Agreement is terminated, will pay any outstanding works completed between the provision of the notice to terminate and the cessation of this Agreement. If the Customer elects to have the Provider cease work upon receiving notification of the Provider's intent to terminate, the Customer will only be liable to pay amounts outstanding on work completed by the Provider to the date the request to cease work was issued by the Customer.</div>

                            <div className="paragraph"><span className="bold">7. Liability and waivers</span></div>

                            <div className="paragraph"><span className="bold">7.1. Liability by the Customer</span></div>

                            <div className="paragraph">(a)	The total liability of the Customer to the Provider for damage, loss or reliance shall be limited to any outstanding payments (if any) for Services completed by the Provider and not paid by the Customer, except where the relevant liability arises from our unlawful actions, fraud, negligence or wilful default.</div>

                            <div className="paragraph">(b)	The Provider expressly understands and agrees that the Customer shall not be liable to the Provider for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by the Provider, however caused and under any theory of liability; including, but not limited to any loss of profit (incurred directly or indirectly), any loss of goodwill or business reputation, death or personal injury and any other intangible loss.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph"><span className="bold">7.2. Liability by the Provider</span></div>

                            <div className="paragraph">(a)	The total liability of the Provider to the Customer for damage, loss or reliance shall be liability limited by a scheme approved under Professional Standards Legislation applicable to accountants.</div>

                            <div className="paragraph">(b)	The Customer expressly understands and agrees that the Provider shall not be liable to the Customer for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by the Provider, however caused and under any theory of liability; including, but not limited to any loss of profit (incurred directly or indirectly), any loss of goodwill or business reputation, death or personal injury and any other intangible loss.</div>

                            <div className="paragraph"><span className="bold">7.3. Waivers</span></div>

                            <div className="paragraph">(a)	A waiver of any right, power or remedy under this agreement must be in writing signed by the party granting it. A waiver is only effective in relation to the particular obligation or breach in respect of which it is given. It is not to be taken as an implied waiver of any other obligation or breach or as an implied waiver of that obligation or breach in relation to any other occasion.</div>

                            <div className="paragraph">(b)	The fact that a party fails to do, or delays in doing, something the party is entitled to do under this agreement does not amount to a waiver.</div>

                            <div className="paragraph"><span className="bold">8. Privacy</span></div>

                            <div className="paragraph"><span className="bold">8.1. Your consent</span></div>

                            <div className="paragraph">(a)	You acknowledge that your personal information will be collected, used and handled in accordance with:</div>

                            <div className="paragraph">(i)	the Privacy Policy of the Provider and with respect of this Agreement; and</div>

                            <div className="paragraph">(ii) the Privacy Policy of each of the Provider, Payou and Zepto with respect of the Online Account; and which you confirm that you have been given an opportunity to read and you can access them on each of their websites.</div>

                            <div className="paragraph">(b)	You acknowledge that personal information provided to the Provider by you is provided for the purposes of the Provider preparing and lodging on your behalf a taxation return with the ATO. As a result, personal information that you provide will be disclosed to relevant parties for the purposes of preparing and lodging the taxation return and for other associated purposes.</div>
                        </div>
                        <div className="col">
                            <div className="paragraph">(c)	You also acknowledge and consent that some of the personal information provided by you may be used by regulatory authorities for the purposes of conducting compliance audits on the Provider.</div>

                            <div className="paragraph">(d)	You consent to us disclosing information about your affairs to a third party affiliate or agent, who may or may not be located offshore, for the purpose of completing your tax return.</div>

                            <div className="paragraph">(e)	You acknowledge that personal information provided by you may also be used for the purposes of opening and operating the Online Account. As a result, personal information that you provide will be disclosed to relevant parties for the purposes of opening and operating the Online Account (as applicable) and for other associated purposes.</div>

                            <div className="paragraph"><span className="bold">8.2. Your request</span></div>

                            <div className="paragraph">You further acknowledge that the Provider will, on your reasonable request and subject to its obligations under the Privacy Act 1988 (Cth) may provide you with details of your personal information held by the Provider.</div>

                            <div className="paragraph"><span className="bold">9. General matters</span></div>

                            <div className="paragraph"><span className="bold">9.1. Notices</span></div>

                            <div className="paragraph">(a)	By agreeing to this Agreement, you consent to receive communications and notices by hand, postal mail, SMS and electronically. This includes but is not limited to communication via email, SMS or postal mail, as applicable. Such electronic communications and notices shall be considered valid and effective, and you agree that they satisfy any legal requirement for written communication.</div>

                            <div className="paragraph">(b)	Any communication or notice sent by hand, mail or electronically in accordance with this clause is received, for communication via:</div>

                            <div className="paragraph"><span className="bold">SMS</span> Upon being successfully delivered to the mobile number provided by the recipient.</div>

                            <div className="paragraph"><span className="bold">Email</span> Upon being successfully delivered to the email address provided by the recipient.</div>

                            <div className="paragraph"><span className="bold">Mail</span> Five business days after the date of posting, as evidenced by a valid postal receipt.</div>
                        </div>
                    </div>
                </ContractPage>
                <ContractPage>
                    <div className="row">
                        <div className="col">
                            <div className="paragraph"><span className="bold">9.2. Dispute Resolution & Mediation</span></div>

                            <div className="paragraph">(a)	If a dispute arises out of or relates to the terms of this Agreement, either party may not commence any legal proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</div>

                            <div className="paragraph">(b)	A party to this Agreement claiming a dispute (the 'Dispute') has arisen under the terms of this Agreement, must give written notice to the other party detailing the nature of the Dispute, the desired outcome and the action required to settle the Dispute (the 'Notice').</div>

                            <div className="paragraph">(c)	On receipt of the Notice by the other party, the parties to this Agreement (the 'Parties') must within seven days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree.</div>

                            <div className="paragraph">(d)	If for any reason whatsoever, 21 days after the date of the Notice, the Dispute has not been resolved the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Institute of Public Accountants or his or her nominee and attend a mediation.</div>

                            <div className="paragraph">(e)	It is agreed that mediation will be held in the Jurisdiction.</div>

                            <div className="paragraph">(f)	The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing, undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation.</div>

                            <div className="paragraph">(g)	All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the fullest extent possible, must be treated as "without prejudice" negotiations.</div>

                            <div className="paragraph">(h)	If thirty (30) days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either party may ask the mediator to terminate the mediation and the mediator must do so.</div>

                            <div className="paragraph">(h)	In the event that the Dispute is not resolved at the conclusion of the mediation, either party may institute legal proceedings concerning the subject matter of the Dispute thereafter.</div>
                        </div>
                        <div className="col">
                            <div className="paragraph"><span className="bold">9.3. No partnership or agency</span></div>

                            <div className="paragraph">Nothing contained or implied in this Agreement will create or constitute, or be deemed to create or constitute, a partnership between the parties. A party must not act, represent or hold itself out as having authority to act as the agent of or in any way bind or commit the other parties to any obligation.</div>

                            <div className="paragraph"><span className="bold">9.4. Governing Law & Jurisdiction</span></div>

                            <div className="paragraph">(a)	This Agreement is governed by the laws of the Jurisdiction.</div>

                            <div className="paragraph">(b)	In the event of any dispute arising out of or in relation to the Services, the Provider agrees that the exclusive venue for resolving any dispute shall be in the courts of Australia, situated in the Jurisdiction.</div>

                            <div className="paragraph"><span className="bold">9.5. Severance</span></div>

                            <div className="paragraph">Any provision of this Agreement, which is prohibited or unenforceable in any jurisdiction will, as to such jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions of this Agreement or affecting the validity or enforceability of such provisions in any other jurisdiction.</div>

                            <div className="paragraph"><span className="bold">9.6. Assignment</span></div>

                            <div className="paragraph">The Provider may delegate, assign, novate and/or subcontract any obligations pursuant to this Agreement to any person without the Customer's consent.</div>

                            <div className="paragraph"><span className="bold">9.7. No waiver</span></div>

                            <div className="paragraph">The failure or delay by either party to enforce any rights or provisions outlined in this Agreement shall not be construed as a waiver of such rights or provisions. The exercise of any right or remedy by either party shall not preclude the exercise of any other right or remedy available to them under this Agreement or applicable law.</div>
                            <div className="line-title"><span className="bold">Executed as an Agreement on</span></div>
                            <div className="bold info">{dateNow}</div>
                            <div className="paragraph line"></div>
                            <div className="line-title exec"><span className="bold">Executed by the Customer</span></div>
                            <div className="flex">
                                <div></div>
                                <div className="bold client-name">{agreement?.client.fullName}</div>
                            </div>
                            <div className="flex">
                                <div className="line">Signature of Customer</div>
                                <div className="line">Name of Customer</div>
                            </div>

                            <div className="line-title exec"><span className="bold">Executed for the Provider by its authorised representative:</span></div>
                            <div className="flex">
                                <div></div>
                                <div className="bold">Stephen Burns. Director.</div>
                            </div>
                            <img className="steve-sign" src={steveSignature} />
                            <div className="flex">
                                <div className="line">Signature</div>
                                <div className="line">Name</div>
                            </div>
                        </div>
                    </div>
                </ContractPage>
            </Container>
        </div>
    );
}

export default AgreementContract;