import { useField } from 'formik';
import { Checkbox, Form } from 'semantic-ui-react';

interface Props {
    disabled?: boolean;
    onClick?: Function;
    name: string;
    label?: string;
    style?: any
}

export default function ToggleCheckBox(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left', marginTop: 15, ...props.style, }}>
            <Checkbox
                key={props.name}
                checked={field.value}
                onClick={(event, data) => {
                    const { checked } = data;
                    let isChecked = checked ? true : false;
                    if (props.onClick) props.onClick(props.name, isChecked, helpers.setValue);
                    helpers.setValue(isChecked);
                }}
                disabled={props.disabled}
                label={props.label}
            />
            {meta.touched && meta.error ? (
                <p style={{ marginLeft: 5, marginTop: 5, color: 'red' }}>{meta.error}</p>
            ) : null}
        </Form.Field>
    )
}