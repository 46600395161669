import { useField } from 'formik';
import { Form, Select } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    labelColor?: string;
    loading?: boolean;
    onChange?: any
    disabled?: boolean;
    clearable?: boolean;
}

export default function FormSelect(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={{ color: props.labelColor ? props.labelColor : 'black' }}>
                {props.label}
            </label>
            <Select
                search
                style={{ border: '1.5px solid #b9c9d9' }}
                disabled={props.disabled}
                clearable={props.clearable}
                options={props.options}
                value={field.value}
                onChange={(e, d) => {
                    if (props.onChange) {
                        props.onChange(e, d);
                    }

                    helpers.setValue(d.value)
                }}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
                loading={props.loading}
            />
            {meta.touched && meta.error ? (
                <p style={{ marginLeft: 5, marginTop: 5, color: 'red' }}>{meta.error}</p>
            ) : null}
        </Form.Field>
    );
}
