import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import { Button, Card, Checkbox, Segment, Table } from "semantic-ui-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import { useStore } from "../../app/stores/store";
import { Role } from "../../app/models/role";
import StyledHeader from "../../app/styled-element/StyledHeader";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import LoadingComponet from "../../app/layout/LoadingComponent";

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;

const RoleForm = () => {
    const { roleStore, lookupStore, commonStore } = useStore();
    const { createRole, loadingInitial, updateRole, loadRole, setLoadingInitial, isLoading } = roleStore;
    const { loadModules } = lookupStore;
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [role, setRole] = useState({
        id: '',
        name: '',
        description: '',
        default: false,
        moduleIds: Array<string>()
    });

    const [checkedModule, setCheckModule] = useState({
        checkAll: false,
        modules: Array<{
            id: string,
            name: string,
            check: boolean
        }>()
    })

    const [title, setTitle] = useState('');

    function handleSubmit(value: Role) {
        let role = {
            ...value,
            moduleIds: checkedModule.modules.filter(m => m.check).map(m => { return m.id })
        }

        if (value.id.length === 0) {
            createRole(role).then(() => {
                history.push(`/roles`);
            });
        }
        else {
            updateRole(role).then(() => {
                history.push(`/roles`);
            });
        }
    }

    const handleSelectAllModule = (state: boolean) => {
        setCheckModule({
            checkAll: state,
            modules: checkedModule.modules.map(m => {
                return {
                    ...m,
                    check: state
                }
            })
        });
    };

    const handleSelectModule = (id: string, state: boolean) => {
        console.log(id, state);
        if (state) {
            let selectedAll = checkedModule.modules.filter(m => m.check === true).length + 1 === checkedModule.modules.length;
            setCheckModule({
                checkAll: selectedAll,
                modules: checkedModule.modules.map(m => {
                    if (id !== m.id)
                        return m;

                    return {
                        ...m,
                        check: state
                    }
                })
            });

        }
        else {
            setCheckModule({
                checkAll: state,
                modules: checkedModule.modules.map(m => {
                    if (id !== m.id)
                        return m;

                    return {
                        ...m,
                        check: state
                    }
                })
            });
        }
    };

    useEffect(() => {
        setLoadingInitial(true);
        loadModules().then(modules => {
            if (id) {
                setTitle('Edit Role');
                loadRole(id).then(role => {
                    if (role) {
                        setRole({
                            id: role.id,
                            name: role.name,
                            description: role.description,
                            default: role.default,
                            moduleIds: role.moduleIds
                        });

                        setCheckModule({
                            checkAll: modules.length === role.moduleIds.length,
                            modules: modules.map(m => {
                                return {
                                    id: m.id,
                                    name: m.name,
                                    check: role.moduleIds.some(id => id === m.id)
                                }
                            })
                        });
                    }
                })
            }
            else {
                setCheckModule({
                    checkAll: false,
                    modules: modules.map(m => {
                        return {
                            id: m.id,
                            name: m.name,
                            check: role.moduleIds.some(id => id === m.id)
                        }
                    })
                });

                setTitle('Add Role');
                setLoadingInitial(false);
            }
        });


    }, [loadModules]);


    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <StyledContainer isMobile={commonStore.isMobile}>
            <Card style={{ width: '100%' }}>
                <Card.Content className='cardDivided'>
                    <Card.Header style={{ color: '#2185d0' }} content={title} />
                </Card.Content>
                <Card.Content>
                    <Formik
                        enableReinitialize
                        initialValues={role}
                        onSubmit={(values) => handleSubmit(values)}
                        validationSchema={Yup.object({
                            name: Yup.string().required('Role field is required.').max(128, 'Name field must be at most 128 characters'),
                            description: Yup.string().required('Description field is required.').max(128, 'Name field must be at most 128 characters')
                        })}
                    >
                        {
                            ({ handleSubmit, isSubmitting, errors }) => (
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <TextInput disabled={role.default} placeholder='Role' name='name' label='Role' />
                                    <TextArea disabled={role.default} placeholder='Description' name='description' label='Description' rows={3} />
                                    <label style={{ marginTop: 15 }} >Select Module</label>
                                    <Table compact celled definition>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell as={() => {
                                                    if (!role.default) {
                                                        return <div style={{
                                                            backgroundColor: '#f7f7f7',
                                                            borderLeft: '1px solid rgba(34,36,38,.1)',
                                                            borderTop: '1px solid rgba(34,36,38,.1)',
                                                            borderBottom: '1px solid rgba(34,36,38,.1)',
                                                            padding: '.7em .7em'
                                                        }}>
                                                            <Checkbox
                                                                toggle
                                                                checked={checkedModule.checkAll}
                                                                onClick={(event, data) => {
                                                                    const { checked } = data;
                                                                    handleSelectAllModule(checked ? checked : false);
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    else return <Table.HeaderCell></Table.HeaderCell>

                                                }}
                                                />
                                                <Table.HeaderCell>Modules</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {checkedModule.modules.map(module => {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell collapsing>
                                                            <Checkbox
                                                                disabled={role.default}
                                                                checked={module.check}
                                                                onClick={(event, data) => {
                                                                    const { checked } = data;
                                                                    handleSelectModule(module.id, checked ? checked : false);
                                                                }}
                                                                id={module.id}
                                                                toggle
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>{module.name}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </Table.Body>
                                    </Table>
                                    {!role.default ?
                                        <>
                                            <Button style={{ marginTop: 15 }} floated='right' content='Cancel' onClick={() => history.push('/roles')} size='large' />
                                            <Button style={{ marginTop: 15 }} loading={isLoading} floated='right' content='Save' primary type='submit' size='large' />
                                        </> :
                                        <Button style={{ marginTop: 15 }} floated='right' content='Back' onClick={() => history.push('/roles')} size='large' />
                                    }
                                </Form>
                            )
                        }
                    </Formik>
                </Card.Content>
            </Card>
        </StyledContainer>


    );
};

export default observer(RoleForm);
