import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Role } from "../../app/models/role";
import { User } from "../../app/models/user";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const UserList = () => {
    const { roleStore, commonStore: { isMobile }} = useStore();
    const {
        roles, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterRoles, filterValues, deleteRole } = roleStore;
    const swal = withReactContent(Swal);

    const history = useHistory();

    const routeChange = (path: string) => {
        history.push(path);
    }

    const handleDelete = (roleId: string) => {
        swal.fire({
            title: `Are you sure you want to delete this role?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRole(roleId);
            }
        });
    }

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterRoles();
    }, [filterRoles]);

    return (
        <>
            < StyledContainer >
                < PaginatedTable <Role>
                    isMobile={isMobile}
                    title="Roles"
                    rightButton={
                        {
                            label: "Add Role",
                            onClick: () => {
                                routeChange('roles/create');
                            }
                        }
                    }
                    columns={
                        [
                            { title: 'id', field: 'id', hidden: true },
                            { title: 'Role', field: 'name' },
                            { title: 'Description', field: 'description' },

                        ]}
                    actions={
                        [
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'Edit Role',
                                onClick: async (event) => {
                                }
                            }),
                        ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={
                        p => {
                            setPageNumber(p);
                        }}
                    onSearchChange={
                        query => {
                            setSearchQuery(query);

                        }}
                    data={roles}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    components={
                        {
                            Action: (props) => (
                                <>
                                    <Button
                                        style={{ display: props.data.default? "none": "block"}}
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                routeChange(`Roles/Edit/${data.id}`)
                                            }}
                                        size="small"
                                        basic
                                        color='blue'
                                        content="Edit"
                                    />
                                    <Button
                                        style={{ display: props.data.default ? "none" : "block" }}
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                handleDelete(data.id);
                                            }}
                                        size="small"
                                        basic
                                        color='blue'
                                        content="Delete"
                                    />
                                </>
                            ),
                        }
                    }
                />
            </ StyledContainer >
        </>
    )
}

export default observer(UserList);
