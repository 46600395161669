import React, { ChangeEvent, useState } from 'react';
import { Input } from 'semantic-ui-react';

const SearchToolbar = (props: any) => {
    const [query, setQuery] = useState(props.searchText);

    const handleChange = (e: ChangeEvent) => {
        let element = e.target as HTMLInputElement;
        const value = element.value ?? '';
        setQuery(value);
    }

    return (
        <div style={props.style}>
            <Input value={query}
                style={{ width: 245 }}
                onChange={handleChange}
                onKeyPress={(ev: React.KeyboardEvent) => {
                    if (ev.key === 'Enter') {
                        let element = ev.target as HTMLInputElement;
                        const value = element.value ?? '';
                        props.dataManager.searchText = value;
                        props.onSearchChanged(value ? value : '');
                        ev.preventDefault();
                    }
                }}
                iconPosition='left'
                icon='search'
                placeholder='Search...'
            />
        </div>
    );
}

export default SearchToolbar;