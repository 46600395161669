import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { Module, Title, BusinessStructure, PaymentOption, AgreementStatus } from '../models/lookup';

export default class LookupStore {
 

    modules: Module[] = [];
    modulesLoading = false;

    titles: Title[] = [];
    titlesLoading = false;

    businessStructures: BusinessStructure[] = [];
    businessStructuresLoading = false;

    paymentOptions: PaymentOption[] = [];
    paymentOptionsLoading = false;

    agreementStatus: AgreementStatus[] = [];
    agreementStatusLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get getPlaymentOptions() {
        if (this.paymentOptions.length === 0) this.loadPaymentOptions();
        return this.paymentOptions;
    }

    get getBusinessStructures() {
        if (this.businessStructures.length === 0) this.loadbusinessStructures();
        return this.businessStructures;
    }


    loadPaymentOptions = async () => {
        if (this.paymentOptions.length !== 0) return this.paymentOptions;

        this.paymentOptionsLoading = true;
        let paymentOptions = await agent.Lookups.getPaymentOptions();

        runInAction(() => {
            this.paymentOptions = paymentOptions;
            this.paymentOptionsLoading = false;
        });

        return paymentOptions;
    }

    loadbusinessStructures = async () => {
        if (this.businessStructures.length !== 0) return this.businessStructures;

        this.businessStructuresLoading = true;
        let businessStructures = await agent.Lookups.getBusinessStructures();

        runInAction(() => {
            this.businessStructures = businessStructures.sort(function (a, b) {
                return a.orderNumber - b.orderNumber;
            });

            this.businessStructuresLoading = false;
        });

        return businessStructures;
    }

    loadModules = async () => {
        if (this.modules.length !== 0) return this.modules;

        this.modulesLoading = true;
        let modules = await agent.Lookups.getModules();

        runInAction(() => {
            this.modules = modules;
            this.modulesLoading = false;
        });

        return modules;
    }

    loadTitles = async () => {
        if (this.titles.length !== 0) return this.titles;

        this.titlesLoading = true;
        let titles = await agent.Lookups.getTitles();

        runInAction(() => {
            this.titles = titles;
            this.titlesLoading = false;
        });

        return titles;
    }

    loadAgreementStatus = async () => {
        if (this.agreementStatus.length !== 0) return this.agreementStatus;

        this.agreementStatusLoading = true;
        let status = await agent.Lookups.getAgreementStatus();

        runInAction(() => {
            this.agreementStatus = status;
            this.agreementStatusLoading = false;
        });

        return status;
    }

    get AgreementOptions() {
        return this.agreementStatus.map(a => {
            return {
                label: a.name,
                value: a.id
            }
        })
    }
}