import { useStore } from "../../../../app/stores/store";
import Container from "../Container";
import PayouPage from "../PayouPage";
import './../../../../app/styles/scss/contractsV2/direct-debit/direct-debit.css';

const TermsAndConditions = () => {
    const { agreementStore, commonStore: { dateNow }, authStore: { user } } = useStore();
    const { agreement } = agreementStore;

    const contractDetails = [
        {
            key: "Date",
            value: dateNow
        },
        {
            key: "Provider Name & ABN",
            value: "Accountant Direct Pty Ltd ABN 16 155 811 448"
        },
        {
            key: "Tax Consultant Email",
            value: user?.email
        },
        {
            key: "Customer, you, your",
            value: agreement?.client.fullName
        },
        {
            key: "Customer Postcode",
            value: agreement?.client.postalCode
        },
        {
            key: "Email",
            value: agreement?.client.email
        },
        {
            key: "Payou, “we”, “us”, or “our”",
            value: "means Payou Pty Ltd ACN 651 634 650."
        },
        {
            key: "Zepto",
            value: "Zepto Payments Pty Ltd ACN 604 057 598 holder of AFSL number 541011."
        },
        {
            key: "Jurisdiction",
            value: "Queensland, Australia",
            className: ""
        },
    ];

    const NextLine = () => <><br /><br /></>;

    return (
        <div style={{ position: 'absolute', left: -1500 }}>
            <Container id='terms-and-conditions-pdf' className="direct-debit">
                <PayouPage>
                    <div className="header-title">Online Account Payments<br />Terms and Conditions</div>
                    <div className="acknowledment">
                        <div className="contents instruction">These User Terms will apply when an Online Account is created for you by Payou (on Your instruction), via the payments platform provider Zepto in order to receive money from your tax refund, to pay any fees or charges due to your Provider in respect of any Service provided to you, to pay any nominated third party as per your DDR instruction and deposit the residual in a bank account nominated by you. The first part of these User Terms is the Contract Details, followed by the Legal Terms.</div>
                        <div className="contents">Please review all pages of these User Terms to confirm that all items are true and correct and that you are satisfied with its terms. By proceeding with these User Terms, you also accept the terms of the attached Direct Debit Request and Direct Debit Request Service Agreement.</div>
                    </div>
                    <div className="sub-header mt-20">CONTRACT DETAILS</div>
                    <div className="fee-table details-table mt-10">
                        <table>
                            <thead>
                                <tr>
                                    <th>ITEM</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contractDetails.map(c => {
                                    return (
                                        <tr key={c.key}>
                                            <td className="details">{c.key}</td>
                                            <td className="details">{c.value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="row terms mt-30">
                        <div className="col">
                            <b>LEGAL TERMS</b>
                            <NextLine />
                            <b>1. DEFINITIONS</b>
                            <NextLine />
                            1.1. The Items specified in the Contract Details above have the same meaning as described in the Contract Details, unless otherwise defined below. The following words have these meanings in these User Terms unless the contrary intention appears:
                            <NextLine />
                            <b>Provider</b> means the Provider named in the Contract Details above.
                            <NextLine />
                            <b>AFSL</b> Australian Financial Services Licence
                        </div>
                        <div className="col">
                            <b>AML/CTF</b> is Anti-Money Laundering and Counter-Terrorism Financing.
                            <NextLine />
                            <b>AML/CTF Act</b> is the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth).
                            <NextLine />
                            <b>AML/CTF Rules</b> is the Anti-Money Laundering and Counter-Terrorism Financing Rules Instrument 2007 (No. 1).
                            <NextLine />
                            <b>APPs</b> is the thirteen (13) Australian Privacy Principles.
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            <b>DDR</b>	means direct debit request.
                            <NextLine />
                            <b>DDRSA</b> means a direct debit request services agreement between Payou and You.
                            <NextLine />
                            <b>Clause</b>	is the Clause(s) of these User Terms.
                            <NextLine />
                            <b>KYC</b> is Know Your Customer and means the same as it is defined under the AML/CTF Act and AML/CTF Rules.
                            <NextLine />
                            <b>OAIC</b> is the Officer of the Australian Information Commissioner.
                            <NextLine />
                            <b>Online Account</b> means the online account that is opened by Payou (upon your instruction and accepting these User Terms) via our online payments platform provider Zepto for You in order for us to process monies received from Your taxation refund and to make payments on your behalf in accordance with your DDR and written instructions.
                            <NextLine />
                            <b>Payou, “we”, “us”, or “our”</b>	means Payou Pty Ltd ACN 651 634 650.
                            <NextLine />
                            <b>Payou’s Website</b> means Payou’s website at payou.com.au
                            <NextLine />
                            <b>Personal Information</b>	means the same as it is defined under the Privacy Act.
                            <NextLine />
                            <b>Privacy Act</b> is the Privacy Act 1988 (Cth).
                            <NextLine />
                            <b>Privacy Complaints</b> are complaints that are related to privacy and privacy practices, including breaches.
                            <NextLine />
                            <b>Privacy Policy</b> means (as applicable) the privacy policy on:
                            (a)	the Provider’s website;<br />
                            (b)	Payou’s website; and<br />
                            (c)	Zepto’s website.
                            <NextLine />
                            <b>Provider</b> means the Provider named in the Contract Details above.
                            <NextLine />
                            <b>Registration Criteria</b> is the set of conditions that must be satisfied before an Online Account will be created for you.
                            <NextLine />
                            <b>Registration Process</b> is the process that will be undertaken to successfully register an Online Account for you in accordance with clause 2.1.
                            <NextLine />
                            <b>Services</b> means the services provided by the Provider to you under the terms of an accounting client service agreement entered into between you and the Provider.
                        </div>
                        <div className="col">
                            <b>Services Agreement</b> means the services agreement entered into between the Provider and You including the signed DDR and DDRSA.
                            <NextLine />
                            <b>User Terms</b> means the means the Contract Details and the Legal Terms.
                            <NextLine />
                            <b>"You" or "Yours"</b> means You the customer of the Provider.
                            <NextLine />
                            <b>Zepto</b> Zepto Payments Pty Ltd ACN 604 057 598 holder of AFSL number 541011.
                            <NextLine />
                            <b>2. OVERVIEW</b>
                            <NextLine />
                            2.1. The Online Account will be created for You by Payou via its payments platform provider Zepto when the following conditions satisfied:
                            <NextLine />
                            (a)	you have a Service Agreement with the Provider; and<br />
                            (b)	you have accepted and signed these User Terms.
                            <NextLine />
                            2.2. The provision and operation of the Online Account is facilitated under Zepto’s Australian Financial Services Licence Number 541011 And also subject to Zepto’s Direct Entry Solution Terms and Target Market Determination which can be found on Zepto’s website at https://www.zepto.com.au/legal/.
                            <NextLine />
                            2.3. When an Online Account is created, You will be legally bound by these User Terms. We recommend that You print a copy of these User Terms for Your records or alternatively You may ask Us for a copy of these User Terms at any time.
                            <NextLine />
                            2.4. These User Terms will be available electronically on Payou’s website and You can contact us for a copy as well.
                            <NextLine />
                            2.5. We may change these User Terms from time to time. If We do, We will notify You and You must read the updated User Terms.
                            <NextLine />
                            2.6. These User Terms are not the terms which apply to the Services themselves and only govern the operation of your Online Account.
                            <NextLine />
                            2.7. These User Terms are in addition to the terms and conditions which govern the Services provided to You by your Provider and the terms of the Service Agreement.
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            <b>3. YOUR ONLINE ACCOUNT</b>
                            <NextLine />
                            3.1. The Online Account will only be created in accordance with clause 2.1 above.
                            <NextLine />
                            3.2. The Online Account created under clause 2.1 will be only used to receive money from your tax refund, to pay any fees or charges due to your Provider in respect of any Service provided to you, to pay any nominated third party as per your DDR instruction and deposit the residual in a bank account nominated by you.
                            <NextLine />
                            3.3. All personal information held in your Online Account will be collected, handled and processed in accordance with our Privacy Policy.
                            <NextLine />
                            3.4. These User Terms and our Privacy Policies create a binding agreement between You, Zepto and us.
                            <NextLine />
                            <b>4. DIRECT DEBIT REQUEST</b>
                            <NextLine />
                            4.1. You authorise us to collect payment using your provided payment method via the signed DDR and DDRSA from Your nominated account.
                            <NextLine />
                            4.2. If You want to update Your nominated account You will need to sign a new DDR and DDRSA nominating Your new nominated account.
                            <NextLine />
                            4.3. Any changes will be communicated to you by written notice in advance if we vary or update any terms. Such variations will be done in accordance with the terms of the DDRSA as applicable.
                            <NextLine />
                            4.4. Any changes will be communicated to you in advance, and your continued acceptance of the Online Account following such updates constitutes acceptance of the revised terms.
                            <NextLine />
                            4.5. You confirm your authorisation to use the payment method via the signed DDR and DDRSA and consent to the processing of payments as outlined in your DDR.
                            <NextLine />
                            <b>5. ONLINE ACCOUNT DEPOSITS AND WITHDRAWALS</b>
                            <NextLine />
                            5.1. All transactions in your Online Account will be done in Australian Dollars (AUD).
                            <NextLine />
                            5.2. The Online Account can only receive the tax refund and any payments from your Online Account will only be actioned via the DDR that you have authorised us. The Online Account does not have the functionality to add any more funds via any other payment method.
                        </div>
                        <div className="col">
                            5.3. You will not be able to add, withdraw, or make any other requests related to the Online Account.
                            <NextLine />
                            <b>6. FEE DISPUTES AND COMPLAINTS</b>
                            <NextLine />
                            6.1. If you believe there is an error in the payments, fees or charges shown in Your bank account statement or transaction history of your Online Account, You must promptly notify us and provide relevant details to support your claim. You can contact us on the details that we have provided in Clause 17.3.
                            <NextLine />
                            6.2. Complaints can be made, resolved, and progressed as per Clauses 16 and 17. If necessary, we will adjust and rectify any genuine errors.
                            <NextLine />
                            <b>7. KYC REQUIREMENTS</b>
                            <NextLine />
                            7.1. Your Provider would have undertaken KYC on You at the time of entering into the Services Agreement with You. Your Provider may disclose the information provided as part of the KYC. In addition, as part of our ongoing obligations under the AML/CTF Act we may need to undertake further verification of your identity and undertake additional KYC.
                            <NextLine />
                            7.2. You agree to provide us with all reasonably requested additional information from You in order for us to undertake further verification of your identity and undertake additional KYC.
                            <NextLine />
                            <b>8. TERMINATION AND SUSPENSION</b>
                            <NextLine />
                            8.1. You acknowledge and agree to our rights and discretion concerning the Online Account termination and suspensions, as outlined in these User Terms. Our decisions regarding the Online Account termination and suspensions shall be final and binding.
                            <NextLine />
                            8.2. We reserve the right, in our sole discretion (acting reasonably), to suspend or terminate your Online Account, without prior notice or liability, for any reason or no reason at all. Such actions may include, but are not limited to, the following situations:
                            <NextLine />
                            (a)	You do not provide us with any document or information we reasonably request from You;<br />
                            (b)	any breach of these User Terms;<br />
                            (c)	any non-compliance with applicable laws, regulations, or guidelines or data protection;<br />
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            (d)	we reasonably consider necessary to comply with, for example our financial crimes policies, any laws in Australia or overseas or to manage any risk or, for a transaction, if your instructions are not clear;<br />
                            (e)	at the request of Your Provider whereby you fail to fulfil payment obligations in accordance with the payments outlined in your Service Agreement;<br />
                            (f)	we believe on reasonable grounds that you may be a person:<br />
                            <div className="intend">(A) with whom we are not permitted to deal with by law or a regulatory authority or</div>
                            <div className="intend">(B)	in breach of laws relating to money laundering and terrorism financing; or</div>
                            (g)	if you choose to terminate the Services provided by us, you may request termination from us;<br />
                            (h)	the Services have been completed by the Provider; or<br />
                            (i) the terms of the Service Agreement has come to an end on has been terminated.
                            <NextLine />
                            <b>9. EFFECT OF TERMINATION AND SUSPENSION</b>
                            <NextLine />
                            9.1. The following will take effect upon termination or suspension of Your Online Account:
                            <NextLine />
                            (a)	Upon account termination, we will cancel Your Online Account.<br />
                            (b)	We may, at our discretion, delete or retain in accordance with our obligations under the Privacy Act 1988 (Cth) your account information and data following the Online Account termination or suspension.<br />
                            (c)	We reserve the right to investigate any suspected violations of these User Terms or unauthorised activities relating to your Online Account.<br />
                            (d)	We shall not be liable to you or any third party for any termination or suspension of your Online Account, whether due to your actions or the exercise of our rights under these User Terms, except for our fraud, negligence or wilful default.<br />
                            (e)	While we may, at our discretion, provide notice of Your Online Account termination or suspension, we are not obligated to do so and may act without prior notice (acting reasonably), but we will notify You as soon as soon as possible thereafter.<br />
                            (f)	We may, at our discretion, reinstate access to Your Online Account following suspension, subject to a satisfactory resolution of the issues that led to the suspension.
                            <NextLine />
                            <b>10.	REPRESENTATIONS AND WARRANTIES</b>
                            <NextLine />
                            10.1.	To the full extent permitted by law, we make no representations, warranties, guarantees, or conditions, whether express or implied (and including, without limitation, those implied by statute, custom, law, or
                        </div>
                        <div className="col">
                            otherwise), except as expressly set out in these User Terms.
                            <NextLine />
                            <b>11.	LIMITATION OF LIABILITY</b>
                            <NextLine />
                            11.1. To the full extent permitted by law and subject to the provisions of these User Terms, except where the relevant liability arises from our unlawful actions, fraud, negligence or wilful default we exclude all liability to you for any damages or loss (including without limitation direct, incidental, indirect, consequential, special, punitive, or exemplary damages or any loss of business, interest, goodwill, revenue, profit, or loss or corruption of data) arising out of your Online Account or otherwise in connection with Your reliance on, the Online Account however caused (including, without limitation, damage or loss arising in contract, tort (including, without limitation, negligence), statute, or otherwise).
                            <NextLine />
                            <b>12.	AUSTRALIAN CONSUMER LAW</b>
                            <NextLine />
                            12.1. Certain legislation, including the Australian Consumer Law, may imply warranties, conditions, or obligations, or impose remedies or guarantees that cannot be fully excluded, restricted, or modified. These statutory provisions may, for instance, impose non-excludable guarantees concerning the quality of the rendering of services with due care and skill, with specific remedies available to consumers for breaches of such guarantees.
                            <NextLine />
                            12.2. To the extent that such legislation applies, these User Terms are subject to those statutory provisions. If applicable, notwithstanding any other provision in these User Terms, we may limit our liability according to such provisions. For services, our liability may be limited to re-supply or payment of the cost of having the services supplied again.
                            <NextLine />
                            <b>13.	INDEMNITY</b>
                            <NextLine />
                            13.1. Except where the relevant liability arises from our unlawful actions, fraud, negligence or wilful default, You agree to fully compensate us and our officers, employees, and agents from and against reasonable liability, loss, damage, costs, and expense (including, without limitation, reasonable legal expenses on a reasonable basis) and penalties incurred or suffered by any of them arising out of:
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            (a)	your breach of these User Terms;<br />
                            (b)	any act of fraud or wilful misconduct by or on behalf of You.
                            <NextLine />
                            <b>14.	PHISHING AND SCAM AWARENESS</b>
                            <NextLine />
                            14.1. You are advised, acknowledge, and agree that you will remain vigilant against phishing attempts and scams that may seek to obtain information about Your Online Account through deceptive means. We shall not be responsible for any losses resulting from users falling victim to phishing or scams.
                            <NextLine />
                            <b>15.	PRIVACY</b>
                            <NextLine />
                            15.1. Under the Privacy Act, we are required to comply with the thirteen (13) APPs that afford you certain rights concerning your privacy and provide protections for your Personal Information. This includes the right to make a complaint about how your Personal Information is handled and managed or if it is breached - referred to as Privacy Complaints. All Privacy Complaints are handled in accordance with our Privacy Policy.
                            <NextLine />
                            15.2. In addition to your Personal Information, to facilitate the Online Account, you consent to our and Zepto’s collection and utilisation of technical data and associated information, which may include but is not limited to technical details and technical information relating to Your Online Account.
                            <NextLine />
                            15.3. Your Authorise Zepto to verify the details of any nominated account by You in Your DDR with Your/our financial institution and that financial institution to release information allowing Zepto to do that.
                            <NextLine />
                            15.4. We or Zepto may disclose your account and Direct Debit details at the request of Your financial institution (e.g. in connection with a claim made for an alleged incorrect or wrongful debit) or as required by law or permitted under our privacy statement.
                            <NextLine />
                            15.5. All Personal Information is handled by us and Zepto in accordance with the privacy protection practices that have been described in the Privacy Policy.
                            <NextLine />
                            15.6. You can obtain a copy of our Privacy Policy on our Payou website. Alternatively, you can request a copy by contacting us on the contact details below in clause 17.3.
                            <NextLine />
                            15.7. You can obtain a copy of Zepto’s Privacy Policy on their website at www.zepto.com.au.
                        </div>
                        <div className="col">
                            <b>16.	PRIVACY COMPLAINTS</b>
                            <NextLine />
                            16.1. All Privacy Complaints are handled and managed in accordance with the procedures set out in the relevant Privacy Policy.
                            <NextLine />
                            16.2. You can also make a Privacy Complaint to the OAIC by contacting them on the following details:
                            <NextLine />
                            <b>Website:</b> www.oaic.gov.au<br />
                            <b>Telephone:</b> 1300 363 992<br />
                            <b>Post:</b> GPO Box 5288, Sydney NSW 2001<br />
                            <b>Fax:</b> +61 2 6123 5145
                            <NextLine />
                            <b>17.	PAYOU CONTACT INFORMATION</b>
                            <NextLine />
                            17.1. You may contact us about an enquiry or for more information about these User Terms on the contact details below in Clause 17.3.
                            <NextLine />
                            17.2. We also accept that we can get things wrong, and when this happens, we are determined to make them right again. If you have a complaint or dispute about our Online Account, you can also contact us via the contact details below in Clause 17.3.
                            <NextLine />
                            17.3. Our contact details:
                            <NextLine />
                            <b>Website:</b> payou.com.au<br />
                            <b>Email:</b> info@payou.com.au<br />
                            <b>Telephone:</b> 0499 004 005<br />
                            <b>Post:</b> PO Box 9054 GCMC QLD 9726
                            <NextLine />
                            17.4. When you submit a complaint to us, we shall:
                            <NextLine />
                            (a)	acknowledge your complaint promptly and ensure a thorough understanding of the issues raised;<br />
                            (b)	make every effort to resolve the problem effectively;<br />
                            (c)	keep you updated on the progress of our investigation;<br />
                            (d)	maintain a comprehensive record of your complaint;<br />
                            (e)	provide you with our name, a reference number, and contact details for further communication if necessary; and<br />
                            (f)	furnish a final response within thirty (30) days or inform you if additional time is required to complete the investigation.
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            <b>18.	ASSIGNMENT</b>
                            <NextLine />
                            18.1. The assignment or transfer of your rights and obligations under these User Terms is strictly prohibited without obtaining our prior written consent.
                            <NextLine />
                            18.2. We reserve the right to assign, transfer, or delegate any or all of our rights and obligations under these User Terms with or without your permission. Such assignment or transfer shall be effective upon notice to you or as otherwise stipulated by applicable laws.
                            <NextLine />
                            <b>19. ENTIRE TERMS</b>
                            <NextLine />
                            19.1. These User Terms, together with any additional terms and conditions that may apply to specific products or services offered under the Services Agreement and the Online Account, constitute the entire agreement between you and us regarding your conduct and use of the Online Account.
                            <NextLine />
                            <b>20. GOVERNING LAW</b>
                            <NextLine />
                            20.1. These User Terms are governed by the laws of the Jurisdiction and both parties consent to the non-exclusive jurisdiction of the courts of the Jurisdiction.
                            <NextLine />
                            20.2. The parties submit to the non-exclusive jurisdiction of the courts of the Jurisdiction in respect of all matters arising under or in connection with these User Terms.
                            <NextLine />
                            <b>21.	NOTICES</b>
                            <NextLine />
                            21.1. By agreeing to these User Terms, you consent to receive communications and notices by hand, postal mail, SMS and electronically. This includes but is not limited to communication via email, SMS or postal mail, as applicable. Such electronic communications and notices shall be considered valid and effective, and you agree that they satisfy any legal requirement for written communication.
                            <NextLine />
                            21.2. Any communication or notice sent by hand, mail or electronically in accordance with Clause 21.1 above is received, for communication via:
                            <NextLine />
                            <b>SMS:</b> Upon being successfully delivered to the mobile number provided by the recipient.<br />
                            <b>Email:</b> Upon being successfully delivered to the email address provided by the recipient.<br />
                            <b>Mail:</b> Five business days after the date of posting, as evidenced by a valid postal receipt.
                        </div>
                        <div className="col">
                            <b>22.	NO WAIVER</b>
                            <NextLine />
                            22.1. The failure or delay by either party to enforce any rights or provisions outlined in these User Terms shall not be construed as a waiver of such rights or provisions. The exercise of any right or remedy by either party shall not preclude the exercise of any other right or remedy available to them under these User Terms or applicable law.
                            <NextLine />
                            <b>23.	SEVERABILITY</b>
                            <NextLine />
                            23.1. If any provision of these User Terms is deemed unlawful, invalid, or unenforceable, the remaining provisions shall remain valid and enforceable. We will strive to replace or remove the problematic provision with a valid one that preserves the original intent of these User Terms. The validity and enforceability of the other provisions will not be affected.
                            <NextLine />
                            <b>24. AMENDMENTS</b>
                            <NextLine />
                            24.1. We may update and modify these User Terms from time to time. Changes will be posted on our Payou Website. By continuing to use our Services, you accept the current version of these User Terms, including any amendments in effect at that time, governing Your usage and conduct.
                            <NextLine />
                            24.2. Please check our Payou Website for the current version of these User Terms.
                            <NextLine />
                            24.3. The revised User Terms shall apply from the date of publication of the revised User Terms on our Payou Website, and you hereby waive any right you may otherwise have to be notified of, or to consent to, revisions of the User Terms.
                            <NextLine />
                            24.4. We will not file a copy of these User Terms specifically in relation to each user or customer and, if we update the User Terms, the version to which you originally agreed will no longer be available on our Payou Website. We recommend that you consider saving a copy of these User Terms for future reference.
                            <NextLine />
                            24.5. These User Terms are Version 1.0 dated 15 April 2024.
                        </div>
                    </div>
                </PayouPage>
            </Container>
        </div>
    );
}

export default TermsAndConditions;