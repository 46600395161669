import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Image } from "semantic-ui-react";
import styled from "styled-components";
import { AgreementTable } from "../../app/models/Agreement";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";
import DateHelper from "../../app/helpers/dateHelper"
import { Status } from "../../app/constants/agreementStatus"
import { PaymentType } from "../../app/constants/paymentType";

const _dateHelper = new DateHelper();

const StyledContainer = styled.div<{ isMobile: boolean }>`
        padding:${p => p.isMobile ? 10 : '1em 2em'};
`;

const AgreementList = () => {
    const { agreementStore, lookupStore: { loadAgreementStatus, agreementStatus }, commonStore: { isMobile } } = useStore();
    const {
        agreements, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterAgreements, filterValues, setFilterByStatusId, setFilterValues } = agreementStore;

    const history = useHistory();

    const routeChange = (path: string) => {
        history.push(path);
    }

    const getColor = (status: string) => {
        let color = '#d2eaa5';

        switch (status) {
            case Status.InProgress:
                color = '#f9ca24';
                break;
            case Status.ForESign:
                color = '#f0932b';
                break;
            case Status.Esigned:
                color = '#6ab04c';
                break;
            case Status.NoShow:
                color = '#eb4d4b';
                break;
            case Status.PaperSigned:
                color = '#76797b'
        }
        return color;
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        }
    }

    const handleFilterChange = (value: string | undefined) => {
        setFilterByStatusId(value === 'All' ? undefined : value);
    }

    useEffect(() => {
        loadAgreementStatus();
        filterAgreements();

        return () => {
            agreementStore.reset();
        }
    }, [filterAgreements]);

    return (
        <>
            < StyledContainer isMobile={isMobile} >
                < PaginatedTable <AgreementTable>
                    isMobile={isMobile}
                    title="Agreements"
                    columns={
                        [
                            { title: 'id', field: 'id', hidden: true },
                            {
                                title: 'Tax File Number',
                                field: 'client.taxFileNumber',
                                render: (data) => {
                                    return <><Icon style={{ color: '#363636' }} name='copy' />{data.client.taxFileNumber}</>;
                                }
                            },
                            { title: 'Name', field: 'client.fullName' },
                            { title: 'Mobile Number', field: 'client.mobileNumber' },
                            { title: 'Email', field: 'client.email' },
                            {
                                title: 'Date',
                                field: 'modifiedOn',
                                render: (data) => {
                                    return _dateHelper.formatShortDate(data.modifiedOn);
                                }
                            },
                            {
                                title: 'Payment',
                                field: 'paymentOption',
                                render: (data) => {
                                    let payment = ' ';

                                    switch (data.paymentOption) {
                                        case (PaymentType.Cash):
                                            payment = 'CASH';
                                            break
                                        case (PaymentType.ElectronicFundTransfer):
                                            payment = 'EFT';
                                            break;
                                        case (PaymentType.FeeFromRefund):
                                            payment = 'FFR';
                                            break;
                                        case (PaymentType.RefundAdvance):
                                            payment = 'ADV';
                                            break;
                                        default:
                                    }

                                    return payment;
                                }
                            },
                            {
                                title: 'Payou',
                                field: 'agreementStatus.id',
                                render: (data) => {
                                    if ((data.agreementStatus.name === Status.Esigned || data.agreementStatus.name === Status.PaperSigned) &&
                                        (data.paymentOption === PaymentType.FeeFromRefund || data.paymentOption === PaymentType.RefundAdvance)
                                    ) {

                                        return (
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    color: '#fff',
                                                    width: 'fit-content',
                                                    borderRadius: '0.25rem',
                                                    border: '1px solid transparent',
                                                    minWidth: 120,
                                                    minHeight: 20,
                                                    fontSize: '1rem',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.open(`https://go.payou.com.au/api/taxcess-entry?id=${data.id}`)
                                                }}
                                            >
                                                <Image alt='Payou Page' src={`https://go.payou.com.au/api/taxcess-status-image?id=${data.id}`} />
                                            </div>
                                        );
                                    }
                                }
                            },
                            {
                                title: 'Status',
                                field: 'agreementStatus.name',
                                render: (data) => {
                                    return (<div style={{
                                        background: getColor(data.agreementStatus.name),
                                        textAlign: 'center',
                                        color: '#fff',
                                        width: 'fit-content',
                                        borderRadius: '0.25rem',
                                        border: '1px solid transparent',
                                        padding: '0 0.50rem',
                                        minWidth: 120,
                                        fontSize: '1rem',
                                    }}>{data.agreementStatus.name}</div>);
                                }
                            },
                            { title: 'Updated by', field: 'updater.fullName' },
                        ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={p => setPageNumber(p)}
                    onSearchChange={query => setSearchQuery(query)}
                    data={agreements}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    onRowClick={(e, rowData) => routeChange('/agreements/interview/bankInformation/' + rowData?.id)}
                    options={{ rowStyle: rowData => { return { borderBottom: '1px solid rgb(110 110 110 / 24%)' } } }}
                    onDateRangeFilter={handleDateRangeFilter}
                    dateRangeValues={[filterValues.startDate!, filterValues.endDate!]}
                    dateRangeFilter={true}
                    filter={{
                        option: agreementStatus.filter(s => s.name !== 'Cancelled').map(a => {
                            return {
                                key: a.id,
                                value: a.id,
                                text: a.name,
                                color: getColor(a.name)
                            }
                        })
                    }}
                    onDataFilterChange={handleFilterChange}
                />
            </ StyledContainer >
        </>
    )
}

export default observer(AgreementList);
