import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";
import LocationFormModal from "./LocationFormModal";
import { Location } from "../../app/models/location"

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const LocationList = () => {
    const { locationStore, commonStore: { isMobile } } = useStore();
    const {
        locations, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterLocations, filterValues, delete: deleteLocation, selectLocation } = locationStore;
    const swal = withReactContent(Swal);

    const history = useHistory();

    const [open, setOpen] = useState(false);

    const routeChange = (path: string) => {
        history.push(path);
    }

    const handleDelete = (id: string) => {
        swal.fire({
            text: `Are you sure you want to delete this location?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteLocation(id).then(() => {
                    filterLocations();
                });
            }
        });
    }

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterLocations();
    }, [filterLocations]);

    return (
        <>
            < StyledContainer >
                < PaginatedTable <Location>
                    isMobile={isMobile}
                    title="Locations"
                    rightButton={
                        {
                            label: "Add Location",
                            onClick: () => {
                                selectLocation({
                                    id: '',
                                    name: ''
                                });
                                setOpen(true);
                            }
                        }
                    }
                    columns={
                        [
                            { title: 'id', field: 'id', hidden: true },
                            { title: 'Name', field: 'name' },
                        ]}
                    actions={
                        [
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'Edit Role',
                                onClick: async (event) => {
                                }
                            }),
                        ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={
                        p => {
                            setPageNumber(p);
                        }}
                    onSearchChange={
                        query => {
                            setSearchQuery(query);

                        }}
                    data={locations}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    components={
                        {
                            Action: (props) => (
                                <>
                                    <Button
                                        style={{ display: props.data.default ? "none" : "block" }}
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                console.log(data);
                                                selectLocation(data);
                                                setOpen(true);
                                            }}
                                        size="small"
                                        basic
                                        color='blue'
                                        content="Edit"
                                    />
                                    <Button
                                        style={{ display: props.data.default ? "none" : "block" }}
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                handleDelete(data.id);
                                            }}
                                        size="small"
                                        basic
                                        color='blue'
                                        content="Delete"
                                    />
                                </>
                            ),
                        }
                    }
                />
            </ StyledContainer >
            <LocationFormModal open={open} onClose={() => setOpen(false)} />
        </>
    )
}

export default observer(LocationList);
