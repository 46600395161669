import { useField } from 'formik';
import * as React from 'react';
import { Form } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
}


export default function PasswordInput(props: Props) {
    const [field, meta] = useField(props.name);
    const [values, setValues] = React.useState({
        showPassword: false
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };


    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={props.label ? { marginTop: "1rem" }: { textAlign: 'left', color: '#6a7e99', fontSize: '12px' }}>
                {props.label}
            </label>
            <div className="ui icon input">
                <input type={values.showPassword ? "text" : "password"} {...field} {...props} />
                <i className={values.showPassword ? 'eye slash link icon': 'eye link icon'} onClick={handleClickShowPassword}></i>
            </div>
            {meta.touched && meta.error ? (
                <p style={{ marginLeft: 5, marginTop: 5, color: 'red' }}>{meta.error}</p>
            ) : null}
        </Form.Field>
    );
}