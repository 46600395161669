import * as React from 'react';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Card, Grid, Header, Icon, Image } from 'semantic-ui-react';
import PasswordInput from '../../app/common/form/PasswordInput';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
const validationSchema = Yup.object({
    oldPassword: Yup.string()
        .required('Old Password field is required')
        .test('passwords-match', 'The old password cannot be the same as your new password.', function (value) {
            return this.parent.password !== value
        })
    ,
    password: Yup.string()
        .required('New Password field is required')
        .min(8, 'Password should have a minimum of 8 characters.')
        .matches(/^(?=.*\d)(?=.*[a-zA-Z]).+$/, 'Password should be a combination of alphanumeric. A special character is optional.')
    ,
    confirmPassword: Yup.string()
        .required('Confirm Password field is required')
        .test('passwords-match', 'The new password you entered does not match your confirm password.', function (value) {
            return this.parent.password === value
        })
});


const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;



const ChangePasswordForm = () => {
    const { authStore: { changePassword, isLoading }, commonStore } = useStore();
    const history = useHistory();

    return (
        <StyledContainer isMobile={commonStore.isMobile}>
            <Card style={{ minWidth: 520 }}>
                <Card.Content className='cardDivided'>
                    <Card.Header style={{ color: '#0099da' }} content='Change Password' />
                </Card.Content>
                <Card.Content>
                    <Formik
                        initialValues={{ oldPassword: '', password: '', confirmPassword: '' }}
                        onSubmit={(values, { setErrors }) => {
                            changePassword({
                                oldPassword: values.oldPassword,
                                newPassword: values.password
                            }).then(() => {
                                toast.success('Password changed successfully.');
                            }).catch(() => {
                                setErrors({
                                    oldPassword: 'Invalid Password.'
                                })
                            });
                        }}
                        validationSchema={validationSchema}
                    >
                        {
                            ({ handleSubmit, isSubmitting, errors }) => (
                                <div >
                                    <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                        <PasswordInput name='oldPassword' placeholder='Old Password' />
                                        <PasswordInput name='password' placeholder='New Password' />
                                        <PasswordInput name='confirmPassword' placeholder='Confirm Password' />
                                        <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                            <Grid.Column>
                                                <Button loading={isLoading} color='blue' style={{ backgroundColor: 'rgb(21, 189, 254)', borderRadius: '8px' }} fluid content='Change Password' type='submit' size='medium' />
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                </div>
                            )
                        }
                    </Formik>
                </Card.Content>
            </Card>
        </StyledContainer>

    );
}

export default observer(ChangePasswordForm);