import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Grid, Header, Modal } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import CheckBox from "../../app/common/form/CheckBox";

interface Props {
    onClickOk: any;
}


const RefundAdvanceModal = ({ onClickOk }: Props) => {
    const { agreementStore } = useStore();
    const { refundAdvanceQuestion, refundAdvanceQuestionModalOpen } = agreementStore;

    return (
        <Modal
            size="small"
            open={refundAdvanceQuestionModalOpen}
            onClose={() => console.log("on modal close")}
        >
            <Modal.Header style={{ color: '#0099da' }}>Refund Advance</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={refundAdvanceQuestion}
                    onSubmit={(values: any) => onClickOk(values)}
                >
                    {
                        ({ handleSubmit }) => (
                            <Form id='refundAdvanceForm' className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <div style={{ flex: 'none', width: '100%', padding: '0.75rem' }}>READ TO CLIENT AND CONFIRM</div>
                                <Grid style={{ marginLeft: 15 }}>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='eligibility' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12, fontSize: '1em', fontWeight: 400 }} width={15}>
                                            Your eligibility for a Refund Advance can only be determined at the end of the tax interview. If you are not approved for this service, do you agree to continue with lodgement of your return with as normal. (If No, exit client)
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='confirm' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            Do you confirm that all of your prior year tax returns have been lodged and assessed by the ATO, and that you do not have any current government debt and have not had any government payments deducted from your tax refund in the past three years. (If No, offer standard return)
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='approved' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            If approved, today we can offer you a maximum of $500. The balance of your refund will be transferred to your account when we receive it from the ATO. Is this suitable for you? (If No, offer standard return)
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div style={{ flex: 'none', width: '100%', padding: '0.75rem', marginTop: '2em' }}>CONSULTANT TO TICK OFF WHEN COMPLETE</div>
                                <Grid style={{ marginLeft: 15 }}>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='current' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            Sight current(unexpired) client photo ID and take photocopy. Ensure this matches bank account details.
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='portal' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            Check on portal that all prior year ITR's are lodged and assessed by the ATO.
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={1}>
                                            <CheckBox name='portalICA' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            Check on portal ICA for any government payments in last 3 years (Centrelink, Child Support, Family Tax Benefit, SPER fines etc).
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={1}>
                                            <CheckBox name='evaluate' />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingTop: 12 }} width={15}>
                                            Personally evaluate the client and look for any gaps or suspected untruths during the tax interview.
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions style={{ height: 60 }}>
                <Button
                    style={{ marginBottom: 13 }}
                    floated='left'
                    form='refundAdvanceForm'
                    content='Ok'
                    basic
                    type='submit'
                    size='medium'
                />
            </Modal.Actions>
        </Modal>
    )
}

export default observer(RefundAdvanceModal);
