import { useStore } from "../../../../app/stores/store";
import Container from "../Container";
import PayouPage from "../PayouPage"
import './../../../../app/styles/scss/contractsV2/direct-debit/direct-debit.css';

const DirectDebit = () => {
    const { agreementStore, commonStore: { dateNow }, authStore: { user } } = useStore();
    const { agreement } = agreementStore;

    const NextLine = () => <><br /><br /></>;

    return (
        <div style={{ position: 'absolute', left: -1500 }}>
            <Container id='direct-debit-pdf' className="direct-debit">
                <PayouPage>
                    <div className="header-title">Direct Debit Request</div>
                    <div className="acknowledment">
                        <div className="title">Your Authority and Acknowledgement</div>
                        <div className="contents">By completing this Direct Debit Request form you request and authorise Payou via Zepto (as outlined below) to arrange for funds to be debited from your nominated account as described below, through the Bulk Electronic Clearing System or by any other means. This authorisation will remain active in accordance with the terms and conditions described in this Direct Debit Request form and the Direct Debit Request Service Agreement.</div>
                        <div className="contents">This document outlines our services commitment to you, in respect of the Direct Debit Request made between Payou, Zepto and You.</div>
                    </div>
                    <div className="sub-header">DIRECT DEBIT DETAILS</div>
                    <div className="divider"></div>
                    <div className="info mt-5"><span className="bold">PROVIDER NAME &amp; ABN:</span> Accountant Direct Pty Ltd ABN 16 155 811 448</div>
                    <div className="info mt-5"><span className="bold">DATE:</span>  {dateNow}</div>
                    <div className="row">
                        <div className="col">
                            <div className="info mt-5"><span className="bold">TAX CONSULTANT EMAIL:</span>  {user?.email}</div>
                            <div className="info mt-5"><span className="bold">CUSTOMER:</span> {agreement?.client.fullName}</div>
                            <div className="info mt-5"><span className="bold">CUSTOMER POSTCODE:</span>  {agreement?.client.postalCode}</div>
                            <div className="info mt-5"><span className="bold">EMAIL:</span> {agreement?.client.email}</div>
                            <div className="info mt-5"><span className="bold">ACCOUNT NAME:</span>  {agreement?.bankInformation.accountName}</div>
                        </div>
                        <div className="col">
                            <div className="info mt-5"><span className="bold">BSB:</span> {agreement?.bankInformation.bsb}</div>
                            <div className="info mt-5"><span className="bold">ACCOUNT NUMBER:</span>  {agreement?.bankInformation.bankAccount}</div>
                            <div className="info mt-5"><span className="bold">PROVIDER FEE PAYABLE:</span> ${agreement?.interview.interviewThirdPage.quote.toFixed(2)}</div>
                            <div className="info mt-5"><span className="bold">PAYOU:</span>  Payou Pty Ltd ACN 651 634 650</div>
                        </div>
                    </div>
                    <div className="sub-header mt-5">PAYOU FEES</div>
                    <div className="fee-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Setup</th>
                                    <th>Per debit</th>
                                    <th>Per debit Failure/Dishonour</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>$5.50</td>
                                    <td>$5.50</td>
                                    <td>$5.50</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="info mt-5"><span className="bold">ZEPTO:</span> Zepto Payments Pty Ltd ACN 604 057 598 holder of AFSL number 541011</div>
                    <div className="info mt-5"><span className="bold">JURISDICTION:</span> Queensland, Australia.</div>
                    <div className="sub-header mt-10">LEGAL TERMS</div>
                    <div className="row terms">
                        <div className="col">
                            You request and authorise Zepto Payments Pty Ltd (User ID #454146, 492448, 500298, 507533, 518403, 518404, 531942, 543948, 543950, 543949, 543954, 543947, 543955, 543956, 543957, 543958, 543962, 600993, 600994, 600995, 600996, 600997, 600998, 600999, 601001, 601002, 601003, 601004, 601005, 601006, 601007, 601008, 612056, 612057, 613600, 613601, 613602, 613603, 613604, 613605, 613606, 613607, 613608, 613609) ("Zepto") on behalf of Payou via the Direct Debit Request and the Direct Debit Service Agreement (Agreement), to arrange through Zepto’s financial institution, to debit from your nominated account any amount your Provider has deemed payable
                            by you via this Agreement and your Provider’s Service Agreement.
                        </div>
                        <div className="col">
                            Bulk Electronic Clearing System from your nominated account held at the financial institution you have nominated and will be subject to the terms and conditions of the Direct Debit Request Service Agreement. Be aware Direct Debiting through Bulk Electronic Clearing System is not available on all accounts. If in doubt, contact Your Financial Institution.
                            <br />
                            <br />
                            <b><i>Each of Payou or Zepto does not accept any liability for the provision, merchantable quality or fitness for purpose of the underlying goods or services provided to You by the Provider and therefore You hold Payou and Zepto harmless for any claim that may arise from the non-provision of services by the Provider or any other claim that may be made against the Provider under Australian Consumer Law.</i></b>
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="header-title">Direct Debit Request Service Agreement</div>
                    <div className="row terms mt-30">
                        <div className="col">
                            This Agreement sets out your rights, Payou and Zepto’s commitments to you and your responsibilities to us together with where you should go for assistance. It explains what your obligations are when entering into a direct debit arrangement with us. It also details what Payou and Zepto’s obligations are to you as for the purposes of the direct debit arrangement. Please keep this Agreement for future reference.
                            <NextLine />
                            <b>1. DEFINITIONS</b>
                            <NextLine />
                            1.1. The Items specified in the Contract Details of the Direct Debit Request have the same meaning as described in the Contract Details, unless otherwise defined below. The following words have these meanings in this Agreement unless the contrary intention appears:
                            <NextLine />
                            <b>Agreement</b> means the Direct Debit Request and the Direct Debit Request Service Agreement.
                            <NextLine />
                            <b>Business Day</b> means a day other than a Saturday or a Sunday or a public holiday listed throughout Australia.
                            <NextLine />
                            <b>Client Service Agreement</b> means the accounting client service agreement that you have entered into with your Provider.
                            <NextLine />
                            <b>Debit Payment</b> means a particular transaction where a debit is made.
                            <NextLine />
                            <b>Direct Debit Request</b> means the direct debit request between us and you.
                            <NextLine />
                            <b>Nominated Account</b> means the account held by your financial institution from which you have nominated and authorised us on your Direct Debit Request form to debit the funds due and payable to us.
                            <NextLine />
                            <b>You, “your”</b> means the customer named on the direct debit request form.
                            <NextLine />
                            <b>Your Financial Institution</b> means the financial institution nominated by you on the Direct Debit Request at which the Nominated Account is maintained.
                        </div>
                        <div className="col">
                            <b>2. DEBITING YOUR NOMINATED ACCOUNT</b>
                            <NextLine />
                            2.1. By agreeing to a Direct Debit Request, Zepto will debit your Nominated Account for the amounts and at the frequency of payments as agreed between you and the Provider and as disclosed in the Direct Debit Request.
                            <NextLine />
                            2.2. Payou and Zepto have been contracted by you to collect Debit Payments from you and other than the direct debt services, these companies do not provide any goods or services to you.
                            <NextLine />
                            2.3. The Direct Debit Request and this Direct Debit Request Service Agreement should be read together with your Provider’s Service Agreement for the terms of the arrangement made between the Provider, us, Zepto and you.
                            <NextLine />
                            2.4. Any drawing due on a Non-Business Day will be debited to your Nominated Account on the next Business Day following the scheduled drawing date.
                            <NextLine />
                            2.5.We will arrange via Zepto for funds to be debited from your Nominated Account as outlined in the Direct Debit Request.
                            <NextLine />
                            <b>3. AMENDMENTS BY US</b>
                            <NextLine />
                            3.1. The terms of this Agreement or a Direct Debit Request may be varied at any time by giving you at least 30 days’ written notice. This is managed through the Zepto Platform.
                            <NextLine />
                            <b>4. DISCLOSURE</b>
                            <NextLine />
                            4.1. We or Zepto may disclose your Nominated Account and Direct Debit details at the request of Your Financial Institution (e.g. in connection with a claim made for an alleged incorrect or wrongful debit) or as required by law or permitted under our privacy statement.
                            <NextLine />
                            <b>5. YOUR RIGHTS</b>
                            <NextLine />
                            5.1. To change, suspend, cancel or discuss your Direct Debit arrangement, please contact us on the contact details in clause 10.2 below.
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            5.2. You may also make changes through your own financial institution, which is required to act promptly on your instructions.
                            <NextLine />
                            <b>6. CANCELLING THE PAYMENTS</b>
                            <NextLine />
                            6.1. Cancellation of the authority to debit your Nominated Account will not terminate your contract or remove your liability to make the payments you have agreed with your Provider.
                            <NextLine />
                            <b>7. YOUR RESPONSIBILITY</b>
                            <NextLine />
                            7.1. You should confirm your Nominated Account details are correct before submitting this Direct Debit Request by checking them against a recent account statement.
                            <NextLine />
                            7.2. You must advise us if your Nominated Account details change, or the Nominated Account is closed.
                            <NextLine />
                            7.3. You should regularly check your account statements to verify that the amounts debited from your Nominated Account are correct. If you believe there has been an error, you should contact us or Your Financial Institution immediately.
                            <NextLine />
                            7.4. You’ll need to ensure that you have sufficient funds in your Nominated Account on each repayment due date until the Direct Debit occurs. If you do not have sufficient funds, then:
                            <NextLine />
                            (a)	you may be charged a fee and/or interest by Your Financial Institution;
                            <NextLine />
                            (b)	you may also incur fees or charges imposed or incurred (for e.g. dishonour fee) as disclosed in your Direct Debit Request; and
                            <NextLine />
                            (c)	you must arrange for the Debit Payment to be made by another method or arrange for sufficient clear funds to be in your Nominated Account by an agreed time so that we can process the Debit Payment.
                            <NextLine />
                            7.5. Should you cancel the Direct Debit Request, instruct Your Financial Institution not to make payment or more than two (2) consecutive payments are dishonored we may cancel this Agreement and the remaining scheduled amount plus all fees and charges will be due and payable
                        </div>
                        <div className="col">
                            <b>8. DISHONORED PAYMENTS</b>
                            <NextLine />
                            8.1. It is your responsibility to ensure that on the due date clear funds are available in your Nominated Account to meet the Debit Payment. Should your payment be dishonoured, you authorise Zepto to debit your Nominated Account when clear funds become available in your Nominated Account.
                            <NextLine />
                            8.2. If your drawing is returned or dishonored by Your Financial Institution, when we schedule your Nominated Account to be re-drawn the dishonor fee as listed in the Direct Debit Request will be drawn together with any other due payments. Any transaction fees payable by us in respect of the above will be added to this debit.
                            <NextLine />
                            8.3. Alternatively you can arrange for the Debit Payment to be made by another method or arrange for sufficient clear funds to be in your Nominated Account within 14 days of the payment being dishonored to enable us to recover the payment due.
                            <NextLine />
                            <b>9. PRIVACY</b>
                            <NextLine />
                            9.1. Zepto will keep information relating to your Nominated Account confidential in accordance with Zepto’s Privacy Policy, available at zepto.com.au/privacy.
                            <NextLine />
                            9.2. Payou will keep information relating to your Nominated Account confidential in accordance with Payou’s Privacy Policy, available on its website at payou.com.au
                            <NextLine />
                            <b>10.	DISPUTES</b>
                            <NextLine />
                            10.1. If you dispute any Debit Payment, you must notify your Provider immediately. The Provider will notify us and Payou will respond to your dispute within 7 working days and will immediately refund the amount of the debit if we are not able to substantiate the reason for it.
                            <NextLine />
                            10.2. If you need to get in touch with us, Payou’s contact details are outlined below:
                            <NextLine />
                            Payou Pty Ltd
                            <NextLine />
                            info@payou.com.au
                            <NextLine />
                            10.3. Alternatively, you can take it up directly with Your Financial Institution.
                        </div>
                    </div>
                </PayouPage>
                <PayouPage>
                    <div className="row terms mt-30">
                        <div className="col">
                            <b>11. GOVERNING LAW</b>
                            <NextLine />
                            11.1. These User Terms are governed by the laws of the Jurisdiction and both parties consent to the non-exclusive jurisdiction of the courts of the Jurisdiction.
                            <NextLine />
                            11.2. The parties submit to the non-exclusive jurisdiction of the courts of the Jurisdiction in respect of all matters arising under or in connection with these User Terms.
                            <NextLine />
                            <b>I {agreement?.client.fullName}:</b>
                            <NextLine />
                            (a)	Authorise and request Payou and Zepto to set up my Direct Debit arrangement, as set out above.
                            <NextLine />
                            (b)	Agree to the terms of this Direct Debit Request and the Direct Debit Request Service Agreement (including the acknowledgements set out in the Agreement).
                            <NextLine />
                            (c)	Authorise Zepto to verify the details of the abovementioned Nominated Account with my/our Financial Institution and that Financial Institution to release information allowing Zepto to do that.
                            <NextLine />
                            <NextLine />
                            <b>{agreement?.client.fullName}</b>
                            <div className="name">Name</div>
                            <NextLine />
                            <div className="flex">
                                <div></div>
                                <div><b>{dateNow}</b></div>
                            </div>
                            <div className="flex">
                                <div className="line">Signature of Customer</div>
                                <div className="line">Date of Signing</div>
                            </div>

                        </div>
                        <div className="col"></div>
                    </div>
                </PayouPage>
            </Container>
        </div>
    )
}

export default DirectDebit;