import { observer } from "mobx-react-lite";
import { RouteProps } from "react-router-dom";
import { Card } from "semantic-ui-react";


interface Props extends RouteProps {
    component: React.ComponentType<any>;
    header: string;
}

function ComponentBox({ header, component: Component }: Props) {
    return (
        <Card style={{
            width: '100%', minHeight: 500, boxShadow: '0 4px 8px 0 rgb(77 141 154 / 60%)'
        }}>
            <Card.Content className='cardDivided' header={header} />
            <Card.Content style={{ minHeight: 500 }}>
                <Component />
            </Card.Content>
        </Card>
    )
}

export default observer(ComponentBox);