import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import FormSelect from "../../app/common/form/FormSelect";
import * as ReturnType from '../../app/constants/returnType';
import { useStore } from "../../app/stores/store";

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    clientId: string
}


const MoveClientModal = ({ open, setOpen, clientId }: Props) => {
    const { agreementStore } = useStore();
    const [newContractType, setNewContractType] = useState({
        returnType: ''
    });
    const history = useHistory();


    const handleModalMount = () => {
        setNewContractType({
            returnType: ''
        });
    }

    return (
        <Modal
            size="small"
            open={open}
            onClose={() => console.log("on modal close")}
            onMount={handleModalMount}
        >
            <Modal.Header>Select a Contract Registration Type</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={newContractType}
                    onSubmit={(values: any, { setErrors, setSubmitting }) => {
                        if (values.returnType === ReturnType.phoneVideo) values.returnType = 'Phone-Video';

                        agreementStore.create(clientId, values.returnType)
                            .then(id => {
                                history.push(`/agreements/interview/bankInformation/${id}`)
                            })
                    }}
                    validationSchema={Yup.object({
                        returnType: Yup.string().required('Field is required.'),
                    })}
                >
                    {
                        ({ handleSubmit }) => (
                            <Form id='moveClientForm' className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <FormSelect
                                    labelColor='#6f8cab'
                                    options={[
                                        {
                                            key: 'In Person',
                                            value: ReturnType.inPerson,
                                            text: 'In Person'
                                        },
                                        {
                                            key: 'Phone/Video',
                                            value: ReturnType.phoneVideo,
                                            text: 'Phone/Video'
                                        }
                                    ]}
                                    loading={false}
                                    placeholder='Select a Type'
                                    name='returnType'
                                    onChange={(e: any, d: any) => {
                                        const { value } = d;
                                        setNewContractType({
                                            returnType: value
                                        });

                                    }}
                                />

                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={agreementStore.isLoading} disabled={agreementStore.isLoading} form='moveClientForm' style={{ borderRadius: 30 }} content='Submit' primary type='submit' size='medium' />
                <Button style={{ borderRadius: 30 }} onClick={() => setOpen(false)} basic>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default observer(MoveClientModal);
