import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import CookieHelper from "../helpers/cookieHelper";
import { store } from "../stores/store";

const _cookieHelper = new CookieHelper();

const axiosService = axios.create();

axiosService.defaults.baseURL = process.env.REACT_APP_API_URL;

axiosService.interceptors.request.use(async config => {
    const token = _cookieHelper.get('jsonWebToken');

    if (token && store.authStore.token && token !== store.authStore.token) {
        store.commonStore.reRenderApp();
        return;
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

axiosService.interceptors.response.use(async res => {
    return res;
}, async (error: AxiosError) => {
    const { status, data, } = error.response!;
    let originalConfig = error.config;

    switch (status) {
        case 400:
            toast.error(data);
            break;
        case 401:
            let token = _cookieHelper.get('jsonWebToken'),
                refreshToken = _cookieHelper.get('refreshToken');

            if (!originalConfig.headers['retry'] && token && refreshToken) {
                originalConfig.headers['retry'] = true;

                try {
                    await store.authStore.refreshTheToken();

                    return axiosService.request(originalConfig);
                } catch (error) {
                    store.authStore.setToken(null);
                    store.authStore.setRefreshToken(null);
                    store.authStore.user = null;
                    store.authStore.auth = null;
                    return Promise.reject(error);
                }
            }
            else {
                store.authStore.setToken(null);
                store.authStore.setRefreshToken(null);
                store.authStore.user = null;
                store.authStore.auth = null;
            }

            break;
        case 405:
            if (data || data.error) {
                toast.error(data || data.error);
            }
            break;
        case 404:
            if (data || data.error) {
                toast.error(data || data.error);
            }
            break;
        case 409:
            if (data.error) {
                toast.error(data.error);
            }
            break;
        case 500:
            //toast.error('Server Error');
            console.error(error)
            break;
    }

    return Promise.reject(error);
});

export default axiosService;