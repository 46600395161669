import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import TextInput from "../../app/common/form/TextInput";
import { Location } from "../../app/models/location";
import { useStore } from "../../app/stores/store";

interface Props {
    open?: boolean;
    onClose?: any
}

const LocationFormModal = ({ open, onClose }: Props) => {
    const { locationStore } = useStore();
    const { location, create, edit, isLoading, filterLocations } = locationStore;

    return (
        <Modal
            size="small"
            open={open}
            onClose={() => console.log("on modal close")}
        >
            <Modal.Header style={{ color: '#0099da' }}>Location</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={location}
                    onSubmit={(values: Location) => {
                        if (values.id) {
                            edit(values)
                                .then(() => {
                                    onClose();
                                    filterLocations();
                                });
                        }
                        else {
                            create(values).then(() => {
                                onClose();
                                filterLocations();

                            });
                        }
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('This field is required.').nullable(),
                    })}
                >
                    {
                        ({ handleSubmit }) => (
                            <Form id='refundAdvanceForm' style={{ marginTop: 0 }} className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput maxLength={128} label='Name' name='name' placeholder='' type='text' />
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions style={{ height: 60 }}>
                <Button
                    style={{ marginBottom: 13 }}
                    floated='left'
                    form='refundAdvanceForm'
                    content='Close'
                    basic
                    size='medium'
                    onClick={onClose}
                />
                <Button
                    loading={isLoading}
                    style={{ marginBottom: 13 }}
                    floated='left'
                    form='refundAdvanceForm'
                    content={location.id ? 'Update' : 'Save'}
                    color='blue'
                    type='submit'
                    size='medium'
                />
            </Modal.Actions>
        </Modal>
    )
}

export default observer(LocationFormModal);
