import { Grid } from 'semantic-ui-react';
import InveterviewLabel from '../../../app/styled-element/InterviewLabel';

interface Props {
    firstColumn: string | JSX.Element
    secondColumn?: JSX.Element
    thirdColumn?: JSX.Element
    style?: any
}

export default function GridRowQuantity(props: Props) {

    return (
        <Grid.Row style={{ ...props.style, paddingTop: '.3rem', paddingBottom: '.3rem' }}>
            <Grid.Column textAlign='right' width={7} >{typeof props.firstColumn === "string" ? <InveterviewLabel content={props.firstColumn} /> : props.firstColumn}</Grid.Column>
            <Grid.Column width={2}>{props.secondColumn}</Grid.Column>
            <Grid.Column width={3}>{props.thirdColumn}</Grid.Column>
        </Grid.Row>
    );
}
