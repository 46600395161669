import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { HiOutlineEmojiSad } from 'react-icons/hi';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useParams } from "react-router-dom";
import { Button, Card, Dimmer, Header, Icon, Loader, Table } from "semantic-ui-react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Note } from "../../../app/models/note";
import { useStore } from "../../../app/stores/store";
import NoteFormModal from '../notes/NoteFormModal';

const NoteList = () => {
    const swal = withReactContent(Swal);
    const { noteStore, agreementStore: { agreement } } = useStore();
    const { notes, filterNotes, setFilterValues, selectNote, delete: deleteNote, filterValues, total, isLoading, setLastAgreementIdLoaded } = noteStore;
    const [openModal, setOpenModal] = useState(false);

    const handleNewNote = () => {
        selectNote({
            id: '',
            agreementId: '',
            updater: '',
            message: '',
            subject: ''
        });
        setOpenModal(true);
    }

    const handleSelectNote = (note: Note) => {
        selectNote(note);
        setOpenModal(true);
    }

    const handleDeleteNote = (id: string) => {
        swal.fire({
            title: `Are you sure you want to delete this note?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setLastAgreementIdLoaded("");
                deleteNote(id).then(() => {
                    filterNotes();
                });
            }
        });
    }

    const handlePageChange = (state: string) => {
        setLastAgreementIdLoaded("");
        if (state === 'next') {
            if (filterValues.pageNumber < Math.ceil(total / 5)) {
                setFilterValues({ ...filterValues, pageNumber: filterValues.pageNumber + 1 });
                filterNotes();
            }
        }
        else if (state === 'prev') {
            if (filterValues.pageNumber > 1) {
                setFilterValues({ ...filterValues, pageNumber: filterValues.pageNumber - 1 });
                filterNotes();
            }
        }

    }

    return (
        <>
            <Card fluid>
                <Card.Content style={{ height: 50 }}>
                    <Header style={{ color: '#0099da' }} floated='left'>Notes</Header>
                    <Icon style={{ float: 'right' }} link color='blue' size='large' name='add circle'
                        onClick={handleNewNote}
                    />
                </Card.Content>
                <Card.Content>
                    <Dimmer active={isLoading} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    {notes.length > 0 ?
                        <Table style={{ marginTop: -12 }} selectable unstackable striped basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Subject</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {notes.map(note => {
                                    return (
                                        <Table.Row style={{ cursor: 'pointer' }}>
                                            <Table.Cell>{note.subject}</Table.Cell>
                                            <Table.Cell>{note.updater}</Table.Cell>
                                            <Table.Cell style={{ display: 'flex' }}>
                                                <Icon style={{ cursor: 'pointer' }} link color='blue' size='large' name='edit outline'
                                                    onClick={() => handleSelectNote(note)}
                                                />
                                                <Icon style={{ cursor: 'pointer' }} link color='red' size='large' name='trash alternate'
                                                    onClick={() => handleDeleteNote(note.id!)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table> :
                        <div style={{ textAlign: 'center', color: '#7a7a7a' }}>
                            <HiOutlineEmojiSad style={{ marginTop: 10 }} fontSize={48} />
                            <Header style={{ color: '#7a7a7a', marginBottom: 20 }} as='h4'>Nothing Here</Header>
                        </div>
                    }

                </Card.Content>
                <Card.Content extra>
                    <MdOutlineKeyboardArrowRight
                        style={{
                            backgroundColor: '#dbdbdb',
                            borderColor: '#dbdbdb',
                            boxShadow: 'none',
                            color: '#7a7a7a',
                            opacity: .5,
                            fontSize: 25,
                            float: 'right',
                            cursor: 'pointer',
                            marginTop: 3
                        }}
                        onClick={() => handlePageChange('next')}
                    />
                    <Header style={{ marginTop: 5 }} color='grey' floated='right' as='h4'>{filterValues.pageNumber}</Header>
                    <MdOutlineKeyboardArrowLeft
                        style={{
                            backgroundColor: '#dbdbdb',
                            borderColor: '#dbdbdb',
                            boxShadow: 'none',
                            color: '#7a7a7a',
                            opacity: .5,
                            fontSize: 25,
                            float: 'right',
                            cursor: 'pointer',
                            marginTop: 3
                        }}
                        onClick={() => handlePageChange('prev')}
                    />
                </Card.Content>
            </Card>
            <NoteFormModal open={openModal} onClose={() => { setOpenModal(false) }} />
        </>
    )
}

export default observer(NoteList);
