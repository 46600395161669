import { makeAutoObservable } from 'mobx';
import { history } from '../..';

export default class CommonStore {
    appLoaded = false;
    isCollapseSideBar = false;
    subNavId: number | undefined = 0;
    isMobile = false;
    isGridStack = false;

    constructor() {
        makeAutoObservable(this);
    }

    get dateNow() {
        return new Date().getDate() + ' ' + new Date().toLocaleString('en-AU', { month: 'long', year: 'numeric' })
    }

    get screenWidth() { return window.innerWidth };

    setIsMobile = (state: boolean) =>  this.isMobile = state;
    setIsGridStack = (state: boolean) => this.isGridStack = state;

    setAppLoaded = () => {
        this.appLoaded = true;
    }

    toggleSidebar = () => {
        this.isCollapseSideBar = !this.isCollapseSideBar;
    }

    setIsCollapseSideBar = (state: boolean) => this.isCollapseSideBar = state;

    setSubNav = (id?: number) => {
        if (id === this.subNavId || id === 0)
            this.subNavId = 0;
        else {
            this.subNavId = id;

            if (this.isMobile) this.setIsCollapseSideBar(true);
        }

    };

    reRenderApp = () => history.go(0);
}