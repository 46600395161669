import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Grid, Header, Icon, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import PasswordInput from '../../app/common/form/PasswordInput';
import { useStore } from '../../app/stores/store';


function ResetPasswordForm() {
    const { authStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const validationSchema = Yup.object({
        password: Yup.string()
            .required('New Password field is required')
            .min(8, 'Password should have a minimum of 8 characters.')
            .matches(/^(?=.*\d)(?=.*[a-zA-Z]).+$/, 'Password should be a combination of alphanumeric. A special character is optional.')
        ,
        confirmPassword: Yup.string()
            .required('Confirm Password field is required')
            .test('passwords-match', 'New Password and Confirm Password should be equal.', function (value) {
                return this.parent.password === value
            })
    });

    return (
        <Formik
            initialValues={{
                password: '',
                confirmPassword: '',
                userCode: id
            }}
            onSubmit={(values) => authStore.resetPassword(values)}
            validationSchema={validationSchema}
        >
            {
                ({ handleSubmit, isSubmitting }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: '436px',
                            position: 'relative',
                            top: '-20px',
                            padding: '54px 32px',
                            boxShadow: '0px 3px 6px rgb(21 146 255 / 16%)',
                            borderRadius: '16px',
                            backgroundColor: '#ffffff'
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                <Image src={`../../assets/taxcess-logo.svg`} style={{ width: '26em' }} centered />
                            </div>
                            <Header as='h3' style={{ marginBottom: '32px', textAlign: 'left' }}>Reset Password</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <PasswordInput name='password' placeholder='New Password' />
                                <PasswordInput name='confirmPassword' placeholder='Confirm Password' />
                                <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                    <Grid.Column style={{ textAlign: 'left' }}>
                                        <div style={{ color: '#6a7e99', textAlign: 'left', width: 150, borderRadius: 30, height: 42, fontSize: 15, fontWeight: 500, padding: '0.6em 0', cursor: 'pointer' }}>
                                            <Icon color='blue' className='angle left icon' />
                                            <a onClick={() => history.push('/')}>Back</a >
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button loading={isSubmitting} color='blue' style={{ backgroundColor: 'rgb(21, 189, 254)', borderRadius: '8px' }} fluid content='Reset Password' type='submit' size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default ResetPasswordForm;