import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { history } from "../..";
import agent from '../api/agents';
import CookieHelper from '../helpers/cookieHelper';
import { Auth } from '../models/Auth';
import { ChangePasswordForm, ResetPasswordFormValues, User, UserFormValues } from "../models/user";
import * as Role from '../constants/role';
import * as Permission from '../constants/permission';
import { SidebarData } from '../common/sidebar-menu/SidebarData';

const _cookieHelper = new CookieHelper();

export default class AuthStore {
    token: string | null = _cookieHelper.get('jsonWebToken') || null;
    refreshToken: string | null = _cookieHelper.get('refreshToken') || null;
    refreshTokenTimeout: any;
    user: User | null = null;
    auth: Auth | null = null;
    isLoading = false;
    hubConnection: HubConnection | null = null;
    appHub = process.env.REACT_APP_HUB_URL;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    _cookieHelper.set('jsonWebToken', token);
                }
                else {
                    _cookieHelper.delete('jsonWebToken');
                }
            }
        );

        reaction(
            () => this.refreshToken,
            token => {
                if (token) {
                    _cookieHelper.set('refreshToken', token);
                }
                else {
                    _cookieHelper.delete('refreshToken');
                }
            }
        );
    }

    createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.appHub!, {
                accessTokenFactory: () => this.token!,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Warning)
            .build();

        this.hubConnection?.start()
            .then(() => {
                agent.Hubs.register(this.hubConnection?.connectionId!).then(() => {
                    console.log('Connected');
                })
            })
            .catch(e => console.error(e));

        this.hubConnection.on('onUserDeactivated', (deactivated: boolean) => {
            this.logout();
            toast.error('This user is deactivated by the admin.');
            console.log('deactivated');
        })
    }

    get isLoggedIn() {
        return !!this.user;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setRefreshToken = (refreshToken: string | null) => {
        this.refreshToken = refreshToken;
    }

    login = async (creds: UserFormValues) => {
        try {
            const accessToken = await agent.Account.login(creds);
            this.setToken(accessToken.jsonWebToken!);
            this.setRefreshToken(accessToken.refreshToken!);
            this.user = await this.getCurrentUser();
            this.setUserAuth();

            if (this.auth?.role === Role.Admin) {
                history.push('/users');
            }
            else {
                let path = "/users";

                for (let data of SidebarData) {
                    if (this.hasPermission(data.permission)) {
                        path = data.path;
                        break;
                    }
                }

                history.push(path);
            }
        } catch (e) {
            toast.error('Invalid Email or Password.')
            console.error(e);
        }
    }

    get defaultUserPath() {
        let path = "/users";

        for (let data of SidebarData) {
            if (this.hasPermission(data.permission)) {
                path = data.path;
                break;
            }
        }

        return path;
    }

    changePassword = async (form: ChangePasswordForm) => {
        this.isLoading = true;

        try {
            let accessToken = await agent.Users.changePassword(form);

            this.setToken(accessToken.jsonWebToken!);
            this.setRefreshToken(accessToken.refreshToken!);
            this.user = await agent.Account.current();
            this.setUserAuth();
            this.isLoading = false;

            if (this.auth?.role === Role.Admin) {
                history.push('/users');
            }
            else {
                let path = "/users";

                for (let data of SidebarData) {
                    if (this.hasPermission(data.permission)) {
                        path = data.path;
                        break;
                    }
                }

                history.push(path);
            }

            return accessToken;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    getCurrentUser = async () => {
        try {
            const user = await agent.Account.current();
            //this.createHubConnection();
            runInAction(() => {
                this.user = user;
                this.setUserAuth();
            });

            return user;
        } catch (e: any) {
            return Promise.reject(e.response.data!);
        }
    }

    resetPassword = async (credentials: ResetPasswordFormValues) => {
        try {
            await agent.Account.resetPassword(credentials);
            toast.success("You have successfully reset your password.");
            history.push('/login');
        } catch (e: any) {
            return Promise.reject(e.response.data!);
        }
    }

    forgotPassword = async (creds: { email: string }) => {
        try {
            await agent.Account.forgotPassword(creds);
            toast.success("Check your email for instructions on how to reset your password.");
            history.push('/login');
            return true;
        } catch (e: any) {
            return Promise.reject(e.response.data!);
        }
    }

    logout = () => {
        if (_cookieHelper.get('jsonWebToken') && _cookieHelper.get('refreshToken')) {
            agent.Account.deleteRefreshToken({
                refreshToken: this.refreshToken!,
                jsonWebToken: this.token!
            });
            //agent.Hubs.unregister(this.hubConnection?.connectionId!).then(this.removeAuth);
        }

        this.removeAuth();
    }

    removeAuth = () => {
        this.setToken(null);
        this.setRefreshToken(null);
        this.user = null;
        this.auth = null;
        _cookieHelper.delete('jsonWebToken');
        _cookieHelper.delete('refreshToken');
        this.hubConnection?.stop();
        history.push('/login');
    }

    refreshTheToken = async () => {
        try {
            const accessToken = await agent.Account.refreshToken({
                jsonWebToken: this.token!,
                refreshToken: this.refreshToken!
            });

            runInAction(() => {
                this.setToken(accessToken.jsonWebToken!);
                this.setRefreshToken(accessToken.refreshToken!);
            })
        } catch (e: any) {
            return Promise.reject(e.response.data!);
        }
    }

    setUserAuth = () => {
        if (this.token) {
            var base64Url = this.token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            const { permissions, role } = JSON.parse(jsonPayload);

            if (Array.isArray(permissions)) {
                this.auth = {
                    role: role,
                    permissions: permissions
                }
            }
            else {
                this.auth = {
                    role: role,
                    permissions: [permissions]
                }
            }
        }

        return this.auth;
    };

    hasPermission = (permission: string | undefined) => {
        if (!this.auth) this.setUserAuth();

        if (permission) {
            let userPermission = this.auth?.permissions.find(p => p == permission);

            if (this.auth?.role === Role.Admin && permission === Permission.Registration)
                return false;

            return this.auth?.role === Role.Admin || userPermission != undefined;
        }

        return false;
    }
}