import { makeStyles, Paper } from '@material-ui/core';
import MaterialTable, { Action, Column, MaterialTableProps, Query, QueryResult } from 'material-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo, useState } from 'react';
import { Button, Grid, Header, Label, SemanticWIDTHS } from 'semantic-ui-react';
import { OptionSelect } from '../models/option';
import DateRangeFilter from './DateRangeFilter';
import DropdownFilter from './DropdownFilter';
import SearchToolbar from './SearchToolbar';

const useStyles = makeStyles({
    theme: {
        boxShadow: '0 4px 8px 0 rgb(77 141 154 / 60%)',
        '& [class*="Component-horizontalScrollContainer"]': {
            padding: '0 8px',
            overflowY: 'hidden'
        },
        '& .MuiTablePagination-root': {
            borderBottom: 'none',
            whiteSpace: 'nowrap'
        },
        '& .MuiTablePagination-toolbar': {
            minHeight: '60px',
        },
        '& .MuiTablePagination-toolbar .MuiButton-label': {
            fontSize: '1rem'
        },
        '& .MuiTablePagination-toolbar .MuiIconButton-label > span': {
            fontSize: '1.6rem'
        }
    }
});

interface Props<T extends object> extends MaterialTableProps<T> {
    columns: Column<T>[];
    total: number;
    data: T[] | ((q: Query<T>) => Promise<QueryResult<T>>);
    onChangePage: (page: number) => void;
    pageSize: number;
    pageNumber: number;
    isLoading?: boolean;
    actions?: ((rowData: T) => Action<object>)[];
    onSearchChange?: ((searchText: string) => void) | undefined;
    search?: boolean;
    toolbar?: boolean;
    searchPlaceholder?: string;
    style?: CSSProperties;
    sorting?: boolean;
    draggable?: boolean;
    rightButton?: {
        label: string
        onClick: any
    }
    rightButton2?: {
        label: string
        onClick: any
        loading?: boolean
    }
    dateFilterLabel?: string;
    onDateRangeFilter?: (startDate?: Date, endDate?: Date) => void | undefined;
    dateRangeValues?: Date[];
    dateRangeFilter?: boolean;
    filter?: {
        option: OptionSelect[]
    };
    note?: any
    onDataFilterChange?: (value?: string) => void;
    isMobile?: boolean;
    toolbarHeader?: JSX.Element;
    actionStyle?: CSSProperties
}

function PaginatedTable<T extends object>(props: Props<T>) {
    const classes = useStyles();

    const addCellStyleInColumns = () => {
        return props.columns.map((c, i) => {
            return {
                ...c,
                cellStyle: {
                    borderBottom: 'none',
                    paddingRight: '30px',
                    padding: '10px'
                },
                headerStyle: {
                    paddingRight: '30px',
                }
            }
        });
    }

    let columns = [props.search, props.dateRangeFilter ? true : false, props.filter ? true : false || props.rightButton ? true : false].filter(v => v).length;

    const [filterText, setFilterText] = useState('All');
    const memo = useMemo(() => props.data, [props.data])
    return (
        <MaterialTable<T>
            title={<div style={{ color: 'rgb(33, 133, 208)' }} className='ui header'>{props.title}</div>}
            columns={addCellStyleInColumns()}
            actions={props.actions}
            page={props.pageNumber > 0 ? props.pageNumber - 1 : props.pageNumber}
            totalCount={props.total ?? 0}
            localization={{
                body: {
                    emptyDataSourceMessage: <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>No Records</Header>,
                },
                toolbar: { searchPlaceholder: props.searchPlaceholder ?? 'Search name...' },
                ...props.localization
            }}
            onChangePage={(p) => props.onChangePage(p + 1)}
            onSearchChange={props.search ? props.onSearchChange : undefined}
            components={{
                Toolbar: (toolbarProps) => (
                    <>
                        <div
                            style={{
                                display: !props.isMobile ? 'flex' : "block",
                                justifyContent: "left",
                                width: "100%",
                                padding: '16px',
                                color: '#0099da',
                                borderBottom: '2px solid #d7e8f9'
                            }}
                        >
                            <div style={{ flexGrow: 1 }}>
                                <p style={{ fontSize: '18px !important', fontWeight: 700 }}>{toolbarProps.title}</p>
                            </div>
                            <div style={{ flexGrow: 1, display: !props.isMobile ? 'flex' : "block", justifyContent: 'flex-end' }}>
                                {props.filter?.option.map(a => {
                                    return (
                                        <div key={a.key} style={{ display: 'flex', marginRight: 15 }}>
                                            <Label circular style={{ backgroundColor: a.color, marginTop: 4, marginRight: 10 }} empty key='yellow' /> <p>{a.text}</p>
                                        </div>
                                    )
                                })}
                                {props.note}
                            </div>
                        </div>
                        {props.toolbarHeader &&
                            props.toolbarHeader
                        }
                        <Grid padded stackable columns={columns as SemanticWIDTHS}>
                            <Grid.Row>
                                {props.search &&
                                    <Grid.Column>
                                        <SearchToolbar {...toolbarProps} />
                                    </Grid.Column>}

                                {props.dateRangeFilter &&
                                    <Grid.Column>
                                        <DateRangeFilter
                                            isMarginAuto={props.search}
                                            isMobile={props.isMobile}
                                            toolbarProps={toolbarProps}
                                            dateFilterLabel={props.dateFilterLabel}
                                            onDateRangeFilter={props.onDateRangeFilter}
                                            dateRangeValues={props.dateRangeValues} />
                                    </Grid.Column>}
                                {props.filter &&
                                    <Grid.Column>
                                        <DropdownFilter text={filterText}
                                            isMobile={props.isMobile}
                                            onChange={(value: string, text: string) => {
                                                if (props.onDataFilterChange)
                                                    props.onDataFilterChange(value);

                                                setFilterText(text);

                                            }} options={props.filter.option} />
                                    </Grid.Column>

                                }
                                <Grid.Column>
                                    {props.rightButton2 &&
                                        <Button
                                            floated={props.isMobile ? 'left' : 'right'}
                                            style={props.isMobile ? { width: 240, marginBottom: 20 } : {}}
                                            primary
                                            loading={props.rightButton2?.loading}
                                            content={props.rightButton2.label}
                                            onClick={props.rightButton2.onClick} />
                                    }
                                    {props.rightButton &&
                                        <Button
                                            floated={props.isMobile ? 'left' : 'right'}
                                            style={props.isMobile ? { width: 240 } : {}}
                                            primary
                                            content={props.rightButton.label}
                                            onClick={props.rightButton.onClick} />
                                    }

                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </>
                ),
                Container: (props) => <Paper className={classes.theme} {...props} />,
                ...props.components
            }}
            data={memo}
            isLoading={props.isLoading ?? false}
            style={{ ...props.style }}
            onRowClick={props.onRowClick}
            options={{
                //rowStyle: props.options?.rowStyle,
                rowStyle: (_data: any, index: number, _level: number) => {
                    return index % 2 ? { ...props.options?.rowStyle, backgroundColor: '#f3f9ff', } : { ...props.options?.rowStyle };
                },
                paginationType: 'normal',
                actionsColumnIndex: props.actions ? - 1 : undefined,
                showTitle: false,
                pageSize: props.pageSize,
                pageSizeOptions: [props.pageSize],
                loadingType: 'linear',
                debounceInterval: 500,
                tableLayout: 'auto',
                headerStyle: {
                    fontWeight: 500,
                    padding: 11,
                    fontSize: '1em',
                    borderBottom: '1px solid rgb(224 224 224)',
                },
                searchFieldAlignment: 'left',
                searchFieldVariant: 'standard',
                searchFieldStyle: { padding: '10px', width: '100%' },
                toolbarButtonAlignment: undefined,
                search: props.search || false,
                toolbar: props.toolbar || false,
                minBodyHeight: 250,
                overflowY: 'hidden',
                emptyRowsWhenPaging: true,
                actionsCellStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: `${props.actions?.length ? 5 / props.actions.length : 0}px 15px`,
                    width: 'auto',
                    marginBottom: '-1px',
                    borderBottom: 'none',
                    ...props.actionStyle
                },
                filterCellStyle: { border: 'none' },
                sorting: props.sorting,
                draggable: props.draggable,
                columnsButton: true
            }}
        />
    );
}

export default observer(PaginatedTable);