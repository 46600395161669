import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiHelpCircle, BiUser } from "react-icons/bi";
import { FaListAlt, FaUsers, FaUsersCog } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { RiFileShield2Line } from "react-icons/ri";
import * as Permissions from '../../constants/permission';
import { VscOpenPreview } from "react-icons/vsc";

export const SidebarData = [
    { key: 'data-1', text: "Clients", path: "/clients", icon: <FaUsers style={{ fontSize: '1.3rem' }} />, permission: Permissions.Clients },
    { key: 'data-2', text: "Agreements", path: "/agreements", icon: <RiFileShield2Line style={{ fontSize: '1.3rem' }} />, permission: Permissions.Agreements },
    { key: 'data-3', text: "Registration", path: "/registrations", icon: <AiOutlineUsergroupAdd style={{ fontSize: '1.3rem' }} />, permission: Permissions.Registration },
    { key: 'data-4', text: "Users", path: "/users", icon: <BiUser style={{ fontSize: '1.3rem' }} />, permission: Permissions.User },
    { key: 'data-5', text: "Roles", path: "/roles", icon: <FaUsersCog style={{ fontSize: '1.3rem' }} />, permission: Permissions.Roles },
    { key: 'data-6', text: "Master List", path: "/masterlist", icon: <FaListAlt style={{ fontSize: '1.3rem' }} />, permission: Permissions.MasterList },
    { key: 'data-7', text: "Review List", path: "/reviewlist", icon: <VscOpenPreview style={{ fontSize: '1.3rem' }} />, permission: Permissions.ReviewerList },
    { key: 'data-8', text: "Settings", path: "/settings", icon: <IoMdSettings style={{ fontSize: '1.3rem' }} />, permission: Permissions.Roles },
    { key: 'data-9', text: "Help", path: "/helps", icon: <BiHelpCircle style={{ fontSize: '1.3rem' }} />, permission: Permissions.Help },
];

