import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from '../../models/sideBar';
import { useStore } from '../../stores/store';

//#region "Styling"
const SidebarLink = styled(NavLink) <{ showSidebar?: boolean }>`
    display: ${props => props.showSidebar ? "flex" : "none"};
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.palette.common.black};
    font-size: 1rem;
    padding: .2rem 1rem;
    font-weight: 400;
    text-decoration: none;
    height: 40px;
    width:100%;
    color: #4a4a4a;
    ${p => (!p.showSidebar && `justify-content: center;`)};
    

      &:hover {
            background-color: ${props => props.theme.palette.primary.contrastText};
            color: ${props => props.theme.palette.primary.main};
            cursor: pointer;
      }

        &.active{
            color: ${props => props.theme.palette.primary.main};
            border-left: 3px solid ${props => props.theme.palette.primary.main};
        }
    `;


const NonLink = styled.div<{ showSidebar?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.palette.common.black};
    font-size: 1rem;
    padding: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
    text-decoration: none;
    height: 60px;
    width:100%;
    ${p => (!p.showSidebar && `justify-content: center;`)};
    

      &:hover {
            background-color: ${props => props.theme.palette.primary.main};
            color: ${props => props.theme.palette.primary.contrastText};
            cursor: pointer;
            border-radius: 8px;
      }

        &.active{
            background-color: ${props => props.theme.palette.primary.main};
            color: ${props => props.theme.palette.primary.contrastText};
            border-radius: 8px;
        }
    `;

const SidebarLabel = styled.span<{ showSidebar?: boolean }>`
    margin-left: 16px;
  ${p => (!p.showSidebar ? ` display:block;` : `display:none;`)};
`;

const MenuContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div<{ showSidebar?: boolean, isOpen: boolean }>`
    width: 100%;
    background-color: #fff;
    max-height: ${p => (p.isOpen ? '700px' : '0px')};
    opacity: ${p => (p.isOpen ? '1' : '0')};
    visibility: ${p => (p.isOpen ? 'visible' : 'hidden')};
    transition: max-height .5s ease, opacity .3s ease, visibility .1s;

    ${p => (p.showSidebar && `padding: 15px;
        background-color: #fff;
        position: absolute;
        top: -45px;
        left: 75px;
        z-index: 1;
        border-radius: 8px;
        width: 257px;
        border: solid #ddd .3px;
    `)};
`;

const DropdownLink = styled(SidebarLink) <{ showSidebar?: boolean }>`
  background: #fff;
  height: 60px;
  padding-left: 3rem;
  align-content: center;
  justify-content: left;

    span{
        white-space: nowrap;
    }

    ${p => (p.showSidebar && `padding:1em;

         span{
            margin-left: 0;
         }
    `)};

    &:hover {
        background-color: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.contrastText};
        cursor: pointer;
    }
`;

const DropdownIconContainer = styled.div<{ showSidebar?: boolean }>`
    font-size: 1.3em;
    margin-left: .3em;
    padding-top: .3em;
    display:${p => (!p.showSidebar ? 'block' : 'none')};
`;

//#endregion "Styling"

interface Props {
    item: Menu;
    key: string;
    isSettings?: boolean;
}

const SubMenu = ({ item, key, isSettings }: Props) => {
    const { commonStore } = useStore();
    const { isCollapseSideBar, subNavId, setSubNav } = commonStore;

    return (
        <>
            <MenuContainer key={key} style={{ marginTop: isSettings? '5em' : 0 }}>
                {item.path ? (
                    <SidebarLink to={item.path!} key={item.id} showSidebar={!isCollapseSideBar} onClick={(() => setSubNav(item.id))}>
                        {item.icon}
                        <SidebarLabel showSidebar={isCollapseSideBar}>{item.text}</SidebarLabel>
                    </SidebarLink>)
                    :
                    (
                        <NonLink showSidebar={!isCollapseSideBar} key={item.id} onClick={item.subNav && (() => setSubNav(item.id))}>
                            {item.icon}
                            <SidebarLabel showSidebar={isCollapseSideBar}>{item.text}</SidebarLabel>
                        </NonLink>
                    )}
                <DropdownContainer isOpen={item.id === subNavId} showSidebar={isCollapseSideBar}>
                    {item.subNav?.map((item, index) => {
                        return (
                            <DropdownLink key={item.text} showSidebar={isCollapseSideBar} to={item.path!} onClick={() => setSubNav(item.id)}>
                                {item.icon}
                                <SidebarLabel>{item.text}</SidebarLabel>
                            </DropdownLink>
                        );
                    })}
                </DropdownContainer>
            </MenuContainer>
        </>
    );
};

export default observer(SubMenu);
