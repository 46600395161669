import { Form, Formik } from 'formik';
import { Button, Card, Grid, Header, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import InveterviewLabel from '../../app/styled-element/InterviewLabel';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import RadioButtons from '../../app/common/form/RadioButtons';
import { useEffect, useState } from 'react';
import { intergerErrorMessage, thisFieldIsRequired } from '../../app/constants/message';
import LoadingComponet from '../../app/layout/LoadingComponent';
import RefundAdvanceModal from '../agreement/RefundAdvanceModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import GridRow from './sub/GridRow';
import InterviewHeader from './sub/InterviewHeader';
import FooterButtons from './sub/FooterButtons';
import { Status } from '../../app/constants/agreementStatus';
import NoteList from './notes/NoteList';
import { InterviewThirdPage } from '../../app/models/Agreement';

const yupValidation = Yup.number().required(thisFieldIsRequired).integer(intergerErrorMessage).moreThan(-1, intergerErrorMessage);

const InterviewForm3 = () => {
    const swal = withReactContent(Swal);
    const history = useHistory();
    const { agreementStore, lookupStore: { paymentOptions, loadPaymentOptions } } = useStore();
    const { viewOnly, setViewOnly, get, loadingInitial, agreement, setAgreement, isLoading, cancel, save, setRefundAdvanceQuestionModalOpen, getAllTrueRefundAdvanceQuestion, setRefundAdvanceQuestion } = agreementStore;
    const { id, view } = useParams<{ id: string, view: string | undefined }>();
    //const [isPaymentOptionChanged, setIsPaymentOptionChanged] = useState(false);
    const [isPartnerEarnIncomeThisTaxYear, setIsPartnerEarnIncomeThisTaxYear] = useState(false);
    const [ishaveDependentChildren, setIshaveDependentChildren] = useState(false);
    const [activeAllOption, setActiveAllOption] = useState({
        haveAnyGovtDebt: false,
        currentlyPayChildSupport: false,
        haveCurrentChildSupportDebt: false
    });

    useEffect(() => {
        setViewOnly(view ? true : false);
        loadPaymentOptions().then((options) => {
            get(id).then((a) => {
                setIsPartnerEarnIncomeThisTaxYear(a?.interview.interviewThirdPage.partnerEarnIncomeThisTaxYear!);
                setIshaveDependentChildren(a?.interview.interviewThirdPage.haveDependentChildren!);
                setActiveAllOption({
                    haveAnyGovtDebt: a?.interview.interviewThirdPage.haveAnyGovtDebt,
                    currentlyPayChildSupport: a?.interview.interviewThirdPage.currentlyPayChildSupport,
                    haveCurrentChildSupportDebt: a?.interview.interviewThirdPage.haveCurrentChildSupportDebt
                });
            });
        })

    }, [id]);

    const handleNext = (value: any) => {
        if (agreement) {
            setAgreement({
                ...agreement,
                interview: {
                    ...agreement.interview,
                    interviewThirdPage: value,
                    interviewFourthPage: {
                        ...agreement.interview.interviewFourthPage, estimatedGovernmentDebt: value.estimateTotalGovtDebt
                    }
                }
            });
            history.push(`/agreements/interview/4/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleBack = (value: any) => {
        if (agreement) {
            setAgreement({
                ...agreement,
                interview: {
                    ...agreement.interview,
                    interviewThirdPage: value,
                    interviewFourthPage: {
                        ...agreement.interview.interviewFourthPage, estimatedGovernmentDebt: value.estimateTotalGovtDebt
                    }
                }
            });
            history.push(`/agreements/interview/2/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleSave = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewThirdPage: value } });
            save(id)
        }
    }

    const storeAgreement = (value: InterviewThirdPage) => {
        if (agreement) {
            setAgreement({
                ...agreement,
                interview: {
                    ...agreement.interview,
                    interviewThirdPage: value,
                    interviewFourthPage: {
                        ...agreement.interview.interviewFourthPage,
                        //refundAdvancePayment: !isPaymentOptionChanged ? agreement.interview.interviewFourthPage.refundAdvancePayment : 0
                    }
                }
            });
        }
    }

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <>
            <Grid stackable className='gridForm'>
                <Grid.Row>
                    <LoadingComponet active={isLoading} content='Please wait...' />
                    <Grid.Column width={11}>
                        <Card fluid>
                            <InterviewHeader />
                            <Card.Content>
                                <Formik
                                    enableReinitialize
                                    initialValues={agreement?.interview.interviewThirdPage!}
                                    onSubmit={(values, { setErrors }) => handleNext(values)}
                                    validationSchema={Yup.object({
                                        partnerIncomeEstimate: yupValidation,
                                        estimateTotalGovtDebt: yupValidation,
                                        feeFromRefundCharge: yupValidation,
                                        refundAdvanceCharge: yupValidation,
                                        discount: yupValidation,
                                        quote: yupValidation,
                                        isTheFeeQuoteOkay: !viewOnly ? Yup.boolean().required().nullable() : Yup.boolean().nullable()
                                    })}
                                >
                                    {
                                        ({ handleSubmit, values, setFieldValue }) => (
                                            <>
                                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                                    <Grid>
                                                        <Grid.Row> <Grid.Column width={16} >
                                                            <p style={{
                                                                display: 'block',
                                                                fontSize: '1.15em',
                                                                fontWeight: 700,
                                                                textTransform: 'none',
                                                            }}
                                                            >
                                                                New substantiation rules require us to gather this information to ensure your personal tax return is accurate.
                                                            </p> </Grid.Column> </Grid.Row>
                                                        <GridRow firstColumn='Full name of partner' secondColumn={<TextInput disabled={viewOnly} name='partnerName' placeholder='Full name of partner (living with you)' type='text' />} />
                                                        <GridRow firstColumn='Partner DOB' secondColumn={<TextInput disabled={viewOnly} name='partnerDateOfBirth' placeholder='Partner DOB' type='date' />} />
                                                        <GridRow firstColumn='Did your partner earn income this tax year?' secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='Did you partner earn income this tax year?' name='partnerEarnIncomeThisTaxYear' onChange={(e: any, { value }: any) => setIsPartnerEarnIncomeThisTaxYear(value)} />} />
                                                        <GridRow style={{ display: isPartnerEarnIncomeThisTaxYear ? 'block' : 'none' }} firstColumn='Partner Income Estimate' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='partnerIncomeEstimate' placeholder='How did you first hear about us?' type='number' />} />
                                                        <GridRow firstColumn={<InveterviewLabel content='Receive government payments?' subContentNextLine subContent='(Centrelink, Child Support, HELP etc)' />} secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='Did you partner earn income this tax year?' name='receiveGovernmentPayments' />} />
                                                        <GridRow firstColumn='Do you have dependant children?' secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='Did you partner earn income this tax year?' name='haveDependentChildren' onChange={(e: any, { value }: any) => setIshaveDependentChildren(value)} />} />
                                                        <GridRow style={{ display: ishaveDependentChildren ? 'block' : 'none' }} firstColumn='Under 18' secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='Under 18' name='haveDependentChildrenUnder18' />} />
                                                        <GridRow style={{ display: ishaveDependentChildren ? 'block' : 'none' }} firstColumn='18 to 21 y.o.' secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='18 to 21 y.o.' name='haveDependentChildrenBetween18and21' />} />
                                                        <GridRow style={{ display: ishaveDependentChildren ? 'block' : 'none' }} firstColumn='21 to 24 y.o. F/T Students' secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='21 to 24 y.o. F/T Students' name='haveDependentChildrenBetween21and24' />} />
                                                        <GridRow firstColumn='Do you currently pay child support?'
                                                            secondColumn={
                                                                <RadioButtons disabled={viewOnly} yesOrNo placeholder='Do you currently pay child support?' name='haveCurrentChildSupportDebt'
                                                                    onChange={(e: any, { value }: any) => {
                                                                        setActiveAllOption({ ...activeAllOption, currentlyPayChildSupport: value });
                                                                        storeAgreement({
                                                                            ...values, haveCurrentChildSupportDebt: value,
                                                                            estimateTotalGovtDebt: 0,
                                                                            feeFromRefundCharge: 0,
                                                                            refundAdvanceCharge: 0,
                                                                            paymentOptionId: value && values.paymentOptionId !== paymentOptions[2].id ? paymentOptions[0].id! : agreement?.interview.interviewThirdPage.paymentOptionId!
                                                                        });
                                                                    }}
                                                                />} />
                                                        <GridRow firstColumn='Do you have a current child support debt?'
                                                            secondColumn={
                                                                <RadioButtons disabled={viewOnly} yesOrNo placeholder='Do you currently pay child support?' name='currentlyPayChildSupport'
                                                                    onChange={(e: any, { value }: any) => {
                                                                        setActiveAllOption({ ...activeAllOption, haveCurrentChildSupportDebt: value });
                                                                        storeAgreement({
                                                                            ...values, currentlyPayChildSupport: value,
                                                                            estimateTotalGovtDebt: 0,
                                                                            feeFromRefundCharge: 0,
                                                                            refundAdvanceCharge: 0,
                                                                            paymentOptionId: value && values.paymentOptionId !== paymentOptions[2].id ? paymentOptions[0].id! : agreement?.interview.interviewThirdPage.paymentOptionId!
                                                                        });
                                                                    }}
                                                                />} />
                                                        <GridRow firstColumn='Do you have any other govt debt?' secondColumn={
                                                            <RadioButtons disabled={viewOnly} yesOrNo placeholder='Do you have any other govt debt?' name='haveAnyGovtDebt'
                                                                onChange={(e: any, { value }: any) => {
                                                                    setActiveAllOption({ ...activeAllOption, haveAnyGovtDebt: value });
                                                                    storeAgreement({
                                                                        ...values, haveAnyGovtDebt: value,
                                                                        estimateTotalGovtDebt: 0,
                                                                        feeFromRefundCharge: 0,
                                                                        refundAdvanceCharge: 0,
                                                                        paymentOptionId: value && values.paymentOptionId !== paymentOptions[2].id ? paymentOptions[0].id! : agreement?.interview.interviewThirdPage.paymentOptionId!
                                                                    });
                                                                }}
                                                            />} />
                                                        <GridRow firstColumn='If yes to above please estimate your total govt debt' secondColumn={<TextInput disabled={(!activeAllOption.haveAnyGovtDebt && !activeAllOption.haveCurrentChildSupportDebt) || viewOnly} icon='dollar' name='estimateTotalGovtDebt' placeholder='If yes to above please estimate your total govt debt' type='number' />} />
                                                        <GridRow firstColumn='Fee from Refund charge' secondColumn={<TextInput onKeyUp={(e) => storeAgreement(values)} disabled={!(!activeAllOption.currentlyPayChildSupport && !activeAllOption.haveAnyGovtDebt && !activeAllOption.haveCurrentChildSupportDebt) || viewOnly} icon='dollar' name='feeFromRefundCharge' placeholder='Fee from Refund charge' type='number' />} />
                                                        <GridRow style={{ display: "none" }} firstColumn='Refund Advance charge' secondColumn={<TextInput disabled icon='dollar' name='refundAdvanceCharge' placeholder='Refund Advance charge' type='number' />} />
                                                        <GridRow firstColumn='Discount' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='discount' placeholder='Discount' type='number' />} />
                                                        <GridRow
                                                            firstColumn='Quote'
                                                            secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='quote' placeholder='Quote' type='number' />}
                                                            thirdColumn={
                                                                <div style={{ display: agreement?.agreementStatus.name === Status.ForESign || agreement?.agreementStatus.name === Status.Esigned || agreement?.agreementStatus.name === Status.PaperSigned || viewOnly ? 'none' : '' }}>
                                                                    <TextInput hidden name='isTheFeeQuoteOkay' placeholder='' />
                                                                    <div
                                                                        onClick={() => {
                                                                            swal.fire({
                                                                                html: `Is the fee quote OK?
                                                                               <br>
                                                                                Are we ok to you to our tax portal?
                                                                               <br>
                                                                                We can only offer the FFR option if your refund covers our fees. If not, you will need to pay upfront today, ok?`,
                                                                                icon: 'question',
                                                                                showCancelButton: true,
                                                                                confirmButtonColor: '#3085d6',
                                                                                cancelButtonColor: '#aaa',
                                                                                confirmButtonText: 'Yes',
                                                                                cancelButtonText: 'No',
                                                                                allowOutsideClick: false,
                                                                            }).then((result) => storeAgreement({ ...values, isTheFeeQuoteOkay: result.isConfirmed }))
                                                                        }}
                                                                        style={{ marginTop: 13, cursor: 'pointer' }}>
                                                                        <Icon name='info circle' size='large' />
                                                                        <span style={{
                                                                            color: '#ff3860',
                                                                            visibility: agreement?.interview.interviewThirdPage.isTheFeeQuoteOkay === null ? 'visible' : 'hidden'
                                                                        }}>An answer is required</span>
                                                                    </div>
                                                                </div>}
                                                        />
                                                        <GridRow firstColumn='Payment Options' secondColumn={
                                                            <RadioButtons
                                                                disabled={viewOnly}
                                                                preventDefault
                                                                grouped={true}
                                                                buttons={paymentOptions
                                                                    .filter(o => 'Refund Advance' !== o.name) // HIDE Refund Advance
                                                                    .sort((a, b) => a.orderNumber - b.orderNumber)
                                                                    .map((option) => {
                                                                        return {
                                                                            label: option.name,
                                                                            value: option.id,
                                                                            name: option.id,
                                                                            checked: false,
                                                                            disabled: !(!activeAllOption.currentlyPayChildSupport && !activeAllOption.haveAnyGovtDebt && !activeAllOption.haveCurrentChildSupportDebt)
                                                                                && ('Fee from refund' === option.name || 'Refund Advance' === option.name)
                                                                        }
                                                                    })}
                                                                placeholder='Under 18'
                                                                name='paymentOptionId'
                                                                onChange={(e: any, d: any, setValue: any) => {
                                                                    const { label, value } = d;
                                                                    if (label === 'Cash') {
                                                                        storeAgreement({ ...values, paymentOptionId: value, feeFromRefundCharge: 0, refundAdvanceCharge: 0 });
                                                                    }
                                                                    else if (label === 'Fee from refund') {
                                                                        storeAgreement({ ...values, paymentOptionId: value, feeFromRefundCharge: 49, refundAdvanceCharge: 0 });
                                                                    }
                                                                    else if (label === 'Electronic Fund Transfer') {
                                                                        storeAgreement({ ...values, paymentOptionId: value, feeFromRefundCharge: 0, refundAdvanceCharge: 0 });

                                                                    }
                                                                    else if (label === 'Refund Advance') {
                                                                        if (!getAllTrueRefundAdvanceQuestion())
                                                                            setRefundAdvanceQuestionModalOpen(true);
                                                                        else {
                                                                            storeAgreement({ ...values, paymentOptionId: value, feeFromRefundCharge: 49, refundAdvanceCharge: 50 });
                                                                        }
                                                                    }
                                                                }}
                                                            />} />
                                                    </Grid>
                                                    <FooterButtons onClickBack={() => handleBack(values)} onClickCancel={() => cancel(id)} onClickSave={() => handleSave(values)} onClickNext={handleSubmit} />
                                                </Form>
                                                <RefundAdvanceModal onClickOk={(questionValues: any) => {
                                                    setRefundAdvanceQuestion(questionValues);
                                                    setRefundAdvanceQuestionModalOpen(false);
                                                    if (getAllTrueRefundAdvanceQuestion()) {
                                                        storeAgreement({ ...values, paymentOptionId: '632c62fa-8e6f-4b1a-b36d-3ca86d1b90b0', feeFromRefundCharge: 49, refundAdvanceCharge: 50 });
                                                    }
                                                }} />
                                            </>
                                        )
                                    }

                                </Formik>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <NoteList />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default observer(InterviewForm3);