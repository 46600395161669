import { useField } from 'formik';
import { type } from 'os';
import * as React from 'react';
import { useEffect } from 'react';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';

interface Props {
    placeholder?: string;
    name: string;
    label?: string;
    type?: string;
    disabled?: boolean;
    icon?: SemanticICONS;
    maxLength?: number;
    minLength?: number;
    hidden?: boolean;
    onKeyUp?: React.KeyboardEventHandler;
    toFixed?: number
}

export default function TextInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);

    const round = (value: number, decimals: number) => {
        return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
    }

    useEffect(() => {
        if (field.value
            && typeof field.value === "number"
            && props.toFixed
            && props.type === "number") {
            helpers.setValue(round(field.value, props.toFixed))
        }
    }, [field.value])

    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ display: props.hidden ? 'none' : 'block', textAlign: 'left' }}>
            <label>{props.label}</label>
            <div style={{ display: 'flex' }}>
                {props.icon ? <Icon style={{ marginTop: '10px' }} name={props.icon} /> : <></>}
                <input
                    onKeyUp={props.onKeyUp}
                    onKeyDown={(e) => {
                        if (props.type === 'number' && e.keyCode === 69) {
                            e.preventDefault();
                        }

                        return true;
                    }}
                    style={{ border: '1.5px solid #b9c9d9' }}
                    {...field} {...props} />
            </div>
            {meta.touched && meta.error ? (<p style={{ marginLeft: props.icon ? 25 : 5, marginTop: 5, color: 'red' }}>{meta.error}</p>) : null}
        </Form.Field>
    );
}