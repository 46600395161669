import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { Filters } from "../models/filter";
import { Role, RoleForm } from "../models/role";


export default class RoleStore {
    role: Role | undefined = undefined;
    roles: Role[] = [];
    isLoading = false;
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    total: number = 0;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    loadRole = async (id: string) => {
        this.loadingInitial = true;

        try {
            const role = await agent.Roles.get(id);
            this.setLoadingInitial(false);
            this.role = role;
            return role
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadRoles = async () => {
        this.isLoading = true;

        try {
            let roles = await agent.Roles.list();

            runInAction(() => {
                this.roles = roles;
                this.isLoading = false;
            });

            return roles;
        } catch (e: any) {
            this.isLoading = false;
        }
    }

    filterRoles = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, roles } = await agent.Roles.filter({
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.roles = roles;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    createRole = async (role: RoleForm) => {
        this.isLoading = true;

        try {
            await agent.Roles.create(role);
            runInAction(() => {
                this.isLoading = false;
            });
            toast.success("Role is successfully added.");
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    updateRole = async (role: RoleForm) => {
        this.isLoading = true;

        try {
            await agent.Roles.edit(role);
            runInAction(() => {
                this.isLoading = false;
            });
            toast.success("Role is successfully updated.");
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    deleteRole = async (roleId: string) => {
        try {
            await agent.Roles.delete(roleId);

            runInAction(() => {
                this.roles = this.roles.filter(u => u.id !== roleId);
            });
            toast.success("Role is successfully deleted.");

        } catch (error) {
            console.log(error);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterRoles();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterRoles();
    }
}