import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "semantic-ui-react";
import TextArea from "../../../app/common/form/TextArea";
import TextInput from "../../../app/common/form/TextInput";
import { Note } from "../../../app/models/note";
import { useStore } from "../../../app/stores/store";
import DateHepler from "../../../app/helpers/dateHelper";

interface Props {
    open?: boolean;
    onClose?: any
}

const NoteFormModal = ({ open, onClose }: Props) => {
    const { noteStore, agreementStore: { agreement } } = useStore();
    const { note, create, edit, isLoading, filterNotes, setLastAgreementIdLoaded } = noteStore;
    const dataHepler = new DateHepler();

    return (
        <Modal
            size="small"
            open={open}
            onClose={() => console.log("on modal close")}
        >
            <Modal.Header style={{ color: '#0099da' }}>
                Note {note.id && <span style={{ color: ' #6f8cab', float: 'right', fontSize: 14, fontWeight: '600' }}>Date Created: {dataHepler.formatShortDate(note.modifiedOn!)}</span>}
            </Modal.Header>
            <Modal.Content style={{ paddingTop: 0 }}>
                <Formik
                    enableReinitialize
                    initialValues={note}
                    onSubmit={(values: Note) => {
                        if (agreement) {
                            setLastAgreementIdLoaded("");
                            if (values.id) {
                                edit({ ...values, agreementId: agreement.id! })
                                    .then(() => {
                                        onClose();
                                        filterNotes();
                                    });
                            }
                            else {
                                create({ ...values, agreementId: agreement.id! }).then(() => {
                                    onClose();
                                    filterNotes();

                                });
                            }
                        }
                    }}
                >
                    {
                        ({ handleSubmit }) => (
                            <Form id='refundAdvanceForm' className='ui form in-modal' onSubmit={handleSubmit} autoComplete='off' style={{ marginTop: 0 }}>
                                <label style={{
                                    display: 'block',
                                    margin: '15px 0 0.28571429rem 0',
                                    color: '#6f8cab',
                                    fontSize: '.92857143em',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                }}>Subject</label>
                                <TextInput name='subject' placeholder='' type='text' />
                                <label style={{
                                    display: 'block',
                                    margin: '15px 0 0.28571429rem 0',
                                    color: '#6f8cab',
                                    fontSize: '.92857143em',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                }}>Message</label>
                                <TextArea rows={10} name='message' placeholder='' />
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions style={{ height: 60 }}>
                <Button
                    style={{ marginBottom: 13 }}
                    floated='left'
                    form='refundAdvanceForm'
                    content='Close'
                    basic
                    size='medium'
                    onClick={onClose}
                />
                <Button
                    loading={isLoading}
                    style={{ marginBottom: 13 }}
                    floated='left'
                    form='refundAdvanceForm'
                    content={note.id ? 'Update' : 'Save'}
                    color='blue'
                    type='submit'
                    size='medium'
                />
            </Modal.Actions>
        </Modal>
    )
}

export default observer(NoteFormModal);
