import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { history } from "../..";
import agent from '../api/agents';
import { DirectDebitForm, Invoice, TermsAndConditions, UnsignedAgreement } from '../constants/documentType';
import { Agreement, AgreementTable, BankInformation } from '../models/Agreement';
import { Document } from '../models/document';
import { Filters } from '../models/filter';
import { store } from './store';

const swal = withReactContent(Swal);

export default class AgreementStore {
    loadingInitial = false;
    agreement: Agreement | null = null;
    originalPayment?= '';
    bankInformation: BankInformation = {
        accountName: '',
        bankAccount: '',
        bsb: '',
        id: ''
    }
    agreements: AgreementTable[] = []
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "", startDate: undefined, endDate: undefined, statusId: undefined };
    total: number = 0;
    isLoading = false;
    isUploading = false;
    refundAdvanceQuestion = {
        eligibility: false,
        confirm: false,
        approved: false,
        current: false,
        portal: false,
        portalICA: false,
        evaluate: false
    }
    refundAdvanceQuestionModalOpen = false;
    printInvoiceLoading = false;
    downloadAgreementLoading = false;
    viewOnly = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    create = async (clientId: string, returnType: string) => {
        this.isLoading = true;

        try {
            let id = await agent.Aggrements.create(clientId, returnType);
            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    setViewOnly = (state: boolean) => this.viewOnly = state;

    getAllTrueRefundAdvanceQuestion = () => {
        return this.refundAdvanceQuestion.eligibility
            && this.refundAdvanceQuestion.confirm
            && this.refundAdvanceQuestion.approved
            && this.refundAdvanceQuestion.current
            && this.refundAdvanceQuestion.portal
            && this.refundAdvanceQuestion.portalICA
            && this.refundAdvanceQuestion.evaluate;
    }

    setRefundAdvanceQuestionModalOpen = (state: boolean) => {
        this.refundAdvanceQuestionModalOpen = state;
    }

    setRefundAdvanceQuestion = (refundAdvanceQuestion: any) => {
        this.refundAdvanceQuestion = refundAdvanceQuestion;
    }

    filterAgreements = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.agreement = null;
        this.setLoading(true);
        try {
            const { total, agreements } = await agent.Aggrements.filter(this.filterValues);

            runInAction(() => {
                this.agreements = agreements.map(a => {
                    return {
                        ...a,
                        client: {
                            ...a.client,
                            fullName: a.client.fullName.replace(/\s+/g, ' ').trim()
                        }
                    }
                });
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setAgreement = (agreement: Agreement) => {
        let quote = agreement.interview.interviewFirstPage.soleTraderReturnsGST
            + agreement.interview.interviewFirstPage.soleTraderReturnsNonGST
            + agreement.interview.interviewFirstPage.basPrepareAndLodgementPerQuarter
            + agreement.interview.interviewFirstPage.companyTrustReturnsRateCard
            + agreement.interview.interviewSecondPage.taxReturnBaseFee * agreement.interview.interviewSecondPage.numberOfAnnualTaxReturn
            + (agreement.interview.interviewSecondPage.investmentRentalProperty * agreement.interview.interviewSecondPage.investmentRentalPropertyQuantity)
            + (agreement.interview.interviewSecondPage.cgtSoldSharesSinceLastTaxReturn * agreement.interview.interviewSecondPage.cgtSoldSharesSinceLastTaxReturnQuantity)
            + (agreement.interview.interviewSecondPage.cgtSoldPropertySinceLastTaxReturn * agreement.interview.interviewSecondPage.cgtSoldPropertySinceLastTaxReturnQuantity)
            + agreement.interview.interviewSecondPage.foreignIncome
            + (agreement.interview.interviewSecondPage.pre2014Returns * agreement.interview.interviewSecondPage.pre2014ReturnsQuantity)
            + agreement.interview.interviewSecondPage.travelAllowances
            + agreement.interview.interviewSecondPage.selfEducation
            + agreement.interview.interviewSecondPage.otme
            + agreement.interview.interviewSecondPage.logbookSetup
            + agreement.interview.interviewSecondPage.otherTaxServices
            + agreement.interview.interviewSecondPage.overPayg
            + agreement.interview.interviewThirdPage.feeFromRefundCharge
            + agreement.interview.interviewThirdPage.refundAdvanceCharge;

        let discountedFees = quote - agreement.interview.interviewThirdPage.discount;

        let selectedPayment = store.lookupStore.paymentOptions.find(o => o.id === agreement.interview.interviewThirdPage.paymentOptionId);

        agreement.interview.interviewFourthPage.estimatedGovernmentDebt = agreement.interview.interviewThirdPage.estimateTotalGovtDebt;

        let estimatedNetRefund = agreement.interview.interviewFourthPage.estimatedGrossTaxRefund - agreement.interview.interviewFourthPage.estimatedGovernmentDebt;

        if ('Fee from refund' === selectedPayment?.name || 'Refund Advance' === selectedPayment?.name) {
            estimatedNetRefund = estimatedNetRefund
                - discountedFees
                - agreement.interview.interviewFourthPage.refundAdvancePayment;
        }

        this.agreement = {
            ...agreement,
            interview: {
                ...agreement.interview,
                interviewThirdPage: {
                    ...agreement.interview.interviewThirdPage,
                    quote: quote
                },
                interviewFourthPage: {
                    ...agreement.interview.interviewFourthPage,
                    accountsDirectFees: quote,
                    discountedFees: discountedFees,
                    estimatedNetRefund: estimatedNetRefund,
                    reviewCost: (this.getReviewCost(agreement) * agreement.interview.interviewSecondPage.numberOfAnnualTaxReturn),
                    reviewerFee: (this.getReviewerFee(agreement) * agreement.interview.interviewSecondPage.numberOfAnnualTaxReturn)
                }
            }
        };
    };


    save = async (id: string) => {
        this.isLoading = true;

        try {
            if (this.agreement != null) {
                let firstHeard = '';

                if (this.agreement.interview.interviewSecondPage.firstHearAboutUs && this.agreement.interview.interviewSecondPage.otherFirstHearAboutUs) {
                    firstHeard = this.agreement.interview.interviewSecondPage.firstHearAboutUs + ',' + this.agreement.interview.interviewSecondPage.otherFirstHearAboutUs;
                }
                else if (this.agreement.interview.interviewSecondPage.firstHearAboutUs && !this.agreement.interview.interviewSecondPage.otherFirstHearAboutUs) {
                    firstHeard = this.agreement.interview.interviewSecondPage.firstHearAboutUs;
                }

                await agent.Aggrements.edit(id,
                    {
                        ...this.agreement,
                        interview: {
                            ...this.agreement.interview,
                            interviewSecondPage: {
                                ...this.agreement.interview.interviewSecondPage,
                                firstHearAboutUs: firstHeard
                            }
                        }
                    }
                );
                this.isLoading = false;
                toast.success('Agreement Saved.');
            }
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }

    }

    cancel = async (id: string) => {
        swal.fire({
            title: `Are you sure?`,
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;

                agent.Aggrements.cancel(id).then(() => {
                    swal.fire({
                        title: `Deleted!`,
                        text: "Interview Canceled.",
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/agreements');
                            this.isLoading = false;
                        }
                    });
                });
            }
        });
    }

    delete = async (id: string) => {
        swal.fire({
            title: `Are you sure you want to delete this agreement?`,
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;

                agent.Aggrements.delete(id).then(() => {
                    swal.fire({
                        title: `Deleted!`,
                        text: "Interview Canceled.",
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/agreements');
                            this.isLoading = false;
                        }
                    });
                });
            }
        });
    }

    get isRefundAdvance() {
        let paymentSelected = store.lookupStore.getPlaymentOptions
            .find(b => b.id === this.agreement?.interview.interviewThirdPage.paymentOptionId);

        return paymentSelected?.name === 'Refund Advance';
    }

    paperSign = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.Aggrements.paperSign(id).then(() => {
                toast.success('Paper signed successfully.');
                history.push('/agreements');
                this.isLoading = false;
            });
        } catch (e) {

        }
    }

    eSign = async (id: string) => {
        let paymentSelected = store.lookupStore.getPlaymentOptions
            .find(b => b.id === this.agreement?.interview.interviewThirdPage.paymentOptionId);
        swal.fire({
            title: `Are you sure?`,
            html: `<strong>Your Payment Option is ${paymentSelected?.name}</strong>
                   <br>
                   <br>
                   You won't be able to cancel this!<br><br>Inform client that contracts are being sent to their mobile phone and email, and if they are a new phone client they must verify their ID before we can lodge (email sent).<br>Can be eSigned either way.<br>Also please add
                   <br>
                   <a href="mailto: support@accountantsdirect.com.au">support@accountantsdirect.com.au</a>
                   <br>to contacts to avoid emails going to spam.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;
                this.isUploading = true;

                agent.Aggrements.forEsign(id, this.agreement!).then(() => {
                    let documents: Document[] = [];

                    let agreementDoc = new Promise((resolve, reject) => {
                        this.convertHTMLToCanvas('agreement-pdf', (base64: string) => {
                            documents.push({
                                fileName: `agreement-${this.agreement?.client.fullName}`,
                                documentBase64: base64,
                                documentTypeName: UnsignedAgreement,
                                order: 1
                            });

                            resolve(base64);
                        }, true, true, `agreement-${this.agreement?.client.fullName}`);
                    });

                    let directDebitDoc = new Promise((resolve, reject) => {
                        if (paymentSelected?.name === 'Refund Advance' || paymentSelected?.name === 'Fee from refund') {
                            this.convertHTMLToCanvas('direct-debit-pdf', (base64: string) => {
                                documents.push({
                                    fileName: `direct-debit-${this.agreement?.client.fullName}`,
                                    documentBase64: base64,
                                    documentTypeName: DirectDebitForm,
                                    order: 2
                                });

                                resolve(base64);
                            }, false, true, `direct-debit-${this.agreement?.client.fullName}`, true)
                        }
                        else resolve('');
                    });

                    let termAndConditions = new Promise((resolve, reject) => {
                        if (paymentSelected?.name === 'Refund Advance' || paymentSelected?.name === 'Fee from refund') {
                            this.convertHTMLToCanvas('terms-and-conditions-pdf', (base64: string) => {
                                documents.push({
                                    fileName: `online-account-payment-terms-and-condition-${this.agreement?.client.fullName}`,
                                    documentBase64: base64,
                                    documentTypeName: TermsAndConditions,
                                    order: 3
                                });

                                resolve(base64);
                            }, false, true, `online-account-payment-terms-and-condition-${this.agreement?.client.fullName}`, false)
                        }
                        else resolve('');
                    });

                    let invoiceDoc = new Promise((resolve, reject) => {
                        this.convertHTMLToCanvas('invoice-pdf', (base64: string) => {
                            documents.push({
                                fileName: `invoice-${this.agreement?.client.fullName}`,
                                documentBase64: base64,
                                documentTypeName: Invoice,
                                order: 4
                            });

                            resolve(base64)
                        }, false, true, `invoice-${this.agreement?.client.fullName}`);
                    });


                    let documentsPromise = new Promise((resolve, reject) => {
                        Promise.all([agreementDoc, directDebitDoc, invoiceDoc, termAndConditions]).then((values) => {
                            agent.Documents.upload({
                                agreementId: this.agreement?.id,
                                documents: documents.sort(d => d.order!)
                            }).then(() => {
                                resolve('');
                            });
                        }).catch((e) => {
                            this.isLoading = false;
                            this.isUploading = false;

                            return Promise.reject('Form is not valid! Please check the fields.');
                        });

                    });

                    Promise.all([documentsPromise]).then((values) => {
                        this.isLoading = false;
                        this.isUploading = false;
                        swal.fire({
                            title: `Success`,
                            text: "Interview Success.",
                            icon: 'success',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/agreements');
                            }
                        });
                    }).catch((e) => {
                        this.isLoading = false;
                        this.isUploading = false;

                        return Promise.reject('Form is not valid! Please check the fields.');
                    });

                }).catch(() => {
                    this.isLoading = false;
                    this.isUploading = false;

                    swal.fire({
                        title: `Interview`,
                        text: "Form is not valid! Please check the fields.",
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    });
                });
            }
        });
    }

    noShow = async (id: string) => {
        swal.fire({
            title: `Did the client not respond?`,
            text: "This will be marked as Status of 'NoShow'",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                agent.Aggrements.noShow(id).then(() => {
                    swal.fire({
                        title: `NoShow!`,
                        text: "Client was no show.",
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/agreements');
                        }
                    });
                });
            }
        });
    }

    get = async (id: string) => {
        this.setLoadingInitial(true);

        if (store.lookupStore.paymentOptions.length === 0)
            await store.lookupStore.loadPaymentOptions();

        if (this.agreement?.id === id) {
            this.setLoadingInitial(false);
            return this.agreement;
        }
        else {
            let agreement = await agent.Aggrements.get(id);

            this.originalPayment = store.lookupStore.paymentOptions.find(o => o.id === agreement.interview.interviewThirdPage.paymentOptionId)?.name;

            agreement = {
                ...agreement,
                interview: {
                    ...agreement.interview,
                    interviewSecondPage: {
                        ...agreement.interview.interviewSecondPage,
                        occupationTypeOfBusiness: agreement.interview.interviewSecondPage.occupationTypeOfBusiness ? agreement.interview.interviewSecondPage.occupationTypeOfBusiness : "",
                        firstHearAboutUs: agreement.interview.interviewSecondPage.firstHearAboutUs ? agreement.interview.interviewSecondPage.firstHearAboutUs.split(',')[0] : '',
                        otherFirstHearAboutUs: agreement.interview.interviewSecondPage.firstHearAboutUs?.split(',')[1] ? agreement.interview.interviewSecondPage.firstHearAboutUs?.split(',')[1] : ''
                    },
                    interviewThirdPage: {
                        ...agreement.interview.interviewThirdPage,
                        partnerDateOfBirth: agreement?.interview.interviewThirdPage.partnerDateOfBirth?.split('T')[0]
                    },
                }
            }

            runInAction(() => {
                if (agreement.interview.interviewThirdPage.paymentOptionId === '632c62fa-8e6f-4b1a-b36d-3ca86d1b90b0') {
                    this.refundAdvanceQuestion = {
                        eligibility: true,
                        confirm: true,
                        approved: true,
                        current: true,
                        portal: true,
                        portalICA: true,
                        evaluate: true
                    }
                }
                else {
                    this.refundAdvanceQuestion = {
                        eligibility: false,
                        confirm: false,
                        approved: false,
                        current: false,
                        portal: false,
                        portalICA: false,
                        evaluate: false
                    }
                }

                this.agreement = agreement;
                this.bankInformation = agreement.bankInformation;
                this.setLoadingInitial(false);
            });

            store.noteStore.setFilterValues({
                pageNumber: 1,
                searchKeyword: '',
                pageSize: 5,
                agreementId: id
            });

            store.noteStore.filterNotes();

            return agreement;
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterAgreements();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterAgreements();
    }

    setFilterByStatusId = (id: string | undefined) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, statusId: id };
        this.filterAgreements();
    }

    setFilterValues = (query: Filters) => {
        this.filterValues = query;
        this.filterAgreements();
    };

    downloadAgreement = async () => {
        this.isLoading = true;
        let blob = await agent.Documents.downloadAgreement(this.agreement?.id!);
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.agreement?.client.fullName + '-agreement.pdf';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.isLoading = false;
        }, 0)
    }

    downloadInvoice = async () => {
        this.isLoading = true;
        let blob = await agent.Documents.downloadInvoice(this.agreement?.id!);
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.agreement?.client.fullName + '-invoice.pdf';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.isLoading = false;
        }, 0)
    }

    downloadDirectDebit = async () => {
        this.isLoading = true;
        let blob = await agent.Documents.downloadDirectDebit(this.agreement?.id!);
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.agreement?.client.fullName + '-direct-debit.pdf';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.isLoading = false;
        }, 0)
    }

    downloadTermsAndConditions = async () => {
        this.isLoading = true;
        let blob = await agent.Documents.downloadTermsAndConditions(this.agreement?.id!);
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.agreement?.client.fullName + '-online-account-payment-terms-and-condition-.pdf';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.isLoading = false;
        }, 0)
    }

    printInvoice = () => {
        this.printInvoiceLoading = true;
        this.convertHTMLToCanvas('invoice-pdf', (base64Pdf: string) => {
            let blob = this.b64toBlob(base64Pdf, 'application/pdf');
            let blobUrl = URL.createObjectURL(blob);

            let iframe = document.getElementById('iframe-pdf') as HTMLIFrameElement;
            iframe.src = blobUrl;

            iframe.onload = () => {
                window.frames[0].focus();
                window.frames[0].print();
                this.printInvoiceLoading = false;
            };
        });
    }

    b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = new Array<Uint8Array>();

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize),
                byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    reset = () => {
        this.filterValues = {
            pageNumber: 1,
            pageSize: 10,
            searchKeyword: "",
            startDate: undefined,
            endDate: undefined,
            statusId: undefined
        };

    }

    private getReviewCost = (agreement: Agreement) => {
        let cost = 0;

        if (!agreement.interview.interviewFourthPage.conductReview)
            return cost;

        let selectedBusinessStructure = store.lookupStore.businessStructures.find(o => o.id === agreement.interview.interviewFirstPage.businessStructureId);
        let isIndividual = false;
        switch (selectedBusinessStructure?.name) {
            case 'Super Basic Individual':
                cost += 9.90;
                isIndividual = true;
                break;
            case 'Individual':
                cost += 13.20;
                isIndividual = true;
                break;
            case 'Sole Trader':
                cost += 19.90;
                isIndividual = true;
                break;
            case 'Company':
                cost += 15.70;
                break;
            case 'Trust':
                cost += 15.70;
                break;
            case 'Partnership':
                cost += 9.90;
                break;
            case 'BAS':
                cost += 9.90;
                break;
            default:
        }

        if (isIndividual) {
            let secondPage = agreement.interview.interviewSecondPage;

            if (secondPage.investmentRentalProperty && secondPage.investmentRentalPropertyQuantity)
                cost += (6.70 * secondPage.investmentRentalPropertyQuantity);

            if (secondPage.cgtSoldPropertySinceLastTaxReturn && secondPage.cgtSoldPropertySinceLastTaxReturnQuantity)
                cost += (6.70 * secondPage.cgtSoldPropertySinceLastTaxReturnQuantity);

            if (secondPage.cgtSoldSharesSinceLastTaxReturn && secondPage.cgtSoldSharesSinceLastTaxReturnQuantity)
                cost += (5.20 * secondPage.cgtSoldSharesSinceLastTaxReturnQuantity);
        }

        return cost;
    };

    private getReviewerFee = (agreement: Agreement) => {
        let cost = 0;

        if (!agreement.interview.interviewFourthPage.conductReview)
            return cost;

        let selectedBusinessStructure = store.lookupStore.businessStructures.find(o => o.id === agreement.interview.interviewFirstPage.businessStructureId);
        let isIndividual = false;
        switch (selectedBusinessStructure?.name) {
            case 'Super Basic Individual':
                cost += 7.70;
                isIndividual = true;
                break;
            case 'Individual':
                cost += 9.90;
                isIndividual = true;
                break;
            case 'Sole Trader':
                cost += 15.40;
                isIndividual = true;
                break;
            case 'Company':
                cost += 12.10;
                break;
            case 'Trust':
                cost += 12.10;
                break;
            case 'Partnership':
                cost += 7.70;
                break;
            case 'BAS':
                cost += 7.70;
                break;
            default:
        }

        if (isIndividual) {
            let secondPage = agreement.interview.interviewSecondPage;

            if (secondPage.investmentRentalProperty && secondPage.investmentRentalPropertyQuantity)
                cost += (5.50 * secondPage.investmentRentalPropertyQuantity);

            if (secondPage.cgtSoldPropertySinceLastTaxReturn && secondPage.cgtSoldPropertySinceLastTaxReturnQuantity)
                cost += (5.50 * secondPage.cgtSoldPropertySinceLastTaxReturnQuantity);

            if (secondPage.cgtSoldSharesSinceLastTaxReturn && secondPage.cgtSoldSharesSinceLastTaxReturnQuantity)
                cost += (4.40 * secondPage.cgtSoldSharesSinceLastTaxReturnQuantity);
        }

        return cost;
    };

    private convertHTMLToCanvas = (id: string, callback: any, hasSignature?: boolean, IsSave?: boolean, fileName?: string, directDebit: boolean = false) => {
        const input = document.getElementById(id);

        html2canvas(input!, {
            scale: 2
        })
            .then((canvas) => {
                let imgWidth = 210,
                    pageHeight = 297,
                    imgHeight = (canvas.height * imgWidth / canvas.width),
                    heightLeft = imgHeight;

                let doc = new jsPDF('p', 'mm', [imgWidth, pageHeight]);
                let position = 0;
                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 1);
                heightLeft -= pageHeight;

                let counter = 0;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage([imgWidth, pageHeight], 'p');
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 1);
                    heightLeft -= pageHeight;

                    if (hasSignature && counter === 6) {
                        doc.setTextColor('#fff');
                        doc.setFontSize(13);
                        doc.text('{{signature}}', 110, 246.5, {})
                    }

                    if (directDebit && counter === 2) {
                        doc.setTextColor('#fff');
                        doc.setFontSize(13);
                        doc.text('{{signature}}', 16, 176, {})
                    }

                    counter++;
                }

                let base64Pdf = btoa(doc.output());
                callback(base64Pdf);

                if (IsSave) {
                    doc.save(fileName || 'generated');
                }
            });
    }
}
