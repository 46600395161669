import { Image, ImageProps } from 'semantic-ui-react';

interface Props extends ImageProps {
    userId: string;
    hasPhoto: boolean;
}

const fileToDataUri = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target!.result)
    };
    reader.readAsDataURL(file);
})

const AvatarImage = ({ userId, hasPhoto, ...rest }: Props) => {
    //const { userStore } = useStore();
    //const [dataUri, setDataUri] = useState('');

    //useEffect(() => {
    //    if (hasPhoto) {
    //        try {
    //            userStore.getProfilePicture(userId).then(v => {
    //                if (v != undefined)
    //                    fileToDataUri(v).then(dataUri => {
    //                        setDataUri(dataUri as string)
    //                    })
    //            });
    //        } catch (e) {
    //            setDataUri('/assets/default-profile-picture.svg');
    //        }
    //    } else {
    //        setDataUri('/assets/default-profile-picture.svg');
    //    }
    //}, [userId, hasPhoto, userStore])

    return (
        <Image
            {...rest}
            src={'/assets/default-profile-picture.svg'}
            avatar />
    );
}

export default AvatarImage;