import { useState } from "react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../app/stores/store";
import LoadingComponet from "../../app/layout/LoadingComponent";
import { Button, Card, Grid, Segment } from "semantic-ui-react";
import { Form, Formik } from "formik";
import StyledHeader from "../../app/styled-element/StyledHeader";
import TextInput from "../../app/common/form/TextInput";
import * as Yup from 'yup';
import FormSelect from "../../app/common/form/FormSelect";
import { toast } from "react-toastify";
import MobileNumberInput from "../../app/common/form/MobileNumberInput";

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const EditUserForm = () => {
    const { userStore, roleStore, commonStore, locationStore, authStore } = useStore();
    const { loadLocations, isLoading: isLoadingLocation, locations } = locationStore;
    const { loadRoles, isLoading: rolesLoading, roles } = roleStore;
    const { loadingInitial, edit, isLoading, getById } = userStore;
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const [isAdmin, setIsAdmin] = useState(false);

    const [user, setUser] = useState({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        mobileNumber: "",
        roleId: "",
        locationId: "",
    });

    useEffect(() => {
        loadLocations().then((locations) => {
            loadRoles().then((roles) => {
                getById(id).then(user => {
                    let admin = roles!.find(r => r.name === 'Admin')
                    admin?.id === user.role.id ? setIsAdmin(true) : setIsAdmin(false);

                    setUser({
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        locationId: user.location.id,
                        mobileNumber: user.mobileNumber,
                        roleId: user.role.id,
                        username: user.username,
                    });
                });
            });
        });
    }, [loadLocations, loadRoles]);

    const onHandleSubmit = (values: any) => {
        edit(values).then(() => {
            toast.success("User is successfully updated.");
            history.push(`/users`);
        });
    }

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <StyledContainer isMobile={commonStore.isMobile}>
            <Card style={{ width: '100%' }}>
                <Card.Content className='cardDivided'>
                    <Card.Header style={{ color: '#0099da' }} content='Edit User' />
                </Card.Content>
                <Card.Content>
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={onHandleSubmit}
                        validationSchema={Yup.object({
                            firstName: Yup.string().required('First Name is required.'),
                            lastName: Yup.string().required('Last Name field is required.'),
                            email: Yup.string().required('Email field is required.').email('The Email is not a valid e-mail address.'),
                            username: Yup.string().required('Username field is required.'),
                            locationId: isAdmin ? Yup.string().nullable() : Yup.string().required('Location field is required.'),
                            roleId: Yup.string().required('Role field is required.'),
                            mobileNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                        })}
                    >
                        {
                            ({ handleSubmit, setFieldValue }) => (
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <Grid stackable>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='First Name' name='firstName' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Last Name' name='lastName' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Email' name='email'/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <MobileNumberInput placeholder='Mobile No.' name='mobileNumber' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <FormSelect
                                                    options={roles.map(role => {
                                                        return {
                                                            key: role.id,
                                                            value: role.id,
                                                            text: role.name,
                                                            disabled: (role.name === 'Admin' && authStore.auth?.role !== 'Admin')
                                                        }
                                                    })}
                                                    loading={rolesLoading}
                                                    placeholder='Role'
                                                    name='roleId'
                                                    onChange={(e: any, d: any) => {
                                                        const { value, options } = d;
                                                        const admin = options.find((o: any) => o.text === 'Admin');
                                                        if (admin.value === value) {
                                                            setIsAdmin(true);
                                                            setFieldValue('locationId', '');
                                                        }
                                                        else
                                                            setIsAdmin(false);
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <FormSelect
                                                    disabled={isAdmin}
                                                    options={locations.map(location => {
                                                        return {
                                                            key: location.id,
                                                            value: location.id,
                                                            text: location.name
                                                        }
                                                    })}
                                                    loading={isLoadingLocation}
                                                    placeholder='Location'
                                                    name='locationId'
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Button style={{ marginTop: 15, borderRadius: 30 }} loading={isLoading} disabled={isLoading} floated='right' content='Update User' primary type='submit' size='medium' />
                                    <Button style={{ marginTop: 15, borderRadius: 30 }} floated='right' content='Cancel' onClick={() => history.push('/users')} size='medium' />
                                </Form>
                            )
                        }
                    </Formik>
                </Card.Content>
            </Card>
        </StyledContainer>
    );
};

export default observer(EditUserForm);
