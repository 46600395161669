import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    ChangePasswordForm,
    RefreshToken, ResetPasswordFormValues, SetUserStatusValues, User,
    UserForm,
    UserFormValues
} from '../models/user';
import { Filters } from '../models/filter';
import axiosService from './axios-service';
import { AgreementStatus, BusinessStructure, Module, PaymentOption, Title } from '../models/lookup';
import { Role, RoleForm } from '../models/role';
import { Client, ClientContract, ClientForm, EditConsultantForm } from '../models/client';
import { Agreement, AgreementTable } from '../models/Agreement';
import { Document, UploadDocuments } from '../models/document';
import { MasterList } from '../models/masterlist';
import { Note } from '../models/note';
import { Location } from '../models/location';
import { ReviewerList, ReviewerRequest } from '../models/reviewer';

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig) => axiosService.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}) => axiosService.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosService.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axiosService.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string, body?: {}) => axiosService.delete<T>(url, { data: body }).then(responseBody),
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const Account = {
    login: (user: UserFormValues) => requests.post<User>('/authenticate/user', user),
    forgotPassword: (credentials: { email: string }) => requests.post<User>('/authenticate/forgotpassword', credentials),
    resetPassword: (credentials: ResetPasswordFormValues) => requests.post<void>('/authenticate/ResetPassword', credentials),
    current: () => requests.get<User>('/Users/current'),
    getProfilePhoto: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`AdminAccounts/GetProfilePicture`, config),
    refreshToken: (oldToken: RefreshToken) => requests.post<RefreshToken>('/authenticate/RefreshToken', oldToken),
    deleteRefreshToken: (oldToken: RefreshToken) => requests.delete<RefreshToken>('/authenticate/RefreshToken', oldToken)
}

const Aggrements = {
    create: (clientId: string, returnType: string) => requests.post(`/Agreements/Client/${clientId}/ReturnType/${returnType}`, {}),
    edit: (id: string, agreement: Agreement) => requests.put(`/Agreements/${id}`, agreement),
    get: (id: string) => requests.get<Agreement>(`/Agreements/${id}`),
    delete: (id: string) => requests.delete(`/Agreements/${id}`),
    cancel: (id: string) => requests.put(`/Agreements/Cancel/${id}`, {}),
    noShow: (id: string) => requests.put(`/Agreements/NoShow/${id}`, {}),
    forEsign: (id: string, agreement: Agreement) => requests.put(`/Agreements/ForEsign/${id}`, agreement),
    paperSign: (id: string) => requests.put(`/Agreements/PaperSign/${id}`, {}),
    filter: (query: Filters) => requests.get<{ total: number, agreements: AgreementTable[] }>
        (`/Agreements?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}${query.startDate ? `&startDate=${query.startDate.toDateString()}` : ''}${query.endDate ? `&endDate=${query.endDate.toDateString()}` : ''}${query.statusId ? `&agreementStatusId=${query.statusId}` : ''}`),
}

const Clients = {
    get: (id: string) => requests.get<Client>(`/Clients/${id}`),
    create: (client: ClientForm) => requests.post<{ agreementId: string, clientId: string }>('/Clients/', client),
    edit: (client: ClientForm) => requests.put(`/Clients/${client.id}`, client),
    editConsultants: (client: EditConsultantForm) => requests.put(`/Clients/Consultant`, client),
    filter: (query: Filters) => requests.get<{ total: number, clients: Client[] }>
        (`/Clients?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    filterContracts: (id: string, query: Filters) => requests.get<{ total: number, agreements: ClientContract[] }>
        (`/Clients/${id}/Agreements?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
}

const Documents = {
    upload: (documents: UploadDocuments) => requests.post<string>('/Documents', documents),
    uploadInvoice: (invoice: Document) => requests.post<string>('/Documents/Invoice', invoice),
    downloadInvoice: (agreementId: string) => requests.get<Blob>(`/Documents/Invoice?agreementId=${agreementId}`, {
        responseType: 'blob'
    }),
    uploadAgreement: (agreement: Document) => requests.post<string>('/Documents/UnsignedAgreement', agreement),
    uploadDirectDebit: (directDebit: Document) => requests.post<string>('/Documents/DirectDebit', directDebit),
    downloadAgreement: (agreementId: string) => requests.get<Blob>(`/Documents/SignedAgreement?agreementId=${agreementId}`, {
        responseType: 'blob'
    }),
    downloadDirectDebit: (agreementId: string) => requests.get<Blob>(`/Documents/DirectDebit?agreementId=${agreementId}`, {
        responseType: 'blob'
    }),
    downloadTermsAndConditions: (agreementId: string) => requests.get<Blob>(`/Documents/TermsAndConditions?agreementId=${agreementId}`, {
        responseType: 'blob'
    }),
}

const MasterLists = {
    filter: (query: Filters) => requests.get<{ total: number, masterLists: MasterList[] }>
        (`/Masterlists?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}${query.startDate ? `&startDate=${query.startDate.toDateString()}` : ''}${query.endDate ? `&endDate=${query.endDate.toDateString()}` : ''}${query.statusId ? `&agreementStatusId=${query.statusId}` : ''}`),
    download: (startDate?: string, endDate?: string) => requests.get<Blob>(`/Masterlists/Download?${startDate ? `startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`, {
        responseType: 'blob'
    })
}

const Notes = {
    get: (id: string) => requests.get<Note>(`/Notes/${id}`),
    create: (note: Note) => requests.post<string>('/Notes/', note),
    edit: (note: Note) => requests.put<string>(`/Notes/${note.id}`, note),
    delete: (id: string) => requests.delete<string>(`/Notes/${id}`),
    filter: (query: Filters) => requests.get<{ total: number, notes: Note[] }>
        (`/Notes?agreementId=${query.agreementId}&pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}${query.startDate ? `&startDate=${query.startDate.toDateString()}` : ''}${query.endDate ? `&endDate=${query.endDate.toDateString()}` : ''}${query.statusId ? `&agreementStatusId=${query.statusId}` : ''}`),
}

const Users = {
    create: (user: UserForm) => requests.post<string>('/Users/', user),
    edit: (user: UserForm) => requests.put(`/Users/${user.id}`, user),
    delete: (userId: string) => requests.delete(`/Users/${userId}`),
    filter: (query: Filters) => requests.get<{ total: number, users: User[] }>
        (`/Users?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    setStatus: (setUserStatusValues: SetUserStatusValues) => requests.patch(`/Users/Status`, setUserStatusValues),
    getById: (id: string) => requests.get<User>(`/Users/${id}`),
    getConsultantsByLocationId: (locationId: string) => requests.get<User[]>(`/Users/Consultants/${locationId}`),
    changePassword: (changePassword: ChangePasswordForm) => requests.put<RefreshToken>(`/Users/Password`, changePassword),
    download: () => requests.get<Blob>(`/Users/Download`, {
        responseType: 'blob'
    }),
}

const Hubs = {
    register: (connectionId: string) => requests.post<string>(`/Hubs/register/${connectionId}`, {}),
    unregister: (connectionId: string) => requests.post<string>(`/Hubs/unregister/${connectionId}`, {}),
}

const Lookups = {
    getModules: () => requests.get<Module[]>('/Lookups/Modules'),
    getTitles: () => requests.get<Title[]>('/Lookups/Titles'),
    getBusinessStructures: () => requests.get<BusinessStructure[]>('/Lookups/BusinessStructures'),
    getPaymentOptions: () => requests.get<PaymentOption[]>('/Lookups/PaymentOptions'),
    getAgreementStatus: () => requests.get<AgreementStatus[]>('/Lookups/AgreementStatuses'),
}

const Roles = {
    get: (id: string) => requests.get<Role>(`/Roles/${id}`),
    create: (role: RoleForm) => requests.post<string>('/Roles/', role),
    edit: (role: RoleForm) => requests.put(`/Roles/${role.id}`, role),
    delete: (roleId: string) => requests.delete(`/Roles/${roleId}`),
    list: () => requests.get<Role[]>('/Roles'),
    filter: (query: Filters) => requests.get<{ total: number, roles: Role[] }>
        (`/Roles/Filter?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
}

const Locations = {
    get: (id: string) => requests.get<Location>(`/Locations/${id}`),
    create: (location: Location) => requests.post<string>('/Locations/', location),
    edit: (location: Location) => requests.put(`/Locations/${location.id}`, location),
    delete: (id: string) => requests.delete(`/Locations/${id}`),
    list: () => requests.get<{ total: number, locations: Location[] }>('/Locations'),
    filter: (query: Filters) => requests.get<{ total: number, locations: Location[] }>
        (`/Locations?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
}

const IdentityVerification = {
    force: (id: string) => requests.post('/IdentityVerifications/ForceVerification', { verificationId: id }),
}

const Reviewers = {
    filter: (query) => requests.get<{ total: number, reviewerList: ReviewerList[] }>
        (`/Reviewers?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}${query.startDate ? `&startDate=${query.startDate}` : ''}${query.endDate ? `&endDate=${query.endDate}` : ''}`),

    get: (agreementId: string) => requests.get<ReviewerList>(`/Reviewers?agreementId=${agreementId}`),
    create: (request: any) => requests.post<string>('/Reviewers', request),
    edit: (request: any) => requests.put(`/Reviewers`, request),
    download: (startDate, endDate) => requests.get<Blob>(`/Reviewers/Download?${startDate ? `startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`, {
        responseType: 'blob'
    })
}

const agent = {
    Aggrements,
    Account,
    Documents,
    Clients,
    Lookups,
    Hubs,
    MasterLists,
    Roles,
    Users,
    Notes,
    Locations,
    IdentityVerification,
    Reviewers
}

export default agent;
