import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const VideoModal = (props: any) => {
    const targetRef = useRef<HTMLDivElement>(null);

    const [dimension, setDimension] = useState({
        height: 200,
        width: 200,
    });

    const onLoad = () => {
        setDimension({
            height: targetRef.current?.clientHeight!,
            width: targetRef.current?.clientWidth!,
        });
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                props.onClose()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const iframeKeyDown = (e) => {
        console.log('e');
        if (e.keyCode === 27) {
            props.onClose()
        }
    }

    if (!props.open) return <></>;

    return ReactDOM.createPortal(
        <>
            <div className='backdrop' onClick={props.onClose} />
            <div ref={targetRef} className='video-modal'>
                <iframe onKeyDown={iframeKeyDown} onLoad={onLoad} src={props.src} width={dimension.width} height={dimension.height}></iframe>
            </div>
        </>,
        document.body
    );
}

export default VideoModal;