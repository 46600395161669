import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { Filters } from '../models/filter';
import { Location } from '../models/location';

export default class LocationStore {
    isLoading = false;
    locations: Location[] = [];
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    total: number = 0;
    loadingInitial = false;
    location: Location = {
        id: '',
        name: ''
    };

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    create = async (location: Location) => {
        this.isLoading = true;


        try {
            let id = await agent.Locations.create(location);

            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    edit = async (location: Location) => {
        this.isLoading = true;

        try {
            let id = await agent.Locations.edit(location);

            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    delete = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.Locations.delete(id);
            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    filterLocations = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, locations } = await agent.Locations.filter(this.filterValues);

            runInAction(() => {
                this.locations = locations.sort((a, b) => a.name.localeCompare(b.name));
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    get = async (id: string) => {
        this.setLoadingInitial(true);
        let note = await agent.Notes.get(id);

        runInAction(() => {
            this.setLoadingInitial(false);
        });

        return note;
    }

    loadLocations = async () => {
        this.setLoading(true);
        try {
            let { total, locations } = await agent.Locations.list();

            runInAction(() => {
                this.locations = locations.sort((a, b) => a.name.localeCompare(b.name));
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
            return locations;
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    selectLocation = (selectedLocation: Location) => this.location = selectedLocation;

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterLocations();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterLocations();
    }

    setFilterValues = (filters: Filters) => this.filterValues = filters;
}