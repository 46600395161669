import { useField } from 'formik';
import * as React from 'react';
import InputMask from 'react-input-mask';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';

interface Props {
    placeholder?: string;
    name: string;
    label?: string;
    type?: string;
    disabled?: boolean;
    icon?: SemanticICONS;
    maxLength?: number;
    hidden?: boolean;
    onKeyUp?: React.KeyboardEventHandler,
    mask: string
}

export default function MaskInput(props: Props) {
    const [field, meta, helper] = useField(props.name);

    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ display: props.hidden ? 'none' : 'block', textAlign: 'left' }}>
            <label>{props.label}</label>
            <div style={{ display: 'flex' }}>
                {props.icon ? <Icon style={{ marginTop: '10px' }} name={props.icon} /> : <></>}
                <InputMask
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    style={{ border: '1.5px solid #b9c9d9' }}
                    mask={props.mask}
                    value={field.value}
                    onChange={(e) => {
                        helper.setValue(e.target.value);
                    }}
                />
            </div>
            {meta.touched && meta.error ? (<p style={{ marginLeft: props.icon ? 25 : 5, marginTop: 5, color: 'red' }}>{meta.error}</p>) : null}
        </Form.Field>
    );
}