import { observer } from "mobx-react-lite";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { Button, Card, Grid, Icon } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { useStore } from "../../app/stores/store";
import FormSelect from "../../app/common/form/FormSelect";
import MobileNumberInput from "../../app/common/form/MobileNumberInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import * as ReturnType from '../../app/constants/returnType';

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;

const InPersonForm = () => {
    const history = useHistory();
    const location = useLocation();
    const { lookupStore, clientStore, userStore, commonStore, locationStore } = useStore();
    const { loadTitles, titles, titlesLoading } = lookupStore;
    const { loadLocations, isLoading: isLoadingLocation, locations } = locationStore;
    const { create, isLoading, get, edit } = clientStore;
    const { getConsultantsByLocationId } = userStore;
    const swal = withReactContent(Swal);
    const { id } = useParams<{ id: string }>();

    const [pageInfo, setPageInfo] = useState(
        {
            title: 'In-Person Registration',
            saveButton: 'Register'
        }
    );
    const [isEdit, setIsEdit] = useState(false);
    const [consultant, setConsultant] = useState({
        consultantsLoading: false,
        consultants: [{
            id: "",
            name: ""
        }]
    })

    const [client, setClient] = useState({
        titleId: "",
        firstName: "",
        lastName: "",
        email: "",
        otherNumber: "",
        mobileNumber: "",
        dateOfBirth: "",
        taxFileNumber: "",
        postalCode: "",
        consultantId: "",
        locationId: "",
        id: "",
        returnType: ""
    });

    useEffect(() => {

        if (location.pathname === '/registrations/overphonevideo') {
            setPageInfo({ title: 'Phone/Video Registration', saveButton: 'Register' });
        }

        loadTitles();

        if (id) {
            setPageInfo({ title: 'Client Info', saveButton: 'Update' });
            setIsEdit(true);
            loadLocations().then(() => {
                get(id).then(client => {
                    handleChangeLocation(client.location?.id!);
                    setClient({
                        id: client.id!,
                        titleId: client.title.id,
                        firstName: client.firstName,
                        lastName: client.lastName,
                        email: client.email,
                        otherNumber: client.otherNumber,
                        mobileNumber: client.mobileNumber,
                        dateOfBirth: client.dateOfBirth.split('T')[0],
                        taxFileNumber: client.taxFileNumber,
                        postalCode: client.postalCode,
                        consultantId: client.consultant?.id!,
                        locationId: client.location?.id!,
                        returnType: client.returnType
                    });
                });
            });
        }
    }, [id]);

    const handleChangeLocation = (locationId: string) => {
        setConsultant({
            consultantsLoading: true,
            consultants: []
        });

        getConsultantsByLocationId(locationId).then(users => {
            setConsultant({
                consultantsLoading: false,
                consultants: users.map(u => {
                    return {
                        id: u.id,
                        name: u.displayName
                    }
                })
            });
        })
    }

    return (
        <StyledContainer isMobile={commonStore.isMobile}>
            <Card style={{ width: '100%' }}>
                <Card.Content className='cardDivided'>
                    <Card.Header content={pageInfo.title} />
                </Card.Content>
                <Card.Content>
                    <Formik
                        enableReinitialize
                        initialValues={client}
                        onSubmit={(values: any, { setErrors }) => {
                            if (isEdit) {
                                edit(values).then(ids => {
                                    swal.fire({
                                        icon: 'success',
                                        title: 'Success',
                                        text: isEdit ? 'Client Updated' : 'Client Registered',
                                        showConfirmButton: true,
                                        allowOutsideClick: false,
                                        allowEnterKey: false,
                                        allowEscapeKey: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            history.push('/clients');
                                        }
                                    });
                                }, error => {
                                    setErrors(error);
                                });
                            }
                            else {

                                if (location.pathname === '/registrations/overphonevideo')
                                    values.returnType = ReturnType.phoneVideo;
                                else
                                    values.returnType = ReturnType.inPerson;

                                create(values).then(ids => {
                                    swal.fire({
                                        icon: 'success',
                                        title: 'Success',
                                        text: isEdit ? 'Client Updated' : 'Client Registered',
                                        showConfirmButton: true,
                                        allowOutsideClick: false,
                                        allowEnterKey: false,
                                        allowEscapeKey: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            history.push('/agreements/interview/bankInformation/' + ids.agreementId);
                                        }
                                    });
                                }, error => {
                                    setErrors(error);
                                });
                            }



                        }}
                        validationSchema={Yup.object({
                            titleId: Yup.string().required('Title field is required.'),
                            firstName: Yup.string().required('First Name is required.'),
                            lastName: Yup.string().required('Last Name field is required.'),
                            mobileNumber: Yup.string().required('Mobile Number field is required.').matches(/^\d+$/, 'Phone number is not valid'),
                            otherNumber: Yup.string().matches(/^\d+$/, 'Number is not valid'),
                            taxFileNumber: Yup.string().required('Tax File Number field is required.').matches(/^\d+$/, 'Tax File Number is not valid'),
                            postalCode: Yup.string().required('Postal Code field is required.').matches(/^\d+$/, 'Postal Code is not valid'),
                            email: Yup.string().required('Email field is required.').email('The Email is not a valid e-mail address.'),
                            dateOfBirth: Yup.string().required('Date of birth field is required.'),
                            consultantId: isEdit ? Yup.string().required('Consultant field is required.') : Yup.string(),
                            locationId: isEdit ? Yup.string().required('Locations field is required.') : Yup.string()
                        })}
                    >
                        {
                            ({ handleSubmit, setFieldValue }) => (
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <Grid stackable>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <FormSelect
                                                    options={titles.map(title => {
                                                        return {
                                                            key: title.id,
                                                            value: title.id,
                                                            text: title.name
                                                        }
                                                    })}
                                                    loading={titlesLoading}
                                                    placeholder='Title'
                                                    name='titleId'
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='First Name' name='firstName' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Last Name' name='lastName' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Email' name='email' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <MobileNumberInput placeholder='Mobile No.' name='mobileNumber' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='Other No.' name='otherNumber' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput type='date' placeholder='Date of birth' name='dateOfBirth' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput maxLength={9} placeholder='Tax File Number' name='taxFileNumber' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput maxLength={4} placeholder='Postal Code' name='postalCode' />
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {isEdit ? <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <FormSelect
                                                    options={locations.map(l => {
                                                        return {
                                                            key: l.id,
                                                            value: l.id,
                                                            text: l.name
                                                        }
                                                    })}
                                                    loading={isLoadingLocation}
                                                    placeholder='Location'
                                                    name='locationId'
                                                    onChange={(e: any, d: any) => {
                                                        const { value } = d;
                                                        handleChangeLocation(value);
                                                        setFieldValue('consultantId', '');
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <FormSelect
                                                    options={consultant.consultants.map(title => {
                                                        return {
                                                            key: title.id,
                                                            value: title.id,
                                                            text: title.name
                                                        }
                                                    })}
                                                    loading={consultant.consultantsLoading}
                                                    placeholder='Consultant'
                                                    name='consultantId'
                                                />
                                            </Grid.Column>
                                        </Grid.Row> : <></>}

                                        <Grid.Row>
                                            <Grid.Column>
                                                <div style={{ textAlign: 'left', float: 'left', width: 150, borderRadius: 30, height: 42, fontSize: 18, padding: '0.6em 1.5em 0 0' }}>
                                                    <Icon color='blue' className='angle left icon' />
                                                    <a onClick={() => history.goBack()}>Back</a >
                                                </div>
                                                <Button style={{ marginTop: 5, borderRadius: 30, float: 'right' }} loading={isLoading} disabled={isLoading} content={pageInfo.saveButton} primary type='submit' size='medium' />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Card.Content>
            </Card>
        </StyledContainer>
    )
};

export default observer(InPersonForm);
