import { useField } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Form } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    disabled?: boolean;
}

export default function MobileNumberInput(props: Props) {
    const [field, meta, helpers] = useField<string>(props.name);
    const [number, setNumber] = useState("");

    useEffect(() => {
        let newValue = field.value.replace('+', '');
        setNumber(newValue);
        helpers.setValue(newValue);
    }, [field.value])

    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label>{props.label}</label>
            <PhoneInput
                inputStyle={{ paddingLeft: 48, height: 39 }}
                country={'au'}
                masks={{
                    au: '...-...-...'
                }}
                onBlur={(e, data: CountryData) => {
                    console.log(data);

                    if (number[data.dialCode.length] === "0") {
                        let newPhone = number.substr(0, data.dialCode.length) + number.substr(data.dialCode.length + 1, number.length);
                        helpers.setValue(newPhone);
                    }
                }}
                value={number}
                onChange={(phone, data: CountryData) => {
                    helpers.setValue(phone);
                }}

            />
            {meta.touched && meta.error ? (
                <p style={{ marginLeft: 5, marginTop: 5, color: 'red' }}>{meta.error}</p>
            ) : null}
        </Form.Field>
    )
}