import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useHistory } from 'react-router-dom';
import { Container, Dropdown, Header, Image, Menu } from 'semantic-ui-react';
import useOutsideAlerter from '../../helpers/outsideClickHelper';
import { useStore } from '../../stores/store';
import AvatarImage from '../image/AvatarImage';

const NavBar = () => {
    const { authStore: { logout, user, defaultUserPath }, commonStore: { toggleSidebar, isMobile } } = useStore();
    const [showProfileMenu, toggleshowProfileMenu] = useState(false);
    const history = useHistory();
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, () => {
        toggleshowProfileMenu(false);
    });

    const handleClickShowProfileMenu = () => {
        toggleshowProfileMenu(!showProfileMenu);
    };

    return (
        <Menu fixed='top' borderless style={{ boxShadow: 'none' }}>
            <Container style={{ width: 1575 }}>
                <Menu.Item onClick={toggleSidebar} style={{ display: isMobile ? '' : 'none', width: '80px', justifyContent: 'center' }}>
                    <AiOutlineMenu fontSize='1.7em' />
                </Menu.Item>
                <Menu.Item as={Link} to={defaultUserPath} exact header style={{ padding: 0, display: isMobile ? 'none' : '' }}>
                    <Image src='/assets/logo-no-tagline.png' alt='logo' size='small' style={{ width: 160, marginLeft: 15 }} />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <div ref={wrapperRef} onClick={handleClickShowProfileMenu}>
                        <Menu.Item style={{ padding: '.9em', marginRight: '1em', cursor: 'pointer' }}>
                            <AvatarImage userId={user?.id!} hasPhoto={user?.hasProfilePicture!} style={{ width: '40px', height: '40px' }} avatar circular />
                            <Header style={{ margin: '5px 2px 0px 0px' }} as='h5'>{user?.displayName}</Header>
                            <Dropdown onClick={handleClickShowProfileMenu} open={showProfileMenu} icon='chevron down' className='small navbar-dropdown' pointing='top right'>
                                <Dropdown.Menu
                                    style={{
                                        width: 200,
                                    }}>
                                    {/*<Dropdown.Item text='Change Password' icon='user' />*/}
                                    <Dropdown.Item style={{ margin: '7px  0.25rem 0 0' }} onClick={() => history.push('/changepassword')} text='Change Password' icon='edit outline' />
                                    <Dropdown.Item style={{ margin: '7px  0.25rem 0 0' }} onClick={logout} text='Logout' icon='power' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </div>
                </Menu.Menu>
            </Container>
        </Menu>
    );
}

export default observer(NavBar);