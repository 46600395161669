import { useState } from "react";
import { Button } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { Admin } from "../../app/constants/role";

const ReviewListActionButtons = ({ props, conductReview, cancelReview, addComment }) => {
    const [reviewing, setReviewing] = useState<boolean>(false);
    const { authStore: { user, auth } } = useStore();

    const ReviewerButton = (props) => {
        if (props.data.reviewer && props.data.reviewer.actionedById && (props.data.reviewer.actionedById === user?.id || auth?.role === Admin)) {
            return (
                <Button
                    style={{
                        width: 100,
                        padding: 0,
                        float: "right"
                    }}
                    key={`cancel_button-${props.data.agreementId}`}
                    loading={reviewing}
                    size="small"
                    color='red'
                    content="Cancel Review"
                    basic
                    onClick={async () => {
                        setReviewing(true);

                        await cancelReview(props);

                        setReviewing(false);
                    }}
                />
            );
        }
        else if (!props.data.reviewer || !props.data.reviewer.actionedById) {
            return (
                <Button
                    style={{
                        width: 100,
                        padding: 0,
                        float: "right"
                    }}
                    key={`review_button-${props.data.agreementId}`}
                    loading={reviewing}
                    size="small"
                    color='blue'
                    content="Review"
                    basic
                    onClick={async () => {
                        setReviewing(true);

                        await conductReview(props);

                        setReviewing(false);
                    }}
                />
            );
        }

        return null;
    }

    return <>
        {ReviewerButton(props)}
        <Button
            key={`comment_button-${props.data.agreementId}`}
            size="small"
            color='blue'
            content="Comment"
            onClick={() => addComment(props)}

        />
    </>
}

export default ReviewListActionButtons;