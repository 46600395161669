import { useHistory } from "react-router-dom";
import { Button, Header, Message, MessageContent, MessageHeader, MessageItem, MessageList } from "semantic-ui-react";

const WhatsNew = () => {
    const history = useHistory();

    return (
        <div style={{ maxWidth: 900, marginRight: "auto", marginLeft: "auto", marginTop: 50 }}>
            <Header as='h2' content='What’s New' />
            <Message>
                <MessageHeader>Version 4.0.0    |   21-06-2024</MessageHeader>
                <MessageContent>Enhancements:</MessageContent>
                <MessageList>
                    <MessageItem>App version number</MessageItem>
                    <MessageItem>Update Email content for eSign</MessageItem>
                    <MessageItem>Updates on Rates</MessageItem>
                    <MessageItem>Additional information for eSign Modal</MessageItem>
                    <MessageItem>Review Cost check box</MessageItem>
                    <MessageItem>Review Cost Updates</MessageItem>
                    <MessageItem>Hide all Refund Advance related fields</MessageItem>
                    <MessageItem>Access for Download Master List</MessageItem>
                    <MessageItem>Updated Tax Agreement Documents</MessageItem>
                    <MessageItem>ID Verification</MessageItem>
                    <MessageItem>Updates on Help Section</MessageItem>
                </MessageList>
            </Message>
            <Button style={{ marginTop: 15 }} floated='right' content='Back' onClick={() => history.goBack()} size='large' />
        </div>
    );
}

export default WhatsNew;