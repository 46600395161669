import { Form, Formik } from 'formik';
import { Button, Card, Grid, Header, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import MaskInput from '../../app/common/form/MaskInput';
import InveterviewLabel from '../../app/styled-element/InterviewLabel';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormSelect from '../../app/common/form/FormSelect';
import { useEffect, useState } from 'react';
import { intergerErrorMessage, thisFieldIsRequired } from '../../app/constants/message'
import LoadingComponet from '../../app/layout/LoadingComponent';
import GridRow from './sub/GridRow';
import InterviewHeader from './sub/InterviewHeader';
import FooterButtons from './sub/FooterButtons';
import NoteList from './notes/NoteList';

const yupValidation = Yup.number().required(thisFieldIsRequired).integer(intergerErrorMessage).moreThan(-1, intergerErrorMessage);

const InterviewForm2 = () => {
    const history = useHistory();
    const { lookupStore: { loadbusinessStructures, businessStructures } } = useStore();
    const [isIndividual, setIsIndividual] = useState(true);
    const { agreementStore, commonStore: { isGridStack } } = useStore();
    const { get: getAgreement, loadingInitial, agreement, setAgreement, cancel, isLoading, save, setViewOnly, viewOnly } = agreementStore;
    const { id, view } = useParams<{ id: string, view: string | undefined }>();

    const handleBack = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFirstPage: value } });

            history.push(`/agreements/interview/bankInformation/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleSave = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFirstPage: value } });
            save(id)
        }
    }

    const handleNext = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFirstPage: value } });
            history.push(`/agreements/interview/2/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    useEffect(() => {
        setViewOnly(view ? true : false);
        loadbusinessStructures().then((businessStructures) => {
            getAgreement(id).then((agreement) => {
                let selectedBusinessStructure = businessStructures.find(b => b.id === agreement.interview.interviewFirstPage.businessStructureId);
                if (selectedBusinessStructure?.name === 'Individual' || selectedBusinessStructure?.name === 'Super Basic Individual') {
                    setIsIndividual(true);
                }
                else setIsIndividual(false)
            });

        })
    }, [id]);

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <Grid stackable className='gridForm'>
            <LoadingComponet active={isLoading} content='Please wait...' />
            <Grid.Row>
                <Grid.Column width={11}>
                    <Card fluid>
                        <InterviewHeader />
                        <Card.Content>
                            <Formik
                                enableReinitialize
                                initialValues={agreement?.interview.interviewFirstPage!}
                                onSubmit={(values, { setErrors }) => handleNext(values)}
                                validationSchema={Yup.object(!isIndividual ? {
                                    businessStructureId: Yup.string().required(thisFieldIsRequired),
                                    soleTraderReturnsNonGST: yupValidation,
                                    soleTraderReturnsGST: yupValidation,
                                    basPrepareAndLodgementPerQuarter: yupValidation,
                                    companyTrustReturnsRateCard: yupValidation,
                                } : {})}
                            >
                                {
                                    ({ handleSubmit, values, setValues }) => (
                                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                            <Grid stackable>
                                                <GridRow isGridStack={isGridStack} firstColumn='Business (One off Services)' />
                                                <GridRow
                                                    isGridStack={isGridStack}
                                                    firstColumn='Business Structure'
                                                    secondColumn={
                                                        <FormSelect
                                                            disabled={viewOnly}
                                                            options={businessStructures.map((b) => {
                                                                return {
                                                                    key: b.id,
                                                                    value: b.id,
                                                                    text: b.name
                                                                }
                                                            })}
                                                            placeholder='Business Structure'
                                                            name='businessStructureId'
                                                            onChange={(e: any) => {
                                                                if (e.target.textContent === 'Individual' || e.target.textContent === 'Super Basic Individual') {
                                                                    setIsIndividual(true);
                                                                    setValues({
                                                                        ...values,
                                                                        entityName: '',
                                                                        abn: '',
                                                                        soleTraderReturnsNonGST: 0,
                                                                        soleTraderReturnsGST: 0,
                                                                        basPrepareAndLodgementPerQuarter: 0,
                                                                        companyTrustReturnsRateCard: 0,
                                                                    })
                                                                }
                                                                else {
                                                                    setIsIndividual(false);
                                                                    setValues({
                                                                        ...values,
                                                                        soleTraderReturnsNonGST: 299,
                                                                        soleTraderReturnsGST: 399,
                                                                        basPrepareAndLodgementPerQuarter: 180,
                                                                        companyTrustReturnsRateCard: 449,
                                                                    })
                                                                }
                                                            }}
                                                        />}
                                                />
                                                <GridRow isGridStack={isGridStack} firstColumn='Entity Name' secondColumn={<TextInput disabled={isIndividual || viewOnly} name='entityName' placeholder='Entity Name' type='text' />} />
                                                <GridRow isGridStack={isGridStack} firstColumn='ABN' secondColumn={<MaskInput mask='99 999 999 999' disabled={isIndividual || viewOnly} name='abn' placeholder='ABN' type='text' />} />
                                                <GridRow isGridStack={isGridStack} firstColumn='Sole trader returns (non GST)' secondColumn={<TextInput icon='dollar' disabled={isIndividual || viewOnly} name='soleTraderReturnsNonGST' placeholder='Sole trader returns from (non GST)' type='number' />} />
                                                <GridRow isGridStack={isGridStack} firstColumn='Sole trader returns (GST)' secondColumn={<TextInput icon='dollar' disabled={isIndividual || viewOnly} name='soleTraderReturnsGST' placeholder='Sole trader returns from (GST)' type='number' />} />
                                                <GridRow isGridStack={isGridStack} firstColumn='BAS prepare and lodgement' secondColumn={<TextInput icon='dollar' disabled={isIndividual || viewOnly} name='basPrepareAndLodgementPerQuarter' placeholder='BAS prepare and lodgement from (per qtr)' type='number' />} />
                                                <GridRow isGridStack={isGridStack} firstColumn='Company / trust returns' secondColumn={<TextInput icon='dollar' disabled={isIndividual || viewOnly} name='companyTrustReturnsRateCard' placeholder='Company / trust returns from (see rate card)' type='number' />} />
                                            </Grid>
                                            <FooterButtons onClickBack={() => handleBack(values)} onClickCancel={() => cancel(id)} onClickSave={() => handleSave(values)} onClickNext={handleSubmit} />
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                    <NoteList />
                </Grid.Column >
            </Grid.Row>
        </Grid>
    );
}

export default observer(InterviewForm2);