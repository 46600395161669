import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import DateHepler from "../../app/helpers/dateHelper";
import LoadingComponet from "../../app/layout/LoadingComponent";
import { MasterList } from "../../app/models/masterlist";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";
import MoveClientModal from "../client/MoveClientModal";
import { Admin } from "../../app/constants/role";

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const ClientList = () => {
    const { masterlistStore, commonStore, authStore: { auth } } = useStore();
    const {
        masterList, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterList, filterValues, setFilterValues, download, downloadLoading } = masterlistStore;

    const dataHepler = new DateHepler();

    const [modalOpen, setModalOpen] = useState(false);
    const [moveClientId, setMoveClientId] = useState("");

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        }
    }

    const handleDownload = () => {
        download().catch((e) => {
            toast.warn('No available Data.');
        });
    }

    useEffect(() => {
        filterList();

        return () => {
            setFilterValues({ ...filterValues, pageNumber: 1, startDate: null, endDate: null });
        };
    }, []);

    return (
        <>
            < StyledContainer >
                <LoadingComponet active={downloadLoading} content='Please wait...' />
                <PaginatedTable <MasterList>
                    isMobile={commonStore.isMobile}
                    title="Master List"
                    columns={
                        [
                            { title: 'CurrentStatus', field: 'currentStatus' },
                            { title: 'Title', field: 'title' },
                            { title: 'FirstName', field: 'firstName' },
                            { title: 'LastName', field: 'lastName' },
                            { title: 'MobileNumber', field: 'mobilePhone' },
                            { title: 'OtherNumber', field: 'otherNumber' },
                            { title: 'Email', field: 'email' },
                            { title: 'PostalCode', field: 'postalCode' },
                            { title: 'TaxFileNumber', field: 'taxFileNumber' },
                            { title: 'Birthday', field: 'birthday', type: 'date',render: (data: any) => { return data.birthday ? dataHepler.formatShortDate(data.birthday) : '' } },
                            { title: 'ReturnType', field: 'returnType' },
                            { title: 'BankAccountName', field: 'bankAccountName' },
                            { title: 'BankStateBranch', field: 'bankStateBranch' },
                            { title: 'BankAccountNumber', field: 'bankAccountNumber' },
                            { title: 'DateRegistered', field: 'dateRegistered', render: (data: any) => { return data.dateRegistered ? dataHepler.formatShortDate(data.dateRegistered) : '' } },
                            { title: 'TaxConsultant', field: 'taxConsultantName' },
                            { title: 'TaxConsultantLocation', field: 'location' },
                            { title: 'NumberOfAnnualTaxReturn', field: 'numberOfAnnualTaxReturn' },
                            { title: 'AnnualTaxReturnListYear', field: 'annualTaxReturnListYear' },
                            { title: 'EsignToCustomer', field: 'esignToCustomer', render: (data: any) => { return data.esignToCustomer ? dataHepler.formatShortDate(data.esignToCustomer) : '' } },
                            { title: 'EsignConfirmedByCustomer', field: 'esignConfirmedByCustomer', render: (data: any) => { return data.esignConfirmedByCustomer ? dataHepler.formatShortDate(data.esignConfirmedByCustomer) : '' } },
                            { title: 'PaymentOptionName', field: 'paymentOptionName' },
                            { title: 'Discount', field: 'discount' },
                            { title: 'NetRefund', field: 'netRefund' },
                            { title: 'GrossRefund', field: 'grossRefund' },
                            { title: 'GovernmentDebt', field: 'governmentDebt' },
                            { title: 'TotalFee', field: 'totalFee' },
                            { title: 'BaseFee', field: 'baseFee' },
                            { title: 'HandlingFee', field: 'handlingFee' },
                            { title: 'RentalFee', field: 'rentalFee' },
                            { title: 'SoldAssetFee', field: 'soldAssetFee' },
                            { title: 'ReceiveGovernmentPayments', field: 'receiveGovernmentPayments' },
                            { title: 'HasDependantChildOrSpouse', field: 'hasDependentChildOrSpouse' },
                            { title: 'CurrentyPayingChildSupport', field: 'currentlyPayingChildSupport' },
                            { title: 'HasCurrentChildSupportDebt', field: 'hasCurrentChildSupportDebt' },
                            { title: 'IsQouteAcceptable', field: 'isQuoteAcceptable' },
                            { title: 'Notes', field: 'notes' },
                            { title: 'HowDidYouFirstHeartAboutUs', field: 'howDidYouFirstHearAboutUs' },
                            { title: 'BusinessStructure', field: 'businessStructure' },
                            { title: 'EntityName', field: 'entityName' },
                            { title: 'ABN', field: 'abn' },
                            { title: 'SoleTraderReturnNonGST', field: 'soleTraderReturnNonGST' },
                            { title: 'SoleTraderReturnGST', field: 'soleTraderReturnGST' },
                            { title: 'BASPrepare', field: 'basPrepare' },
                            { title: 'CompanyOrTrustReturn', field: 'companyOrTrustReturn' },
                            { title: 'OtherTaxServices', field: 'otherTaxServices' },
                            { title: 'OccupationOrTypeOfBusiness', field: 'occupationOrTypeOfBusiness' },
                            { title: 'Pre2014Returns', field: 'pre2014Returns' },
                            { title: 'TravelAllowances', field: 'travelAllowances' },
                            { title: 'SelfEducation', field: 'selfEducation' },
                            { title: 'OTME', field: 'otme' },
                            { title: 'LogbookSetup', field: 'logbookSetup' },
                            { title: 'NameOfPartner', field: 'nameOfPartner' },
                            { title: 'PartnerDOB', field: 'partnerDOB', render: (data: any) => { return data.partnerDOB ? dataHepler.formatShortDate(data.partnerDOB) : '' } },
                            { title: 'PartnerEarnIncomeTax', field: 'partnerEarnIncomeTax' },
                            { title: 'RefundAdvanceCharge', field: 'refundAdvanceCharge' },
                            { title: 'RefundAdvancePayment', field: 'refundAdvancePayment' },
                            { title: 'UpdatedBy', field: 'updatedBy' },
                            { title: 'InvoiceNumber', field: 'invoiceNumber' },
                            { title: 'Review Cost', field: 'reviewCost' },
                            { title: 'Reviewer Fee', field: 'reviewerFee' },

                        ]}
                    options={{
                        rowStyle: rowData => {
                            return {
                                borderBottom: '1px solid rgb(110 110 110 / 24%)'
                            }
                        }
                    }}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={
                        p => {
                            setPageNumber(p);
                        }}
                    onSearchChange={
                        query => {
                            setSearchQuery(query);

                        }}
                    data={masterList}
                    isLoading={isLoading}
                    toolbar={true}
                    onDateRangeFilter={handleDateRangeFilter}
                    dateRangeValues={[filterValues.startDate!, filterValues.endDate!]}
                    dateRangeFilter={true}
                    rightButton={auth?.role == Admin || auth?.role == "Location Manager" ? {
                        label: "Download",
                        onClick: handleDownload
                    } : undefined}
                />
            </ StyledContainer >
            <MoveClientModal clientId={moveClientId} open={modalOpen} setOpen={setModalOpen} />
        </>
    )
}

export default observer(ClientList);
