import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Grid, Icon } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import { intergerErrorMessage, thisFieldIsRequired } from '../../app/constants/message';
import LoadingComponet from '../../app/layout/LoadingComponent';
import { useStore } from '../../app/stores/store';
import AgreementContract from './contractsV2/Agreement/AgreementContract';
import DirectDebit from './contractsV2/DirectDebit/DirectDebit';
import Invoice from './contractsV2/Invoice/Invoice';
import NoteList from './notes/NoteList';
import FooterButtons from './sub/FooterButtons';
import GridRow from './sub/GridRow';
import InterviewHeader from './sub/InterviewHeader';
import { InterviewFourthPage } from '../../app/models/Agreement'
import CheckBox from '../../app/common/form/CheckBox';
import TermsAndConditions from './contractsV2/TermsAndConditions/TermsAndCondition';

const yupValidation = Yup.number().required(thisFieldIsRequired).integer(intergerErrorMessage).moreThan(-1, intergerErrorMessage);

const InterviewForm4 = () => {
    const swal = withReactContent(Swal);
    const history = useHistory();
    const { agreementStore, lookupStore: { loadPaymentOptions, loadbusinessStructures } } = useStore();
    const { get, loadingInitial, agreement, setAgreement, isLoading, cancel, save, eSign, printInvoice, setViewOnly, viewOnly } = agreementStore;
    const { id, view } = useParams<{ id: string, view: string }>();
    const [selectedPayment, setSelectedPayment] = useState('');

    let pending = true;

    useEffect(() => {
        function beforeUnload(e: BeforeUnloadEvent) {
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [pending]);

    useEffect(() => {
        setViewOnly(view ? true : false);
        loadbusinessStructures().then(() => {
            loadPaymentOptions().then(options => {
                get(id).then((a) => {
                    let payment = options.find(o => o.id === a.interview.interviewThirdPage.paymentOptionId);
                    setSelectedPayment(payment?.name ? payment?.name : '');
                    setAgreement(a);
                });
            });
        })
    }, [id]);

    const handleBack = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFourthPage: value } });
            history.push(`/agreements/interview/3/${id}${viewOnly ? "/view" : ""}`);
        }
    }

    const handleSave = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFourthPage: value } });
            save(id)
        }
    }

    const handlePrintInvoice = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFourthPage: value } });
            printInvoice();
        }
    }

    const handleESign = (value: InterviewFourthPage) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewFourthPage: value } });

            if (('Refund Advance' === selectedPayment || 'Fee from refund' === selectedPayment) && agreement.interview.interviewFourthPage.estimatedNetRefund <= 0) {
                swal.fire({
                    html: `<strong>Selected payment method is not permitted due to negative Estimated Net Refund</strong>.
                           <br>
                           <br>
                           Do you wish to change the payment method?
                           <br>`,
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleBack(value);
                    }
                });
            }
            else if ('Refund Advance' === selectedPayment && agreement.interview.interviewFourthPage.refundAdvancePayment <= 0) {
                swal.fire({
                    html: `<strong>Please enter a value for the Refund Advance Payment.</strong>`,
                    icon: 'error',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Close'
                });
            }
            else {
                eSign(id);
            }
        }
    }

    const storeAgreement = (value: InterviewFourthPage) => {
        if (agreement) {
            setAgreement({
                ...agreement,
                interview: {
                    ...agreement.interview,
                    interviewFourthPage: value
                }
            });
        }
    }

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <>
            <Grid stackable className='gridForm'>
                <Grid.Row>
                    <LoadingComponet active={isLoading} content='Please wait...' />
                    <Grid.Column width={11}>
                        <Card fluid>
                            <InterviewHeader />
                            <Card.Content>
                                <Formik
                                    enableReinitialize
                                    initialValues={agreement?.interview.interviewFourthPage!}
                                    onSubmit={(values, { setErrors }) => storeAgreement(values)}
                                    validationSchema={Yup.object({
                                        estimatedGrossTaxRefund: Yup.string().required(thisFieldIsRequired),
                                        estimatedGovernmentDebt: Yup.string().required(thisFieldIsRequired),
                                        refundAdvancePayment: 'Refund Advance' === selectedPayment ? yupValidation : Yup.number()
                                    })}
                                >
                                    {
                                        ({ handleSubmit, values }) => (
                                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                                <Grid >
                                                    <GridRow firstColumn='Estimated Gross Tax Refund / or Payable(-)' secondColumn={<TextInput disabled={viewOnly} onKeyUp={(e) => handleSubmit()} name='estimatedGrossTaxRefund' placeholder='Estimated Gross Tax Refund / or Payable(-)' type='number' />} />
                                                    <GridRow firstColumn='Accountants Direct Fees' secondColumn={<TextInput disabled name='accountsDirectFees' placeholder='Accounts Direct Fees' type='number' />} />
                                                    <GridRow firstColumn='Discounted Fees'
                                                        secondColumn={
                                                            <>
                                                                <TextInput hidden icon='dollar' disabled name='discountedFees' placeholder='Discounted Fees' type='number' />
                                                                <div style={{ display: 'flex', margin: '0.5rem 0 0.28571429rem 0', }}>
                                                                    <Icon style={{ marginTop: 2 }} name='dollar' />
                                                                    <p style={{
                                                                        display: 'block',
                                                                        fontSize: '1.15em',
                                                                        fontWeight: 700,
                                                                        textTransform: 'none',
                                                                    }}>
                                                                        <span style={{
                                                                            color: 'rgba(0, 0, 0, .87)',
                                                                        }}>
                                                                            {agreement?.interview.interviewFourthPage.discountedFees}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </>}
                                                    />
                                                    <GridRow firstColumn='Estimated Government Debt' secondColumn={<TextInput disabled onKeyUp={(e) => handleSubmit()} name='estimatedGovernmentDebt' placeholder='Estimated Government Debt' type='number' />} />
                                                    <GridRow style={{ display: "none" }} firstColumn='Refund Advance Payment' secondColumn={<TextInput onKeyUp={(e) => handleSubmit()} disabled={!('Refund Advance' === selectedPayment) || viewOnly} name='refundAdvancePayment' placeholder='Refund Advance Payment' type='number' />} />
                                                    <GridRow firstColumn='Estimated Net Refund' secondColumn={<TextInput disabled name='estimatedNetRefund' placeholder='Estimated Net Refund' type='number' toFixed={2} />} />
                                                    <GridRow firstColumn='Invoice Number' secondColumn={<TextInput disabled name='invoiceNumber' placeholder='Invoice Number' type='text' />} />
                                                    <GridRow firstColumn='Review Cost'
                                                        secondColumn={
                                                            <>
                                                                <TextInput hidden icon='dollar' disabled name='reviewCost' placeholder='Discounted Fees' type='number' />
                                                                <div style={{ display: 'flex', margin: '0.5rem 0 0.28571429rem 0', }}>
                                                                    <Icon style={{ marginTop: 2 }} name='dollar' />
                                                                    <p style={{
                                                                        display: 'block',
                                                                        fontSize: '1.15em',
                                                                        fontWeight: 700,
                                                                        textTransform: 'none',
                                                                    }}>
                                                                        <span style={{
                                                                            color: 'rgba(0, 0, 0, .87)',
                                                                        }}>
                                                                            {agreement?.interview.interviewFourthPage.reviewCost.toFixed(2)}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </>}
                                                    />
                                                    <GridRow firstColumn="Conduct Review" secondColumn={<CheckBox name='conductReview' style={{ marginTop: 12 }} onClick={(e) => handleSubmit()} />}
                                                    />
                                                </Grid>
                                                <FooterButtons onClickBack={() => handleBack(values)} onClickCancel={() => cancel(id)} onClickSave={() => handleSave(values)} onClickPrintInvoice={() => handlePrintInvoice(values)} onClickESign={() => handleESign(values)} />
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <NoteList />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Invoice />
            <DirectDebit />
            <TermsAndConditions />
            <AgreementContract />
        </>
    );
}

export default observer(InterviewForm4);