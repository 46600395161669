import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Grid } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import LoadingComponet from '../../app/layout/LoadingComponent';
import { useStore } from '../../app/stores/store';
import InveterviewLabel from '../../app/styled-element/InterviewLabel';
import NoteList from './notes/NoteList';
import FooterButtons from './sub/FooterButtons';
import InterviewHeader from './sub/InterviewHeader';
import { intergerErrorMessage, thisFieldIsRequired } from '../../app/constants/message';

const BankInformationForm = () => {
    const history = useHistory();
    const { agreementStore, commonStore: { isGridStack, } } = useStore();
    const { get, loadingInitial, agreement, setAgreement, cancel, save, isLoading, viewOnly, setViewOnly } = agreementStore;

    const { id, view } = useParams<{ id: string, view: string | undefined }>();
    useEffect(() => {
        setViewOnly(view ? true : false);
        get(id);
    }, [id]);

    const handleSubmit = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, bankInformation: value });
            history.push(`/agreements/interview/1/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleSave = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, bankInformation: value });
            save(id);
        }
    }

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <Grid stackable className='gridForm'>
            <LoadingComponet active={isLoading} content='Please wait...' />
            <Grid.Row>
                <Grid.Column width={11}>
                    <Card fluid>
                        <InterviewHeader />
                        <Card.Content>

                            <Formik
                                enableReinitialize
                                initialValues={agreement?.bankInformation!}
                                onSubmit={(values: any, { setErrors }) => handleSubmit(values)}
                                validationSchema={viewOnly ? Yup.object({}) : Yup.object({
                                    accountName: Yup.string().required('Account Name field is required.').nullable(),
                                    bsb: Yup.string().required(thisFieldIsRequired)
                                        .matches(/^[0-9]+$/, intergerErrorMessage)
                                        .min(6, 'BSB must be a 6 digits number.')
                                        .max(6, 'BSB must be a 6 digits number.')
                                        .nullable(),
                                    bankAccount: Yup.string().required(thisFieldIsRequired)
                                        .matches(/^[0-9]+$/, intergerErrorMessage)
                                        .min(6, 'Bank Account must be not less than 6 digits number.')
                                        .max(10, 'Bank Account must be not greater than 10 digits number.')
                                        .nullable(),
                                })}
                            >
                                {
                                    ({ handleSubmit, isSubmitting, errors, values, submitForm }) => (
                                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                            <Grid stackable>
                                                <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
                                                    <Grid.Column width={8} ><InveterviewLabel isMobile={isGridStack} content='Account Name' /></Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <TextInput disabled={viewOnly} name='accountName' placeholder='Account Name' />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
                                                    <Grid.Column width={8} ><InveterviewLabel isMobile={isGridStack} content='BSB' /></Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <TextInput disabled={viewOnly} name='bsb' placeholder='BSB' type='text' maxLength={6} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
                                                    <Grid.Column width={8}><InveterviewLabel isMobile={isGridStack} content='Bank Account' /></Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <TextInput disabled={viewOnly} name='bankAccount' placeholder='Bank Account' maxLength={10} minLength={6} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <FooterButtons onClickCancel={() => cancel(id)} onClickSave={() => handleSave(values)} onClickNext={submitForm} />
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                    <NoteList />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default observer(BankInformationForm);