import { useState } from "react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../app/stores/store";
import LoadingComponet from "../../app/layout/LoadingComponent";
import { Button, Card, Grid, Segment } from "semantic-ui-react";
import { Form, Formik } from "formik";
import StyledHeader from "../../app/styled-element/StyledHeader";
import TextInput from "../../app/common/form/TextInput";
import * as Yup from 'yup';
import PasswordInput from "../../app/common/form/PasswordInput";
import FormSelect from "../../app/common/form/FormSelect";
import { toast } from "react-toastify";
import MobileNumberInput from "../../app/common/form/MobileNumberInput";

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const AddUserForm = () => {
    const { userStore, roleStore, commonStore, locationStore, authStore } = useStore();
    const { loadLocations, isLoading: isLoadingLocation, locations } = locationStore;
    const { loadRoles, isLoading: rolesLoading, roles } = roleStore
    const { loadingInitial, create, isLoading } = userStore;
    const history = useHistory();

    const [isAdmin, setIsAdmin] = useState(false);

    const [user, setUser] = useState({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        password: "",
        roleId: "",
        locationId: "",
    });

    useEffect(() => {
        loadLocations();
        loadRoles();
    }, [loadLocations, loadRoles]);

    const formValidation = Yup.object({
        firstName: Yup.string().required('First Name is required.'),
        lastName: Yup.string().required('Last Name field is required.'),
        email: Yup.string().required('Email field is required.').email('The Email is not a valid e-mail address.'),
        locationId: isAdmin ? Yup.string() : Yup.string().required('Location field is required.'),
        mobileNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        roleId: Yup.string().required('Role field is required.'),
        password: Yup.string()
            .required('Password field is required')
            .min(8, 'Password should have a minimum of 8 characters.')
            .matches(/^(?=.*\d)(?=.*[a-zA-Z]).+$/, 'Password should be a combination of alphanumeric. A special character is optional.')
    });

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <StyledContainer isMobile={commonStore.isMobile}>
            <Card style={{ width: '100%' }}>
                <Card.Content className='cardDivided'>
                    <Card.Header style={{ color: '#0099da' }} content='Add User' />
                </Card.Content>
                <Card.Content>
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={(values: any, { setErrors }) => {
                            create(values).then(id => {
                                toast.success("User is successfully created.");
                                history.push('/users')
                            }, error => {
                                setErrors(error);
                            });
                        }}
                        validationSchema={formValidation}
                    >
                        {
                            ({ handleSubmit, isSubmitting, errors, setFieldValue }) => (
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <Grid stackable>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <FormSelect
                                                    disabled={isAdmin}
                                                    options={locations.map(location => {
                                                        return {
                                                            key: location.id,
                                                            value: location.id,
                                                            text: location.name
                                                        }
                                                    })}
                                                    loading={isLoadingLocation}
                                                    placeholder='Location'
                                                    name='locationId'
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <TextInput placeholder='First Name' name='firstName' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Last Name' name='lastName' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <MobileNumberInput placeholder='Mobile No.' name='mobileNumber' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <TextInput placeholder='Email' name='email' />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <PasswordInput name='password' placeholder='Password' />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <FormSelect
                                                    options={roles.map(role => {
                                                        return {
                                                            key: role.id,
                                                            value: role.id,
                                                            text: role.name,
                                                            disabled: (role.name === 'Admin' && authStore.auth?.role !== 'Admin')
                                                        }
                                                    })}
                                                    loading={rolesLoading}
                                                    placeholder='Role'
                                                    name='roleId'
                                                    onChange={(e: any, d: any) => {
                                                        const { value, options } = d;
                                                        const admin = options.find((o: any) => o.text === 'Admin');
                                                        if (admin.value === value) {
                                                            setIsAdmin(true);
                                                            setFieldValue('locationId', '');
                                                        }
                                                        else
                                                            setIsAdmin(false);
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Button style={{ marginTop: 15, borderRadius: 30 }} loading={isLoading} disabled={isLoading} floated='right' content='Add User' primary type='submit' size='medium' />
                                    <Button style={{ marginTop: 15, borderRadius: 30 }} floated='right' content='Cancel' onClick={() => history.push('/users')} size='medium' />
                                </Form>
                            )
                        }
                    </Formik>
                </Card.Content>
            </Card>
        </StyledContainer>
    );
};

export default observer(AddUserForm);
