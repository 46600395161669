import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { Filters } from '../models/filter';
import { SetUserStatusValues, User, UserForm, UserRole } from "../models/user";

export default class UserStore {
    user: User | null = null;
    displayName: string = '';
    users: User[] = [];
    total: number = 0;
    loadingInitial = false;
    isLoading = false;
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    editMode = false;
    usersRole: UserRole | null = null;
    downloadLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }



    create = async (user: UserForm) => {
        this.isLoading = true;

        try {
            let userId = await agent.Users.create(user);
            this.isLoading = false;
            return userId;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    edit = async (user: UserForm) => {
        this.isLoading = true;

        try {
            await agent.Users.edit(user);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    }

    deleteUser = async (userId: string) => {
        try {
            await agent.Users.delete(userId);

            runInAction(() => {
                this.users = this.users.filter(u => u.id != userId);
            });

        } catch (error) {
            console.log(error);

        }
    }

    getConsultantsByLocationId = async (id: string) => {
        this.setLoadingInitial(true);
        let user = await agent.Users.getConsultantsByLocationId(id);

        runInAction(() => {
            this.setLoadingInitial(false);
        });

        return user;
    }

    getById = async (id: string) => {
        this.setLoadingInitial(true);
        let user = await agent.Users.getById(id);

        runInAction(() => {
            this.setLoadingInitial(false);
        });

        return user;
    }

    filterUsers = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, users } = await agent.Users.filter({
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.users = users;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setStatus = async (setStatus: SetUserStatusValues) => {
        await agent.Users.setStatus(setStatus);
        runInAction(() => {
            let user = this.users.find(u => u.id === setStatus.userId);

            if (user) {
                let users = this.users.map(user => {
                    if (user.id === setStatus.userId) return { ...user, status: setStatus.status };

                    return user;
                });

                this.users = users;
            }
        });
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterUsers();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterUsers();
    }

    setDisplayName = (user: User) => {
        if (this.user != null)
            this.displayName = `${user?.firstName} ${user?.lastName}`
    }

    download = async () => {
        this.downloadLoading = true;

        let blob = await agent.Users.download();
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'User List';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.downloadLoading = false;
        }, 0)
    }
}