import { createContext, useContext } from "react";
import AuthStore from "./authStore";
import CommonStore from "./commonStore";
import LookupStore from "./lookupStore";
import UserStore from "./userStore";
import RoleStore from "./roleStore";
import ClientStore from "./clientStore";
import AgreementStore from "./agreementStore";
import MasterListStore from "./masterlistStore";
import ContractStore from "./contractStore";
import NoteStore from "./noteStore";
import LocationStore from "./locationStore";


interface Store {
    agreementStore: AgreementStore;
    authStore: AuthStore;
    clientStore: ClientStore;
    contractStore: ContractStore;
    commonStore: CommonStore;
    lookupStore: LookupStore;
    roleStore: RoleStore;
    userStore: UserStore;
    masterlistStore: MasterListStore;
    noteStore: NoteStore;
    locationStore: LocationStore;
}

export const store: Store = {
    agreementStore: new AgreementStore(),
    authStore: new AuthStore(),
    clientStore: new ClientStore(),
    contractStore: new ContractStore(),
    commonStore: new CommonStore(),
    lookupStore: new LookupStore(),
    roleStore: new RoleStore(),
    userStore: new UserStore(),
    masterlistStore: new MasterListStore(),
    noteStore: new NoteStore(),
    locationStore: new LocationStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}