import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { User } from "../../app/models/user";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const UserList = () => {
    const { userStore, commonStore: { isMobile }, authStore } = useStore();
    const { users, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterUsers, filterValues, setStatus, deleteUser, downloadLoading, download } = userStore;
    const swal = withReactContent(Swal);

    const history = useHistory();

    const routeChange = (path: string) => {
        history.push(path);
    }

    const onSetStatus = (props: any) => {
        const { data } = props;

        swal.fire({
            title: `Are you sure you want to ${data.status ? "Deactivate" : "Activate"} this user?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setStatus({
                    userId: data.id,
                    status: !data.status
                });
            }
        });
    };

    const handleDelete = (userId: string) => {
        swal.fire({
            title: `Are you sure you want to delete this user?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(userId).then(() => {
                    toast.success("User is successfully deleted.");
                });
            }
        });
    }

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterUsers();
    }, [filterUsers]);

    return (
        <>
            <StyledContainer>
                <PaginatedTable<User>
                    isMobile={isMobile}
                    title="Users"
                    rightButton={{
                        label: "Add User",
                        onClick: () => routeChange('users/create')
                    }}
                    rightButton2={{
                        label: "Download",
                        onClick: () => download(),
                        loading: downloadLoading
                    }}
                    columns={[
                        { title: 'id', field: 'id', hidden: true },
                        { title: 'Name', field: 'displayName' },
                        { title: 'Location', field: 'location.name' },
                        { title: 'Email Address', field: 'email' },
                        { title: 'Role', field: 'role.name' },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (data) => {
                                return data.status ? "Active" : "InActive";
                            }
                        },

                    ]}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: async (event) => {
                            }
                        }),
                    ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={p => {
                        setPageNumber(p);
                    }}
                    onSearchChange={query => {
                        setSearchQuery(query);

                    }}
                    data={users}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    components={{
                        Action: props => {
                            const { data } = props;
                            console.log();

                            return (
                                <>
                                    {data.role.name === 'Admin' && authStore.auth?.role !== 'Admin' ?
                                        null :
                                        <>
                                            <Button
                                                onClick={e => {
                                                    routeChange(`Users/Edit/${data.id}`)
                                                }}
                                                size="small"
                                                basic
                                                color='blue'
                                                content="Edit"
                                            />
                                            <Button
                                                onClick={e => onSetStatus(props)}
                                                size="small"
                                                basic
                                                color='blue'
                                                content={props.data.status ? "Deactivate" : "Activate"}
                                            />
                                            <Button
                                                onClick={e => {
                                                    handleDelete(data.id);
                                                }}
                                                size="small"
                                                basic
                                                color='blue'
                                                content="Delete"
                                            />
                                        </>
                                    }
                                </>
                            )
                        },
                    }}
                />
            </StyledContainer>
        </>
    )
}

export default observer(UserList);
