import { Header, Icon, Segment, Image } from "semantic-ui-react";
import styled from "styled-components";

const StyledSegment = styled(Segment)`
    && {
            margin-top: 5vw !important;
            max-width: 500px !important;
            margin: auto;
            background-color: #fff !important;
            padding: 40px 60px !important;
        }
`;

const Declined = () => {
    return (
        <StyledSegment placeholder>
            <Image src={`/assets/accountantsdirect.png`} style={{ width: 200, height: 108 }} centered />
            <Header icon>
                <Icon style={{ marginTop: -25, marginBottom: 25 }} name='times circle' color='red' />
                <span color='#2a2a2a'>
                    Agreement is declined.
                </span>

            </Header>
            <Segment.Inline>

            </Segment.Inline>
        </StyledSegment>
    );
}

export default Declined;