import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import DateHepler from "../../app/helpers/dateHelper";
import LoadingComponet from "../../app/layout/LoadingComponent";
import { MasterList } from "../../app/models/masterlist";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";
import MoveClientModal from "../client/MoveClientModal";
import { Admin } from "../../app/constants/role";
import { Button } from "semantic-ui-react";
import { Filters } from "../../app/models/filter";
import CommentModal from "./CommentModal";
import agent from "../../app/api/agents";
import { ReviewerList } from "../../app/models/reviewer";
import ReviewListActionButtons from "./ReviewListActionButtons";

const StyledContainer = styled.div`
        padding: 1em 2em;
`;



const ReviewList = () => {
    const { commonStore, authStore: { user, auth } } = useStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [modalState, setModalState] = useState<{ open: boolean, review: any }>({ open: false, review: "" });
    const [filters, setFilters] = useState<Filters>({
        pageNumber: 1,
        pageSize: 10,
        searchKeyword: "",
        startDate: null,
        endDate: null
    });

    const [refresh, setRefresh] = useState(0);
    console.log(refresh);
    const [data, setData] = useState<{ total: number, reviews: ReviewerList[] }>({
        reviews: [],
        total: 0
    });

    const dateHepler = new DateHepler();

    useEffect(() => {
        setIsLoading(true);

        agent.Reviewers.filter({
            ...filters,
            startDate: filters.startDate ? dateHepler.formatShortDate(filters.startDate.toDateString()) : null,
            endDate: filters.endDate ? dateHepler.formatShortDate(filters.endDate.toDateString()) : null,
        })
            .then(data => {

                setData({
                    total: data.total,
                    reviews: data.reviewerList
                });
                setIsLoading(false);
            });



    }, [filters, refresh]);

    const cancelReview = async (props) => {
        let reviewer = {
            agreementId: props.data.agreementId,
            actionedById: "",
            comment: props.data.reviewer.comment
        }

        await agent.Reviewers.edit(reviewer);
        setRefresh(prev => (prev + 1));
        //setData(prev => {
        //    return {
        //        total: prev.total,
        //        reviews: prev.reviews.map(r => {
        //            if (r.agreementId == reviewer.agreementId) {
        //                r.reviewer = {
        //                    ...r.reviewer,
        //                    actionedById: "",
        //                    actionedBy: ""
        //                }
        //            }

        //            return r;
        //        })
        //    }
        //});
    }

    const conductReview = async (props) => {
        let reviewer: any = {};

        if (props.data.reviewer) {
            reviewer = {
                agreementId: props.data.agreementId,
                actionedById: props.data.actionById ?? user?.id,
                comment: props.data.reviewer.comment
            };

            await agent.Reviewers.edit(reviewer);


        }
        else {
            reviewer = {
                agreementId: props.data.agreementId,
                actionedById: user?.id,
                comment: ""
            }

            await agent.Reviewers.create(reviewer);
        }

        setRefresh(prev => (prev + 1));

        //setData(prev => {
        //    return {
        //        total: prev.total,
        //        reviews: prev.reviews.map(r => {
        //            if (r.agreementId == reviewer.agreementId) {
        //                r.reviewer = {
        //                    ...r.reviewer,
        //                    actionedById: reviewer.actionedById,
        //                    actionedBy: user?.displayName
        //                }
        //            }

        //            return r;
        //        })
        //    }
        //})
    }

    const addComment = (props) => {
        let review: any = {};

        if (props.data.reviewer) {
            review = props.data.reviewer;
            review.comment = props.data.reviewer.comment ?? "";
        }
        else {
            review = {
                agreementId: props.data.agreementId,
                actionedById: "",
                comment: ""
            }
        }

        setModalState({ review: review, open: true });
    }

    const onCommentSubmit = async (data) => {
        await agent.Reviewers.create({
            agreementId: data.agreementId,
            actionedById: data.actionedById,
            comment: data.comment
        });

        //setData(prev => {
        //    return {
        //        total: prev.total,
        //        reviews: prev.reviews.map(r => {
        //            if (r.agreementId == data.agreementId) {
        //                r.reviewer = data;
        //            }

        //            return r;
        //        })
        //    }
        //});
        setRefresh(prev => (prev + 1));

        setModalState({ review: "", open: false });

    }

    const download = async () => {
        setIsDownloading(true);

        let startDate = filters.startDate ? dateHepler.formatShortDate(filters.startDate.toDateString()) : null;
        let endDate = filters.endDate ? dateHepler.formatShortDate(filters.endDate.toDateString()) : null;

        let blob = await agent.Reviewers.download(startDate, endDate);

        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Review List';
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setIsDownloading(false);
        }, 0);
    }

    const refreshData = () => {
        setRefresh(prev => (prev + 1));
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filters.startDate != startDate && filters.endDate != endDate) {
                setFilters({ ...filters, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilters({ ...filters, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilters({ ...filters, startDate: startDate, endDate: endDate });
        }
    }

    return (
        <>
            <div style={{ padding: "1em 2em" }} key="reviewer-list-container">
                <PaginatedTable<ReviewerList>
                    key="reviewer-list"
                    isMobile={commonStore.isMobile}
                    title="Review List"
                    note={<div style={{ color: "#acacac" }}><b>Note</b>: <i>Always refresh the page to ensure you get the latest update.</i></div>}
                    columns={
                        [
                            { title: 'Name', field: 'name' },
                            { title: 'Tax File Number', field: 'taxFileNumber' },
                            { title: 'Mobile Number', field: 'mobilePhone' },
                            { title: 'Email', field: 'email' },
                            { title: 'Date', render: rowData => dateHepler.formatShortDate(rowData.date) },
                            { title: 'Esign Confirmed By Customer', render: rowData => { return rowData.esignConfirmedByCustomer ? dateHepler.formatShortDate(rowData.esignConfirmedByCustomer) : '' } },
                            { title: 'Tax Consultant Location', field: 'taxConsultantLocation' },
                            { title: 'Reviewer Fee', field: 'reviewerFee' },
                            {
                                title: 'Actioned by (Reviewer) ',
                                render: rowData => {
                                    if (rowData.reviewer && rowData.reviewer.actionedBy) {
                                        return rowData.reviewer.actionedBy;
                                    }
                                }
                            },
                        ]}
                    options={{
                        rowStyle: rowData => {
                            return {
                                borderBottom: '1px solid rgb(110 110 110 / 24%)'
                            }
                        }
                    }}
                    total={data.total}
                    pageSize={10}
                    pageNumber={filters.pageNumber}
                    totalCount={data.total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={
                        p => {
                            setFilters(prev => ({
                                ...prev,
                                pageNumber: p
                            }));
                        }}
                    onSearchChange={
                        query => {
                            setFilters(prev => ({
                                ...prev,
                                searchKeyword: query
                            }));
                        }}
                    data={data.reviews.map(row => ({ ...row, tableDataId: row.agreementId }))}
                    isLoading={isLoading}
                    toolbar={true}
                    rightButton={{
                        label: "Refresh",
                        onClick: refreshData
                    }}
                    rightButton2={auth?.role == Admin || auth?.role == "Location Manager" ? {
                        label: "Download",
                        onClick: download,
                        loading: isDownloading
                    } : undefined}
                    actions={[

                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: async (event) => {
                            }
                        }),
                    ]}
                    components={
                        {
                            Action: (props) => (
                                <ReviewListActionButtons props={props} conductReview={conductReview} cancelReview={cancelReview} addComment={addComment} />
                            ),
                        }
                    }
                    actionStyle={{
                        justifyContent: 'right',
                    }}
                    onDateRangeFilter={handleDateRangeFilter}
                    dateRangeValues={[filters.startDate!, filters.endDate!]}
                    dateRangeFilter={true}
                />
            </div>
            <CommentModal data={modalState.review} open={modalState.open} onClickSubmit={onCommentSubmit} onClickCancel={() => setModalState(prev => ({ review: "", open: false }))} />
        </>
    );
}

export default ReviewList;