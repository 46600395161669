import { useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import 'video-react/dist/video-react.css';
import VideoContainer from "./VideoContainer";
import VideoModal from "./VideoModal";

const items = [
    {
        title: '1. Taxcess & Payou Overview (Pre Demo)',
        source: 'https://drive.google.com/file/d/1S4Hj1NiPBcwdpayFJsbIzNoDfa8i6B5i/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1S4Hj1NiPBcwdpayFJsbIzNoDfa8i6B5i=s320-w320-h200-k-p'
    },
    {
        title: '2. Logging in & NEW client registration',
        source: 'https://drive.google.com/file/d/1SE2pt3QjI6kdyXhXAk7ojbyiE2sk7eMx/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SE2pt3QjI6kdyXhXAk7ojbyiE2sk7eMx=s320-w320-h200-k-p'
    },
    {
        title: '3. Finding & changing CURRENT client info & starting new contract',
        source: 'https://drive.google.com/file/d/1SE9vM0LXFTUKeRjqwlHIGPkBkRE3Pt-t/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SE9vM0LXFTUKeRjqwlHIGPkBkRE3Pt-t=s320-w320-h200-k-p'
    },
    {
        title: '4. Inputting bank details and calculating qoute - individual & sole trader',
        source: 'https://drive.google.com/file/d/1SKCj2PP04BMGraR3IjcPGy8sDoxwSOsW/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SKCj2PP04BMGraR3IjcPGy8sDoxwSOsW=s320-w320-h200-k-p'
    },
    {
        title: '5. Calculating qoute, company, trust, partnership BAS',
        source: 'https://drive.google.com/file/d/1SLnwLYp6AYaAY4PrCTvcU37h3pAHXLLD/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SLnwLYp6AYaAY4PrCTvcU37h3pAHXLLD=s320-w320-h200-k-p'
    },
    {
        title: '6. Choosing payment option, delivering quote & inputting xero refund payment',
        source: 'https://drive.google.com/file/d/1SLuRJdfqvgKf360clXqPee9UgUhpBgb6/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SLuRJdfqvgKf360clXqPee9UgUhpBgb6=s320-w320-h200-k-p'
    },
    {
        title: '7. eSigning',
        source: 'https://drive.google.com/file/d/1SQggyoNweh0RXxt8Gd7IVG-JxOM5F8Yj/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SQggyoNweh0RXxt8Gd7IVG-JxOM5F8Yj=s320-w320-h200-k-p'
    },
    {
        title: '8. Identity verification (new phone clients only)',
        source: 'https://drive.google.com/file/d/1SXKB-5fig_ixBFg3lAlC5ch9Zup2l6JF/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SXKB-5fig_ixBFg3lAlC5ch9Zup2l6JF=s320-w320-h200-k-p'
    },
    {
        title: '9. Agreement dashboard and Status',
        source: 'https://drive.google.com/file/d/1SYVcpopDQqSAGcoVfYKIoFEsPnaOONb6/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SYVcpopDQqSAGcoVfYKIoFEsPnaOONb6=s320-w320-h200-k-p'
    },
    {
        title: '10. Finalising FFR set up in Payou',
        source: 'https://drive.google.com/file/d/1Sj7DRwtMfkcfVT9bXvL7qeSccBXg1yq_/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1Sj7DRwtMfkcfVT9bXvL7qeSccBXg1yq_=s320-w320-h200-k-p'
    },
    {
        title: '11. How to do a Paper Signed Agreement(Only used if absolutely necessary)',
        source: 'https://drive.google.com/file/d/1SgwhvXr1J4btBS0hA7SR-6if4s5Rqah0/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SgwhvXr1J4btBS0hA7SR-6if4s5Rqah0=s320-w320-h200-k-p'
    },
    {
        title: '12. How to take payment using Westpac Payway',
        source: 'https://drive.google.com/file/d/1SlsJruUNbfJwy9YRQO0-PZ1LVbc8iRFd/preview',
        thumbnail: 'https://lh3.google.com/u/0/d/1SlsJruUNbfJwy9YRQO0-PZ1LVbc8iRFd=s320-w320-h200-k-p'
    },
]

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth <= 1280 ? 3 : 4,
    initialSlide: 0,
    nextArrow: <MdArrowForwardIos color='#4a4a4a' />,
    prevArrow: <MdArrowBackIos color='#4a4a4a' />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const VideoCarousel = () => {
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const handleClick = (url: string) => {
        setUrl(url)
        setOpen(true);
    }

    return (
        <>
            <VideoModal open={open} src={url} onClose={() => setOpen(false)} />
            <div className='video-slider'>
                <Slider {...settings}>
                    {items.map((item, index) => {
                        return (
                            <VideoContainer handleClick={handleClick} index={index} item={item} />
                            //<div onClick={() => handleClick(item.source)} key={'video-' + index} className='video-container'>
                            //    <Card fluid>
                            //        <Icon name='play circle' className='play-button' />
                            //        <Image style={{ minHeight: 30 }} className='thumbnail' src={item.thumbnail} wrapped ui={false} />
                            //        <Card.Content style={{ minHeight: 89 }}>
                            //            <Card.Description>{item.title}</Card.Description>
                            //        </Card.Content>
                            //    </Card>
                            //</div>
                        );
                    }
                    )}
                </Slider>
            </div>
        </>
    )
}

export default VideoCarousel;