import { useHistory } from "react-router-dom";

const Version = (props) => {
    const history = useHistory();

    return (
        <div
            onClick={() => history.push("whats-new")}
            style={{ color: "#a39a9a", cursor: "pointer", ...props.style }}>
            version 4.0.0 | 21-06-2024
        </div>
    )
}

export default Version;