import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Grid, Header, Modal } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import FormSelect from "../../app/common/form/FormSelect";

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    clientId: string
}


const MoveClientModal = ({ open, setOpen, clientId }: Props) => {
    const { clientStore, locationStore, userStore } = useStore();
    const { get, editConsultant, filterClients } = clientStore;
    const { getConsultantsByLocationId } = userStore;
    const { loadLocations, isLoading: isLoadingLocation, locations } = locationStore;
    const [newConsultant, setNewConsultant] = useState({
        locationId: "",
        consultantId: ""
    });

    const [currentConsultant, setCurrentConsultant] = useState({
        location: "",
        consultantName: ""
    });

    const handleChangeLocation = (locationId: string, consultantId?: string) => {
        setConsultant({
            consultantsLoading: true,
            consultants: []
        });

        setNewConsultant({
            ...newConsultant,
            consultantId: ""
        });

        if (locationId) {
            getConsultantsByLocationId(locationId).then(users => {
                setConsultant({
                    consultantsLoading: false,
                    consultants: users.map(u => {
                        return {
                            id: u.id,
                            name: u.displayName
                        }
                    })
                });
            })
        }
        else {
            setConsultant({
                consultantsLoading: false,
                consultants: []
            });
        }
    }

    const [consultant, setConsultant] = useState({
        consultantsLoading: false,
        consultants: [{
            id: "",
            name: ""
        }]
    })

    const handleModalMount = () => {
        setNewConsultant({
            locationId: "",
            consultantId: ""
        })
        loadLocations();
        get(clientId).then(client => {
            setCurrentConsultant({
                location: client.location?.name!,
                consultantName: client.consultant?.fullName!
            });
        });
    }

    return (
        <Modal
            size="small"
            open={open}
            onClose={() => console.log("on modal close")}
            onMount={handleModalMount}
        >
            <Modal.Header>Move Client Location</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={newConsultant}
                    onSubmit={(values: any, { setErrors }) => {
                        console.log(values);
                        editConsultant({
                            id: clientId,
                            consultantId: values.consultantId
                        }).then(() => {
                            filterClients();
                            setOpen(false);
                        })
                    }}
                    validationSchema={Yup.object({
                        consultantId: Yup.string().required('Consultant field is required.'),
                        locationId: Yup.string().required('Locations field is required.')
                    })}
                >
                    {
                        ({ handleSubmit, isSubmitting, errors, setFieldValue }) => (
                            <Form id='moveClientForm' className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label style={{
                                                display: 'block',
                                                margin: '15px 0 0.28571429rem 0',
                                                color: '#6f8cab',
                                                fontSize: '.92857143em',
                                                fontWeight: 500,
                                                textTransform: 'none',
                                            }}>Current Location:</label>
                                            <Header style={{ marginTop: 5 }} size='small'>{currentConsultant.location}</Header>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormSelect
                                                labelColor='#6f8cab'
                                                options={locations.map(l => {
                                                    return {
                                                        key: l.id,
                                                        value: l.id,
                                                        text: l.name
                                                    }
                                                })}
                                                loading={isLoadingLocation}
                                                placeholder='Select a Location'
                                                name='locationId'
                                                label='Move to Location:'
                                                onChange={(e: any, d: any) => {
                                                    const { value } = d;
                                                    setFieldValue('consultantId', "");
                                                    handleChangeLocation(value);
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label style={{
                                                display: 'block',
                                                margin: '15px 0 0.28571429rem 0',
                                                color: '#6f8cab',
                                                fontSize: '.92857143em',
                                                fontWeight: 500,
                                                textTransform: 'none',
                                            }}>Current Assignee:</label>
                                            <Header style={{ marginTop: 5 }} size='small'>{currentConsultant.consultantName}</Header>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormSelect
                                                labelColor='#6f8cab'
                                                options={consultant.consultants.map(title => {
                                                    return {
                                                        key: title.id,
                                                        value: title.id,
                                                        text: title.name
                                                    }
                                                })}
                                                loading={consultant.consultantsLoading}
                                                placeholder='Select a Consultant'
                                                name='consultantId'
                                                label='Assign To:'
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button form='moveClientForm' style={{ borderRadius: 30 }} content='Submit' primary type='submit' size='medium' />
                <Button style={{ borderRadius: 30 }} onClick={() => setOpen(false)} basic>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default observer(MoveClientModal);
