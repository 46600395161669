import { useField } from 'formik';
import { Checkbox, Form } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    buttons?: Array<{
        label: string,
        name: string,
        value: any,
        checked: boolean,
        disabled?: boolean
    }>;
    label?: string;
    loading?: boolean;
    onChange?: any
    disabled?: boolean;
    grouped?: boolean;
    preventDefault?: boolean;
    yesOrNo?: boolean;
}

export default function RadioButtons(props: Props) {
    const [field, meta, helpers] = useField(props.name);

    const yesNoButtons = [
        {
            label: 'Yes',
            name: 'Yes',
            value: true,
            checked: false,
            disabled: false
        },
        {
            label: 'No',
            name: 'No',
            value: false,
            checked: false,
            disabled: false
        }
    ]

    const getButtons = () => { return props.yesOrNo ? yesNoButtons : props.buttons }

    return (
        <Form.Group grouped={props.grouped} style={{ textAlign: 'left', marginTop: 12 }}>
            <label>
                {props.label}
            </label>
            {getButtons()?.map(option => {
                return (
                    <Form.Field error={meta.touched && !!meta.error}>
                        <Checkbox
                            radio
                            disabled={option.disabled || props.disabled}
                            label={option.label}
                            name={option.name}
                            value={option.value}
                            checked={option.value === field.value}
                            onChange={(e, data) => {
                                if (!props.preventDefault)
                                    helpers.setValue(data.value);

                                if (props.onChange) {
                                    props.onChange(e, data, helpers.setValue);
                                }
                            }}
                            style={{ marginLeft: 10 }}
                        />
                    </Form.Field>
                )
            })}
            {meta.touched && meta.error ? (
                <p style={{ marginLeft: 5, marginTop: 5, color: 'red' }}>{meta.error}</p>
            ) : null}
        </Form.Group>
    );
}
