interface Props {
    content: string;
    subContent?: string;
    subContentNextLine?: boolean;
    bold?: boolean;
    isMobile?: boolean;
}


export default function InveterviewLabel(props: Props) {
    return (
        <p style={{
            display: 'block',
            margin: '0.8rem 0 0.28571429rem 0',

            fontSize: '1.15em',
            fontWeight: props.bold? 700: 400,
            textTransform: 'none',
            float: !props.isMobile? 'right': 'left',
        }}>
            <span style={{
                color: '#4a4a4a',
            }}>
                {props.content}
            </span>
            {props.subContentNextLine? <br/> : null}
            {props.subContent ?
                <span style={{
                    color: '#b5b5b5',
                }}>
                    {props.subContent}
                </span>
                : null}
        </p>
    )
}