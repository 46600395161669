import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import PasswordInput from '../../app/common/form/PasswordInput';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/store';
import Version from '../version/Version';

const LoginForm = () => {
    const { authStore } = useStore();


    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={(values, { setErrors }) => authStore.login(values).catch(e => setErrors({ password: 'Invalid password', email: 'Invalid email' }))}
            validationSchema={Yup.object({
                email: Yup.string().required('Email field is required.').email('The Email is not a valid e-mail address.'),
                password: Yup.string().required('Password field is required.')
            })}
        >
            {
                ({ handleSubmit, isSubmitting }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: 436,
                            position: 'relative',
                            top: -20,
                            padding: '2.5rem 3em',
                            boxShadow: '0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%)',
                            borderRadius: 5,
                            backgroundColor: '#ffffff'
                        }}>
                            <div style={{ textAlign: 'center', marginBottom: 32 }}>
                                <Image src={`assets/taxcess-logo.svg`} style={{ width: '26em', marginBottom: 12 }} centered />
                            </div>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput name='email' placeholder='Email' />
                                <PasswordInput name='password' placeholder='Password' />
                                <Button loading={isSubmitting} color='blue' style={{ backgroundColor: '#15bdfe', borderRadius: '8px', marginTop: '1rem' }} fluid content='Login' type='submit' size='large' />
                                <div style={{ marginTop: 40, marginBottom: 10 }}>
                                    <Link style={{ color: '#b5c5d6' }} to={`/forgotpassword`}>Forgot your Password?</Link >
                                </div>
                                <Version style={{
                                    position: "absolute",
                                    bottom: "-35px",
                                    left: "-31px",
                                }} />
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default observer(LoginForm);