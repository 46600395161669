import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Grid } from 'semantic-ui-react';
import * as Yup from 'yup';
import FormSelect from '../../app/common/form/FormSelect';
import RadioButtons from '../../app/common/form/RadioButtons';
import TextInput from '../../app/common/form/TextInput';
import { intergerErrorMessage, thisFieldIsRequired } from '../../app/constants/message';
import LoadingComponet from '../../app/layout/LoadingComponent';
import { InterviewSecondPage } from '../../app/models/Agreement';
import { useStore } from '../../app/stores/store';
import InveterviewLabel from '../../app/styled-element/InterviewLabel';
import NoteList from './notes/NoteList';
import FooterButtons from './sub/FooterButtons';
import GridRow from './sub/GridRow';
import GridRowQuantity from './sub/GridRowQuantity';
import InterviewHeader from './sub/InterviewHeader';

const yupValidation = Yup.number().required(thisFieldIsRequired).integer(intergerErrorMessage).moreThan(-1, intergerErrorMessage);

const InterviewForm2 = () => {
    const history = useHistory();
    const { agreementStore } = useStore();
    const { get, loadingInitial, agreement, setAgreement, cancel, isLoading, save, setViewOnly, viewOnly } = agreementStore;
    const { id, view } = useParams<{ id: string, view: string | undefined }>();
    const [useBefore, setUseBefore] = useState(false);
    const [otherUseBefore, setOtherUseBefore] = useState(false);

    const handleBack = (value: InterviewSecondPage) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewSecondPage: { ...value, numberOfAnnualTaxReturn: value.annualTaxReturnYears.split(',').length } } });
            history.push(`/agreements/interview/1/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleNext = (value: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewSecondPage: { ...value, numberOfAnnualTaxReturn: value.annualTaxReturnYears.split(',').length } } });
            history.push(`/agreements/interview/3/${id}${viewOnly ? "/view" : ""}`)
        }
    }

    const handleSave = (value: any, validateForm: any) => {
        if (agreement) {
            setAgreement({ ...agreement, interview: { ...agreement.interview, interviewSecondPage: { ...value, numberOfAnnualTaxReturn: value.annualTaxReturnYears.split(',').length } } });
            save(id);
        }
    }

    useEffect(() => {
        setViewOnly(view ? true : false);
        get(id).then((a) => {
            setUseBefore(!a?.interview.interviewSecondPage.usedServiceBefore!);
            setOtherUseBefore(!a?.interview.interviewSecondPage.usedServiceBefore!
                && a?.interview.interviewSecondPage.firstHearAboutUs ? a?.interview.interviewSecondPage.firstHearAboutUs.includes('Optional specify') : false);
        });
    }, [id]);

    if (loadingInitial) return <LoadingComponet content='Loading...' />

    return (
        <Grid stackable className='gridForm'>
            <Grid.Row>
                <LoadingComponet active={isLoading} content='Please wait...' />
                <Grid.Column width={12}>
                    <Card fluid>
                        <InterviewHeader />
                        <Card.Content>
                            <Formik
                                enableReinitialize
                                initialValues={agreement?.interview.interviewSecondPage!}
                                onSubmit={(values, { setErrors }) => handleNext(values)
                                }
                                validationSchema={Yup.object({
                                    annualTaxReturnYears: Yup.string().required(thisFieldIsRequired),
                                    occupationTypeOfBusiness: !viewOnly ? Yup.string().required(thisFieldIsRequired).nullable() : Yup.string(),
                                    taxReturnBaseFee: yupValidation,
                                    overPayg: yupValidation,
                                    investmentRentalProperty: yupValidation,
                                    cgtSoldPropertySinceLastTaxReturn: yupValidation,
                                    cgtSoldSharesSinceLastTaxReturn: yupValidation,
                                    foreignIncome: yupValidation,
                                    pre2014Returns: yupValidation,
                                    travelAllowances: yupValidation,
                                    selfEducation: yupValidation,
                                    otme: yupValidation,
                                    logbookSetup: yupValidation,
                                    otherTaxServices: yupValidation,
                                    investmentRentalPropertyQuantity: Yup.number().required(thisFieldIsRequired).moreThan(-1, intergerErrorMessage),
                                    cgtSoldPropertySinceLastTaxReturnQuantity: Yup.number().required(thisFieldIsRequired).moreThan(-1, intergerErrorMessage),
                                    cgtSoldSharesSinceLastTaxReturnQuantity: Yup.number().required(thisFieldIsRequired).moreThan(-1, intergerErrorMessage),
                                    pre2014ReturnsQuantity: Yup.number().required(thisFieldIsRequired).moreThan(-1, intergerErrorMessage),
                                })}
                            >
                                {
                                    ({ handleSubmit, submitForm, values, setFieldValue, validateForm }) => (
                                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                            <Grid >
                                                <GridRow firstColumn='Annual Tax Return Years' secondColumn={<TextInput disabled={viewOnly} name='annualTaxReturnYears' placeholder='Annual Tax Return Years' type='text' />} />
                                                <GridRow firstColumn='Have you used our service before?'
                                                    secondColumn={<RadioButtons disabled={viewOnly} yesOrNo placeholder='Have you used our service before?' name='usedServiceBefore'
                                                        onChange={(e: any, { value }: any) => {
                                                            setUseBefore(!value);
                                                            setFieldValue('firstHearAboutUs', '');
                                                            setFieldValue('otherFirstHearAboutUs', '');
                                                            if (value) setOtherUseBefore(!value);
                                                        }}
                                                    />} />
                                                <GridRow style={{ display: useBefore ? 'block' : 'none' }}
                                                    firstColumn='How did you first hear about us?'
                                                    secondColumn={
                                                        <FormSelect
                                                            disabled={viewOnly}
                                                            placeholder='How did you first hear about us?'
                                                            clearable
                                                            options={[
                                                                {
                                                                    key: 'Walked Past',
                                                                    value: 'Walked Past',
                                                                    text: 'Walked Past'
                                                                },
                                                                {
                                                                    key: 'Google Search',
                                                                    value: 'Google Search',
                                                                    text: 'Google Search'
                                                                },
                                                                {
                                                                    key: 'Facebook',
                                                                    value: 'Facebook',
                                                                    text: 'Facebook'
                                                                },
                                                                {
                                                                    key: 'Online Advertising',
                                                                    value: 'Online Advertising',
                                                                    text: 'Online Advertising'
                                                                },
                                                                {
                                                                    key: 'Friend or Family(Optional specify)',
                                                                    value: 'Friend or Family(Optional specify)',
                                                                    text: 'Friend or Family(Optional specify)'
                                                                },
                                                                {
                                                                    key: 'Another business (Optional specify)',
                                                                    value: 'Another business (Optional specify)',
                                                                    text: 'Another business (Optional specify)'
                                                                },
                                                                {
                                                                    key: 'Others(specify)',
                                                                    value: 'Another business (Optional specify)',
                                                                    text: 'Others(specify)'
                                                                },
                                                            ]}
                                                            name='firstHearAboutUs'
                                                            onChange={(e: any, d: any) => {
                                                                const { value } = d;
                                                                let v = String(value);
                                                                setOtherUseBefore(v.includes('Optional specify'));
                                                            }}
                                                        />} />
                                                <GridRow style={{ display: otherUseBefore ? 'block' : 'none' }} firstColumn='Other' secondColumn={<TextInput disabled={viewOnly} name='otherFirstHearAboutUs' placeholder='Type' type='text' />} />
                                                <GridRow firstColumn={<InveterviewLabel bold content='Individuals' />} />
                                                <GridRow firstColumn='Occupation / Type of Business' secondColumn={<TextInput disabled={viewOnly} name='occupationTypeOfBusiness' placeholder='Type' type='text' />} />
                                                <GridRow firstColumn='Tax Return Base Fee' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='taxReturnBaseFee' placeholder='Tax Return Base Fee' type='number' />} />
                                                <GridRow firstColumn='3 or over PAYG' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='overPayg' placeholder='3 or over PAYG (per summary)' type='number' />} />
                                                <GridRowQuantity firstColumn='Investment rental property'
                                                    secondColumn={<TextInput disabled={viewOnly} name='investmentRentalPropertyQuantity' type='number' />}
                                                    thirdColumn={<TextInput disabled={viewOnly} icon='dollar' name='investmentRentalProperty' placeholder='Investment rental property' type='number' />} />
                                                <GridRowQuantity firstColumn={<InveterviewLabel content='CGT: Sold property since last tax return' />}
                                                    secondColumn={<TextInput disabled={viewOnly} name='cgtSoldPropertySinceLastTaxReturnQuantity' type='number' />}
                                                    thirdColumn={<TextInput disabled={viewOnly} icon='dollar' name='cgtSoldPropertySinceLastTaxReturn' placeholder='CGT: Sold property since last tax return' type='number' />} />
                                                <GridRowQuantity firstColumn='CGT: Sold shares since last tax return'
                                                    secondColumn={<TextInput disabled={viewOnly} name='cgtSoldSharesSinceLastTaxReturnQuantity' type='number' />}
                                                    thirdColumn={<TextInput disabled={viewOnly} icon='dollar' name='cgtSoldSharesSinceLastTaxReturn' placeholder='CGT: Sold property since last tax return' type='number' />} />
                                                <GridRow firstColumn='Foreign income' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='foreignIncome' placeholder='Foreign income' type='number' />} />
                                                <GridRowQuantity firstColumn='Pre 2014 Returns'
                                                    secondColumn={<TextInput disabled={viewOnly} name='pre2014ReturnsQuantity' type='number' />}
                                                    thirdColumn={<TextInput disabled={viewOnly} icon='dollar' name='pre2014Returns' placeholder='Pre 2014 Returns' type='number' />} />
                                                <GridRow firstColumn='Travel Allowances' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='travelAllowances' placeholder='Travel Allowances' type='number' />} />
                                                <GridRow firstColumn='Self Education' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='selfEducation' placeholder='Self Education' type='number' />} />
                                                <GridRow firstColumn='OTME' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='otme' placeholder='OTME' type='number' />} />
                                                <GridRow firstColumn='Logbook Setup' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='logbookSetup' placeholder='Logbook Setup' type='number' />} />
                                                <GridRow firstColumn='Other Tax Services' secondColumn={<TextInput disabled={viewOnly} icon='dollar' name='otherTaxServices' placeholder='Tax Services' type='number' />}
                                                    thirdColumn={<TextInput disabled={viewOnly} name='otherTaxServicesType' placeholder='Description' type='text' />} />
                                            </Grid>
                                            <FooterButtons
                                                onClickBack={() => handleBack(values)}
                                                onClickCancel={() => cancel(id)}
                                                onClickSave={() => handleSave(values, validateForm)}
                                                onClickNext={submitForm} />
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={4}>
                    <NoteList />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default observer(InterviewForm2);