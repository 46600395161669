import { useState } from "react";
import { Segment } from "semantic-ui-react";
import VideoModal from "./VideoModal";

const slides = [
    {
        title: "Taxcess User Manual",
        url: "https://drive.google.com/file/d/1SAVL0wuaoB529qnULB19R8EyHcvQd9up/preview"
    }
]

const Slides = () => {
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const handleClick = (url: string) => {
        setUrl(url)
        setOpen(true);
    }
    return (
        <>
            <VideoModal open={open} src={url} onClose={() => setOpen(false)} />
            <div className='slides'>
                {slides.map(slide => {
                    return (
                        <div onClick={() => handleClick(slide.url)} className='slide'>
                            <Segment className='slide-title-container'>
                                <p className='slide-title'>{slide.title}</p>
                            </Segment>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Slides;