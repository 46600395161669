import { observer } from "mobx-react-lite";
import { useIdleTimer } from "react-idle-timer";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Header } from "semantic-ui-react";
import styled from 'styled-components';
import * as Role from "../../app/constants/role";
import NavBar from "../common/navbar/NavBar";
import SidebarMenu from "../common/sidebar-menu/SideBarMenu";
import { useStore } from "../stores/store";

//#region "Styling"
const StyledAppWrapper = styled.div<{ isCollapse: boolean }>`
        height: '100%';
        overflow-y: auto;
        position: absolute;
        top: 50px;
        left: 190px;
        bottom: 0;
        right: 0;
        padding: ${p => p.isCollapse ? 0 : '2rem 1rem'};
        background-color: #edf7ff !important;
        border-bottom-right-radius: 20px;
        transition: all 0.5s ease;

        & .item {
            background: #fff;
            margin-bottom: 10px;
            padding: 15px;
            font-size: 14px;
            line-height: 22px;
        }

          ${p => (p.isCollapse && `
                left: 0px;
                top: 60px;
            `)};
`;

//#endregion "Styling"


interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    permission?: string;
}

function PrivateRoute({ component: Component, exact, permission, ...rest }: Props) {
    const { authStore: { isLoggedIn, user, hasPermission, auth, defaultUserPath, logout }, commonStore: { isMobile } } = useStore();

    useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: () => {
            toast.error('Session has expired. Please log in again.');
            logout();
        }
    });

    return (
        <Route
            {...rest}
            exact={exact ? exact : false}
            render={(props) =>
                (hasPermission(permission) && isLoggedIn) || (auth?.role === Role.Admin && isLoggedIn) ? (
                    <>
                        <NavBar />
                        <div style={{ maxWidth: 1575, position: 'relative', margin: 'auto', height: '100%' }}>
                            <SidebarMenu />
                            <StyledAppWrapper isCollapse={isMobile}>
                                <Container
                                    style={{ marginTop: '1em', }}
                                    fluid>
                                    <Header style={{ marginTop: '1em', marginLeft: '2em', fontSize: 20, fontWeight: 400 }} as='h4'>Welcome {user?.displayName}</Header>
                                    <Component {...props} />
                                </Container>
                            </StyledAppWrapper>
                        </div>
                    </>
                ) : isLoggedIn ? <Redirect to={defaultUserPath} /> : <Redirect to='/login' />}
        />
    )
}

export default observer(PrivateRoute);
