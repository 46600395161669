import { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import DownloadButton from './DownloadButton';
import Faq from './Faq';
import './help.css';
import Slides from './Slides';
import VideoCarousel from './VideoCarousel';

const StyledContainer = styled.div`
        padding: 1em 2em;
`;

const intialFaqs = [
    {
        id: 1,
        question: 'Popular Article',
        answer: 'Taxcess',
        expanded: false
    },
    {
        id: 2,
        question: 'Popular Article 2',
        answer: 'Taxcess 2',
        expanded: false
    },
    {
        id: 3,
        question: 'Popular Article 3',
        answer: 'Taxcess 3',
        expanded: false
    }
];

const Help = () => {
    const [faqs, setFaqs] = useState(intialFaqs);

    const handleClick = (faq: any) => {
        setFaqs(prev => {
            return prev.map(f => f.id == faq.id ? { ...f, expanded: !f.expanded } : f);
        });
    }



    return (
        <StyledContainer>
            <Segment className='help-container' piled>
                {/*<div>*/}
                {/*    <h1 className='title'>What can we help you with today?</h1>*/}
                {/*    <DownloadButton />*/}
                {/*</div>*/}
                {/*{faqs.map((f, index) => <Faq values={f} handleClick={handleClick} />)}*/}
                <h1 className='video-title'>Documents to help with the basics</h1>
                <Slides />
                <h1 className='video-title'>Videos to help with the basics</h1>
                <VideoCarousel />
            </Segment>
        </StyledContainer>
    );
}

export default Help;