import { useStore } from "../../../../app/stores/store";
import Container from "../Container";
import ContractPage from "../ContractPage";
import './../../../../app/styles/scss/contractsV2/invoice/invoice.css';

const Invoice = () => {
    const { agreementStore, commonStore: { dateNow }, lookupStore: { paymentOptions, loadPaymentOptions } } = useStore();
    const { agreement } = agreementStore;


    const round = (value: number, decimals: number) => {
        return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
    }

    let gst = round((agreement?.interview.interviewFourthPage.discountedFees! / 11), 2);

    const prices = [
        { desc: 'Sole trader returns (non GST)', price: agreement?.interview.interviewFirstPage.soleTraderReturnsNonGST },
        { desc: 'Sole trader returns (GST)', price: agreement?.interview.interviewFirstPage.soleTraderReturnsGST },
        { desc: 'BAS prepare and lodgement', price: agreement?.interview.interviewFirstPage.basPrepareAndLodgementPerQuarter },
        { desc: 'Company / trust returns', price: agreement?.interview.interviewFirstPage.companyTrustReturnsRateCard },
        { desc: 'Other tax services', price: agreement?.interview.interviewSecondPage.otherTaxServices },
        { desc: 'Preparation of Tax Return for Y/E 30 June', qty: agreement?.interview.interviewSecondPage.annualTaxReturnYears.split(',').length, price: agreement?.interview.interviewSecondPage.taxReturnBaseFee },
        { desc: '3 or over PAYG', price: agreement?.interview.interviewSecondPage.overPayg },
        { desc: 'Investment rental property', qty: agreement?.interview.interviewSecondPage.investmentRentalPropertyQuantity, price: agreement?.interview.interviewSecondPage.investmentRentalProperty },
        { desc: 'CGT: Sold property since last tax return', qty: agreement?.interview.interviewSecondPage.cgtSoldPropertySinceLastTaxReturnQuantity, price: agreement?.interview.interviewSecondPage.cgtSoldPropertySinceLastTaxReturn },
        { desc: 'CGT: Sold shares since last tax return', qty: agreement?.interview.interviewSecondPage.cgtSoldSharesSinceLastTaxReturnQuantity, price: agreement?.interview.interviewSecondPage.cgtSoldSharesSinceLastTaxReturn },
        { desc: 'Foreign income', price: agreement?.interview.interviewSecondPage.foreignIncome },
        { desc: 'Pre 2014 Returns', qty: agreement?.interview.interviewSecondPage.pre2014ReturnsQuantity, price: agreement?.interview.interviewSecondPage.pre2014Returns },
        { desc: 'Travel Allowances', price: agreement?.interview.interviewSecondPage.travelAllowances },
        { desc: 'Self Education', price: agreement?.interview.interviewSecondPage.selfEducation },
        { desc: 'OTME', price: agreement?.interview.interviewSecondPage.otme },
        { desc: 'Logbook Setup', price: agreement?.interview.interviewSecondPage.logbookSetup },
        { desc: 'Fee from Refund charge', price: agreement?.interview.interviewThirdPage.feeFromRefundCharge },
        { desc: 'Refund Advance charge', price: agreement?.interview.interviewThirdPage.refundAdvanceCharge },
    ];

    return (
        <div style={{ position: 'absolute', left: -1500 }}>
            <iframe id='iframe-pdf' width='100%' height='100%'><p>asd</p></iframe>
            <Container div id='invoice-pdf' className="invoice">
                <ContractPage firstPage>
                    <div className="header-title">Tax Invoice</div>
                    <div className="information">
                        <div className="col">
                            <div className="name">{agreement?.client.fullName.toLocaleUpperCase()}</div>
                            <div className="infos">
                                <div className="other-info">{agreement?.client.location?.name} {agreement?.client.postalCode}</div>
                                <div className="other-info">{agreement?.interview.interviewFirstPage.entityName}</div>
                                <div className="other-info">{agreement?.interview.interviewFirstPage.abn}</div>
                                <div className="other-info">{agreement?.client.email}</div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="infos">
                                <div className="other-info highlight">Invoice Date</div>
                                <div className="other-info mb-5">{dateNow}</div>
                                <div className="other-info highlight">Invoice Number</div>
                                <div className="other-info">{agreement?.interview.interviewFourthPage.invoiceNumber}</div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="infos">
                                <div className="other-info highlight">ABN</div>
                                <div className="other-info mb-5">16 155 811 448</div>
                                <div className="other-info highlight">Tax Agent</div>
                                <div className="other-info">2243 3005</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="infos">
                                <div className="other-info highlight">Accountants Direct Pty Ltd.</div>
                                <div className="other-info">Phone: 1300 829 746</div>
                                <div className="other-info">Email: accounts@accountantsdirect.com.au</div>
                                <div className="other-info">Website: wwww.accountantsdirect.com.au</div>
                            </div>
                        </div>
                    </div>
                    <div className="prices-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className="th-desc text-left">
                                        Description
                                    </th>
                                    <th className="th-qty text-center">
                                        Quantity
                                    </th>
                                    <th className="th-price text-right">
                                        Unit Price
                                    </th>
                                    <th className="th-amount text-right">
                                        Amount AUD
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {prices.filter(p => p.price).map((p, i, row) => {
                                    let unitPrice = '0.00', aud = '0.00';

                                    if (p.price) {
                                        unitPrice = p.qty === undefined ? p.price.toFixed(2) : (p.qty * p.price).toFixed(2);
                                        aud = round(p.price, 2).toFixed(2).toString();
                                    }

                                    return (
                                        <tr className={i + 1 === row.length ? "last" : ""}>
                                            <td className="text-left">{p.desc}</td>
                                            <td className="text-center">{p.qty}</td>
                                            <td className="text-right">${aud}</td>
                                            <td className="text-right">${unitPrice}</td>
                                        </tr>
                                    )
                                })}
                                <tr className="subtotal bold">
                                    <td className="text-left">Total items: {agreement?.interview.interviewSecondPage.annualTaxReturnYears.split(',').length}</td>
                                    <td colSpan={2} className="text-right">Subtotal</td>
                                    <td className="text-right">${agreement?.interview.interviewThirdPage.quote.toFixed(2)}</td>
                                </tr>
                                <tr className="gst">
                                    <td></td>
                                    <td colSpan={2} className="text-right">
                                        <span>GST</span>
                                    </td>
                                    <td className="text-right">${gst}</td>
                                </tr>
                                <tr className="method">
                                    <td className="text-left">Payment Method: {paymentOptions.find(p => p.id === agreement?.interview.interviewThirdPage.paymentOptionId)?.name}</td>
                                    <td colSpan={2} className="text-right">
                                        <span>Discount</span>
                                    </td>
                                    <td className="text-right">
                                        <span>${agreement?.interview.interviewThirdPage.discount.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr className="total">
                                    <td className="text-left thank-you" colSpan={2}>Thank you for your business!</td>
                                    <td colSpan={2} className="text-center">
                                        <div className='total-value'>Total <span>${agreement?.interview.interviewFourthPage.discountedFees!.toFixed(2)}</span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ContractPage>
            </Container>
        </div>
    );
}


export default Invoice;