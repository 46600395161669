import { observer } from "mobx-react-lite";
import ComponentBox from "../../app/layout/ComponentBox"
import { Card } from "semantic-ui-react";
import { FaWalking } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { StyledContainer } from "../../app/styled-element/StyledContainer";

const RegistrationOptions = () => {
    const history = useHistory();

    return (
        <StyledContainer>
            <ComponentBox
                header="Registration"
                component={() =>
                    <Card.Group itemsPerRow={4}>
                        <Card
                            onClick={() => {
                                history.push(`/registrations/inperson`);
                            }}
                            style={{
                                padding: '3rem 2.25rem',
                                width: '11rem',
                                textAlign: 'center',
                                color: '#4a4a4a'
                            }}
                            content={
                                <>
                                    <FaWalking style={{ fontSize: 30, margin: 'auto' }} />
                                    <p>In Person</p>
                                </>

                            }
                        />
                        <Card
                            onClick={() => {
                                history.push(`/registrations/overphonevideo`);
                            }}
                            style={{
                                padding: '3rem 2.25rem',
                                width: '11rem',
                                textAlign: 'center',
                                color: '#4a4a4a'
                            }}
                            content={
                                <>
                                    <AiFillPhone style={{ fontSize: 30, margin: 'auto' }} />
                                    <p>Phone/Video</p>
                                </>
                            }
                        />
                    </Card.Group>
                }
            />
        </StyledContainer>

    )
};

export default observer(RegistrationOptions);
