import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { MasterList } from '../models/masterlist';
import { Filters } from '../models/filter';
import DateHelper from '../helpers/dateHelper';
let dateHelper = new DateHelper()

export default class MasterListStore {
    isLoading = false;
    masterList: MasterList[] = [];
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "", startDate: undefined, endDate: undefined };
    total: number = 0;
    loadingInitial = false;
    downloadLoading = false

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    filterList = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, masterLists } = await agent.MasterLists.filter(this.filterValues);

            runInAction(() => {
                this.masterList = masterLists;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterList();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterList();
    }

    setFilterValues = (query: Filters) => {
        this.filterValues = query;
        this.filterList();
    };

    download = async () => {
        try {
            this.downloadLoading = true;
            let startDate, endDate;

            if (this.filterValues.startDate && this.filterValues.endDate) {
                startDate = dateHelper.formatShortDate(this.filterValues.startDate.toDateString())
                endDate = dateHelper.formatShortDate(this.filterValues.endDate.toDateString())
            }

            let blob = await agent.MasterLists.download(startDate, endDate);
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'Master List';
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                this.downloadLoading = false;
            }, 0)
        } catch (e) {
            runInAction(() => {
                this.downloadLoading = false;
            })
            return Promise.reject(e);
        }

    }
}