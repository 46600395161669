import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { ClientContract } from '../models/client';
import { Filters } from '../models/filter';

export default class ContractStore {
    isLoading = false;
    contracts: ClientContract[] = [];
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    total: number = 0;
    loadingInitial = false;
    clientId = "";

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    setClientId = (id: string) => {
        this.clientId = id;
    }

    filterContracts = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, agreements } = await agent.Clients.filterContracts(this.clientId, {
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.contracts = agreements;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterContracts();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterContracts();
    }

    setFilterValues = (query: Filters) => {
        this.filterValues = query;
        this.filterContracts();
    };
}