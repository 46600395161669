import { Form, Formik } from "formik";
import { Button, FormSelect, Grid, Modal } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextArea from "../../app/common/form/TextArea";

const CommentModal = (props) => {
    return (
        <Modal
            size="small"
            open={props.open}
            onClose={() => console.log("on modal close")}
        >
            <Modal.Header>Comment</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={{ comment: props.data.comment }}
                    onSubmit={(value) => {
                        props.onClickSubmit({
                            ...props.data,
                            comment: value.comment
                        });
                    }}
                    validationSchema={Yup.object({
                        comment: Yup.string().required('Field is required.'),
                    })}
                >
                    {
                        ({ handleSubmit }) => (
                            <Form id='moveClientForm' className='ui form' onSubmit={handleSubmit} autoComplete='off' style={{ marginTop: 0 }}>
                                <MyTextArea
                                    placeholder=""
                                    name="comment"
                                    rows={4}
                                    maxLength={128}
                                />
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Content>
            <Modal.Actions>
                <Button form='moveClientForm' style={{ borderRadius: 30 }} content='Submit' primary type='submit' size='medium' />
                <Button style={{ borderRadius: 30 }} onClick={props.onClickCancel} basic>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CommentModal;