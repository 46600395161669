import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Permission from '../../app/constants/permission';
import AgreementList from '../../features/agreement/AgreementList';
import BankInformationForm from '../../features/agreement/BankInformationForm';
import Completed from '../../features/agreement/Completed';
import Declined from '../../features/agreement/Declined';
import InterviewForm1 from '../../features/agreement/InterviewForm1';
import InterviewForm2 from '../../features/agreement/InterviewForm2';
import InterviewForm3 from '../../features/agreement/InterviewForm3';
import InterviewForm4 from '../../features/agreement/InterviewForm4';
import ForgotPasswordForm from '../../features/auth/ForgotPasswordForm';
import LoginForm from '../../features/auth/LoginForm';
import ResetPasswordForm from '../../features/auth/ResetPasswordForm';
import ClientContractList from '../../features/client/ClientContractList';
import ClientList from '../../features/client/ClientList';
import NotFound from '../../features/errors/NotFound';
import Help from '../../features/help/Help';
import LocationList from '../../features/location/LocationList';
import MasterList from '../../features/masterlist/MasterList';
import InPersonForm from '../../features/registration/InPersonForm';
import RegistrationOptions from '../../features/registration/RegistrationOptions';
import RoleForm from '../../features/role/RoleForm';
import RoleList from '../../features/role/RoleList';
import AddUserForm from '../../features/users/AddUserForm';
import ChangePasswordForm from '../../features/users/ChangePasswordForm';
import EditUserForm from '../../features/users/EditUserForm';
import UserList from '../../features/users/UserList';
import LoadingComponent from '../layout/LoadingComponent';
import { useStore } from '../stores/store';
import PrivateRoute from './PrivateRoute';
import WhatsNew from '../../features/version/WhatsNew';
import ReviewList from '../../features/reviewlist/ReviewList';

function App() {
    const { commonStore, authStore } = useStore();
    const location = useLocation();

    useEffect(() => {
        if (commonStore.screenWidth <= 900) {
            commonStore.setIsCollapseSideBar(true);
            commonStore.setIsMobile(true);
        }
        else if (commonStore.screenWidth <= 767) {
            commonStore.setIsGridStack(true);
        }

        window.matchMedia("(min-width: 900px)").addEventListener('change', (e) => {
            commonStore.setIsCollapseSideBar(!e.matches);
            commonStore.setIsMobile(!e.matches);
        });

        window.matchMedia("(min-width: 767px)").addEventListener('change', (e) => {
            commonStore.setIsGridStack(!e.matches);
        });

        if (authStore.token) {
            authStore.getCurrentUser().then(() => {
                commonStore.setAppLoaded();
            }).catch(() => { });
        } else {
            commonStore.setAppLoaded();
        }
    }, []);


    return (
        <>
            <ToastContainer position='top-right' hideProgressBar theme='colored' />
            {!commonStore.appLoaded ?
                <LoadingComponent content='Loading Page...' /> :
                <Switch>
                    <PrivateRoute exact={true} path='/' component={() => <Redirect to={authStore.defaultUserPath} />} />

                    <PrivateRoute exact={true} path='/clients' component={ClientList} permission={Permission.Clients} />
                    <PrivateRoute exact={true} path='/clients/:id/contracts' component={ClientContractList} permission={Permission.Clients} />

                    <PrivateRoute exact={true} path='/agreements' component={AgreementList} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/BankInformation/:id' component={BankInformationForm} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/1/:id' component={InterviewForm1} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/2/:id' component={InterviewForm2} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/3/:id' component={InterviewForm3} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/4/:id' component={InterviewForm4} permission={Permission.Agreements} />

                    <PrivateRoute exact={true} path='/agreements/interview/BankInformation/:id/:view' component={BankInformationForm} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/1/:id/:view' component={InterviewForm1} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/2/:id/:view' component={InterviewForm2} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/3/:id/:view' component={InterviewForm3} permission={Permission.Agreements} />
                    <PrivateRoute exact={true} path='/agreements/interview/4/:id/:view' component={InterviewForm4} permission={Permission.Agreements} />
                    <Route path='/agreements/completed' component={Completed} />
                    <Route path='/agreements/declined' component={Declined} />


                    <PrivateRoute exact={true} path='/registrations' component={() => <Redirect to="/registrations/options" />} permission={Permission.Registration} />
                    <PrivateRoute exact={true} path='/registrations/options' component={RegistrationOptions} permission={Permission.Registration} />
                    <PrivateRoute exact={true} path='/registrations/inperson' component={InPersonForm} permission={Permission.Registration} />
                    <PrivateRoute exact={true} path='/registrations/overphonevideo' component={InPersonForm} permission={Permission.Registration} />
                    <PrivateRoute exact={true} path='/clients/edit/:id' component={InPersonForm} permission={Permission.Registration} />

                    <PrivateRoute exact={true} path='/roles' component={RoleList} />
                    <PrivateRoute exact={true} key={location.key} path='/roles/create' component={RoleForm} />
                    <PrivateRoute exact={true} key={location.key} path={'/roles/edit/:id'} component={RoleForm} />

                    <PrivateRoute exact={true} path='/masterlist' component={MasterList} permission={Permission.MasterList} />
                    <PrivateRoute exact={true} path='/reviewlist' component={ReviewList} permission={Permission.ReviewerList} />
                    <PrivateRoute exact={true} path='/settings' component={LocationList} />


                    <PrivateRoute exact={true} path='/users' component={UserList} permission={Permission.User} />
                    <PrivateRoute exact={true} key={location.key} path='/users/create' component={AddUserForm} permission={Permission.User} />
                    <PrivateRoute exact={true} key={location.key} path={'/users/edit/:id'} component={EditUserForm} permission={Permission.User} />
                    <PrivateRoute exact={true} key={location.key} path={'/changepassword'} component={ChangePasswordForm} permission={Permission.Clients} />

                    <PrivateRoute exact={true} path='/helps' component={Help} permission={Permission.Help} />

                    <Route path='/whats-new' component={WhatsNew} />
                    <Route path='/login' component={authStore.isLoggedIn ? () => <Redirect to={authStore.defaultUserPath} /> : LoginForm} />
                    <Route path='/forgotpassword' component={authStore.isLoggedIn ? () => <Redirect to={authStore.defaultUserPath} /> : ForgotPasswordForm} />
                    <Route path='/resetpassword/:id' component={authStore.isLoggedIn ? () => <Redirect to={authStore.defaultUserPath} /> : ResetPasswordForm} />
                    <Route component={NotFound} />
                </Switch>
            }
        </>
    );
}

export default observer(App);

