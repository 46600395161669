/* eslint-disable jsx-a11y/alt-text */
import Page from "./Page"
import '../../../app/styles/scss/contractsV2/payou-page.css';
import headerImg from "../../../app/common/contract/Header image.png";
import footerImg from "../../../app/common/contract/Footer Direct debit.png";
import logo from "../../../app/common/contract/Payou logo.png";

const PayouPage = (props) => {
    return (
        <Page className={`payou-page ${props.firstPage ? "" : "long-page"}`}>
            <img className="header-image" src={headerImg} />
            <img className="logo" src={logo} />
            <div className="content">
                {props.children}
            </div>
            <img className="footer-image" src={footerImg} />
            <div className="footer">
                Payou Pty Ltd (ABN 91 651 634 650). The First Automated Tax Refund Processing Platform <br />
                W: payou.com.au   E: info@payou.com.au
            </div>
        </Page>
    );
}

export default PayouPage;