import { Button, Card, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Status } from '../../../app/constants/agreementStatus';
import * as Role from '../../../app/constants/role';

interface Property {
    onClickBack?: any;
    onClickNext?: any;
    onClickSave?: any;
    onClickCancel?: any;
    onClickESign?: any;
    onClickPrintInvoice?: any;
}

const FooterButtons = ({ onClickBack, onClickNext, onClickSave, onClickCancel, onClickESign, onClickPrintInvoice }: Property) => {
    const { agreementStore, authStore: { auth } } = useStore();
    const { agreement, printInvoiceLoading, viewOnly, delete: deleteAgreement } = agreementStore;
    let isForSign = agreement?.agreementStatus.name === Status.ForESign
        || agreement?.agreementStatus.name === Status.Esigned
        || agreement?.agreementStatus.name === Status.PaperSigned
        || viewOnly;


    return (
        <div style={{ marginTop: 30 }}>
            {onClickBack ? <div style={{ textAlign: 'left', float: 'left', width: 150, borderRadius: 30, height: 42, fontSize: 18, padding: '0.6em 1.5em' }}>
                <Icon color='blue' className='angle left icon' />
                <a onClick={onClickBack}>Back</a >
            </div> : null}
            {auth?.role !== Role.Admin && onClickESign ? <Button disabled={viewOnly} type='button' onClick={onClickESign} color='blue' style={{ width: 150, borderRadius: 30, height: 42 }} content='For E-sign' size='medium' floated='right' /> : null}
            {!isForSign && onClickPrintInvoice ? <Button type='button' onClick={onClickPrintInvoice} basic color='blue' style={{ width: 150, borderRadius: 30, height: 42 }} loading={printInvoiceLoading} content='Print Invoice' size='medium' floated='right' /> : null}
            {onClickNext ? <Button type='button' onClick={onClickNext} color='blue' style={{ width: 150, borderRadius: 30, height: 42 }} content='Next' size='medium' floated='right' /> : null}
            {auth?.role !== Role.Admin && !isForSign && onClickSave ? <Button type='button' onClick={onClickSave} basic color='blue' style={{ width: 150, borderRadius: 30, height: 42 }} content='Save' size='medium' floated='right' /> : null}
            {auth?.role !== Role.Admin && !isForSign && onClickCancel ? <Button type='button' onClick={onClickCancel} basic color='red' style={{ width: 150, borderRadius: 30, height: 42 }} content='Cancel Interview' size='medium' floated='right' /> : null}
            {auth?.role === Role.Admin ? <Button disabled={viewOnly} type='button' onClick={() => deleteAgreement(agreement?.id!)} basic color='red' style={{ width: 200, borderRadius: 30, height: 42 }} content='Delete Agreement' size='medium' floated='right' /> : null}
        </div>
    );
}

export default observer(FooterButtons);