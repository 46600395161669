import { useMediaQuery } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Grid, Header } from 'semantic-ui-react';
import { Status } from '../../../app/constants/agreementStatus';
import * as Role from '../../../app/constants/role';
import { useStore } from '../../../app/stores/store';

const InterviewHeader = () => {
    const isRowBased = useMediaQuery('(min-width: 1334px)');
    const { agreementStore, authStore: { auth }, lookupStore } = useStore();
    const { noShow, agreement, downloadInvoice, downloadAgreement, paperSign, viewOnly, downloadDirectDebit, downloadTermsAndConditions, originalPayment } = agreementStore;
    const { id } = useParams<{ id: string }>();
    const headerBottonsStyles = { borderRadius: 30, marginTop: isRowBased ? -6 : 5 };

    const downloadDocuments = () => {
        downloadAgreement();

        if ("Fee from refund" == originalPayment || "Refund Advance" == originalPayment) {
            downloadDirectDebit();
            downloadTermsAndConditions();
        }
    }

    let buttons, title = 'For Interview';
    if (agreement?.agreementStatus.name === Status.ForESign) title = 'For Esign';
    else if (agreement?.agreementStatus.name === Status.Esigned) title = 'For Review';


    if (agreement?.agreementStatus.name === Status.ForESign) {
        buttons =
            <div>
                <Button onClick={downloadInvoice} style={headerBottonsStyles} basic color='blue' floated='right'>Download Invoice</Button>
                {auth?.role !== Role.Admin && <Button disabled={viewOnly} onClick={() => paperSign(id)} style={headerBottonsStyles} basic color='blue' floated='right'>Paper Sign</Button>}
            </div>
    }
    else if (agreement?.agreementStatus.name === Status.Esigned || agreement?.agreementStatus.name === Status.ForIDVerification) {
        buttons = <div>
            <Button onClick={downloadInvoice} style={headerBottonsStyles} color='blue' floated='right'>Download Invoice</Button>
            <Button onClick={downloadDocuments} style={headerBottonsStyles} basic color='blue' floated='right'>Download Agreement</Button>
        </div>
    }
    else if (agreement?.agreementStatus.name === Status.PaperSigned) {
        buttons =
            <div>
                <Button onClick={downloadInvoice} style={headerBottonsStyles} color='blue' floated='right'>Download Invoice</Button>
                <Button disabled style={headerBottonsStyles} basic color='blue' floated='right'>Paper Signed</Button>
            </div>
    }
    else if (agreement?.agreementStatus.name === Status.NoShow || agreement?.agreementStatus.name === Status.Cancelled || auth?.role === Role.Admin) {
        buttons = '';
    }
    else {
        buttons = <Button onClick={() => noShow(id)} style={headerBottonsStyles} basic color='blue' floated='right'>No Show</Button>
    }

    const [maskTFN, setMaskTFN] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    useEffect(() => {
        if (agreement?.client.taxFileNumber) {
            let maskText = '';
            for (var i = 0; i < agreement?.client.taxFileNumber.length; i++) {
                if (i % 3 === 0) {
                    maskText += " ";
                }
                maskText += agreement?.client.taxFileNumber[i];
            }
            setMaskTFN(maskText);
        }

        if (agreement?.client.mobileNumber) {
            setMobileNumber(`${agreement?.client.mobileNumber.substring(0, 6)} ${agreement?.client.mobileNumber.substring(6, 9)} ${agreement?.client.mobileNumber.substring(9, 13)}`)
        }
    }, [agreement]);

    return (
        <>
            <Card.Content>
                <Grid>
                    <Grid.Row style={{ minHeight: 50, alignItems: 'center' }}>
                        <Grid.Column className='interviewHeaderTitle' width={4}>
                            <p style={{
                                color: '#0099da',
                                flexGrow: 1,
                                fontWeight: 700,
                                fontFamily: 'Muli,sans-serif',
                                fontSize: 18,
                                marginTop: -8,
                                float: 'left'
                            }}>{title}</p>
                        </Grid.Column>
                        <Grid.Column width={12}>{buttons}</Grid.Column>
                    </Grid.Row>

                </Grid>
            </Card.Content>
            <Card.Content className='cardInterviewDivided'>
                <Grid stackable>
                    <Grid.Row divided>
                        <Grid.Column textAlign='center' width={5}>
                            <Header as='h4'>Name: {agreement?.client.fullName}</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center' width={5}>
                            <Header as='h4'>Mobile Number: {mobileNumber}</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center' width={5}>
                            <Header as='h4'>Tax File Number: {maskTFN}</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </>
    );
}

export default observer(InterviewHeader);