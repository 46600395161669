import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { Client } from "../../app/models/client";
import { useStore } from "../../app/stores/store";
import PaginatedTable from "../../app/table/PaginatedTable";
import MoveClientModal from "../client/MoveClientModal";
import NewContractModal from "../client/NewContractModal";
import * as Role from '../../app/constants/role'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import agent from "../../app/api/agents";

const StyledContainer = styled.div<{ isMobile?: boolean }>`
        padding: ${p => p.isMobile ? 0 : '1em 2em'};
`;

const ClientList = () => {
    const swal = withReactContent(Swal);
    const { clientStore, authStore: { auth }, commonStore } = useStore();
    const {
        clients, total, setPageNumber, pageNumber, isLoading,
        setSearchQuery, filterClients, filterValues } = clientStore;

    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(false);
    const [newContractModalOpen, setNewContractModalOpen] = useState(false);
    const [moveClientId, setMoveClientId] = useState("");

    useEffect(() => {
        filterValues.pageNumber = 1;
        filterValues.searchKeyword = '';
        filterClients();
    }, []);

    const skipVerification = (data) => {
        swal.fire({
            html: `<strong>Proceed with bypassing ID verification?</strong>.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                agent.IdentityVerification.force(data.identityVerification.verificationId)
                    .then(() => {
                        filterClients();
                    });
            }
        });
    }

    const hasSkipVerification = clients.some(c => !c.identityVerification?.isVerified);

    return (
        <>
            <StyledContainer isMobile={commonStore.isMobile}>
                < PaginatedTable <Client>
                    title="Clients"
                    columns={
                        [
                            { title: 'id', field: 'id', hidden: true },
                            { title: 'Name', field: 'fullName' },
                            { title: 'Email Address', field: 'email' },
                            { title: 'Mobile Number', field: 'mobileNumber' },

                        ]}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Role',
                            onClick: async (event) => {
                            }
                        }),
                    ]}
                    total={total}
                    pageSize={10}
                    pageNumber={pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={
                        p => {
                            setPageNumber(p);
                        }}
                    onSearchChange={
                        query => {
                            setSearchQuery(query);

                        }}
                    data={clients}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    components={
                        {
                            Action: (props) => (
                                <>
                                    {(auth?.role === Role.Admin && props.data.identityVerification !== null && !props.data.identityVerification.isVerified) ?
                                        <Button
                                            className='table-action-button'
                                            onClick={
                                                e => {
                                                    const { data } = props;
                                                    skipVerification(data);
                                                }}
                                            size="small"
                                            basic
                                            color='blue'
                                            content="Skip Verification"
                                        /> : hasSkipVerification  && <div className='table-action-button'></div>
                                    }
                                    <Button
                                        className='table-action-button'
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                history.push(`clients/edit/${data.id}`)
                                            }}
                                        size="small"
                                        basic
                                        color='blue'
                                        content="Edit"
                                    />
                                    <Button
                                        className='table-action-button'
                                        onClick={
                                            e => {
                                                const { data } = props;
                                                setMoveClientId(data.id);
                                                setModalOpen(true);
                                            }}
                                        size="small"
                                        color='blue'
                                        content="Move Client"
                                    />
                                    {auth?.role !== Role.Admin ? <>
                                        <Button
                                            className='table-action-button'
                                            onClick={
                                                e => {
                                                    const { data } = props;
                                                    setMoveClientId(data.id);
                                                    setNewContractModalOpen(true);
                                                }}
                                            size="small"
                                            color='blue'
                                            content="New Contracts"
                                        />
                                        <Button
                                            className='table-action-button'
                                            onClick={
                                                e => {
                                                    const { data } = props;
                                                    history.push(`clients/${data.id}/contracts`);
                                                }}
                                            size="small"
                                            color='blue'
                                            content="All Contracts"
                                        />
                                    </> : null}
                                </>
                            ),
                        }
                    }
                />
            </ StyledContainer >
            <MoveClientModal clientId={moveClientId} open={modalOpen} setOpen={setModalOpen} />
            <NewContractModal clientId={moveClientId} open={newContractModalOpen} setOpen={setNewContractModalOpen} />
        </>
    )
}

export default observer(ClientList);
