import { Header, Icon, Segment, Image } from "semantic-ui-react";
import styled from "styled-components";

const StyledSegment = styled(Segment)`
    && {
            margin-top: 5vw !important;
            max-width: 500px !important;
            margin: auto;
            background-color: #fff !important;
            padding: 40px 60px !important;
        }
`;

const Completed = () => {
    return (
        <StyledSegment placeholder>
            <Image src={`/assets/accountantsdirect.png`} style={{ width: 200, height: 108 }} centered />
            <Header icon >
                <Icon style={{ marginTop: -25, marginBottom: 25}} name='check circle' color='green' />
                <span color='#2a2a2a'>
                    Thank you for eSigning your Accountants Direct tax agreements.
                    <br /><br />You will soon receive an email with these agreements attached for your records. 
                    <br /><br />New Phone Clients: We require you to complete identity verification prior to lodgement. A link with instructions has been sent to your email. 
                    <br /><br />This window is now safe to close.
                </span>

            </Header>
            <Segment.Inline>

            </Segment.Inline>
        </StyledSegment>
    );
}

export default Completed;