import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { Client, ClientContract, ClientForm, EditConsultantForm } from '../models/client';
import { Filters } from '../models/filter';

export default class ClientStore {
    isLoading = false;
    clients: Client[] = [];
    contracts: ClientContract[] = [];
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    total: number = 0;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    create = async (client: ClientForm) => {
        this.isLoading = true;
        client.mobileNumber = '+' + client.mobileNumber;
        client.firstName = client.firstName.trim();
        client.lastName = client.lastName.trim();

        try {
            let ids = await agent.Clients.create(client);
            this.isLoading = false;
            return ids;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    edit = async (client: ClientForm) => {
        this.isLoading = true;
        client.mobileNumber = '+' + client.mobileNumber;
        client.firstName = client.firstName.trim();
        client.lastName = client.lastName.trim();

        try {
            await agent.Clients.edit(client);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    editConsultant = async (client: EditConsultantForm) => {
        this.isLoading = true;

        try {
            await agent.Clients.editConsultants(client);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    }

    filterClients = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, clients } = await agent.Clients.filter({
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.clients = clients.map(c => {
                    return {
                        ...c,
                        fullName: c.fullName.replace(/\s+/g, ' ').trim()
                    };
                });
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    get = async (id: string) => {
        this.setLoadingInitial(true);
        let client = await agent.Clients.get(id);

        runInAction(() => {
            this.setLoadingInitial(false);
        });

        return client;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        runInAction(() => {
            this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
            this.filterClients();
        })
    }

    setSearchQuery = (query: string) => {
        runInAction(() => {
            this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
            this.filterClients();
        })
    }
}