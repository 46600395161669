export default class CookieHelper {
    get = (name: string) => {
        let cookies: any = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((accumulator, [key, value]) =>
                ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});

        return cookies[name];
    }

    set = (name: string, value: any) => document.cookie = `${name}=${value}; path=/`;

    delete = (name: string) => document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}