import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { Filters } from '../models/filter';
import { Note } from '../models/note';
import { store } from './store';

export default class NoteStore {
    isLoading = false;
    notes: Note[] = [];
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    total: number = 0;
    note: Note = {
        id: '',
        subject: '',
        message: '',
        updater: '',
        agreementId: ''
    };
    lastAgreementIdLoaded = "";

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    setLastAgreementIdLoaded = (id: string) => this.lastAgreementIdLoaded = id;

    create = async (note: Note) => {
        this.isLoading = true;


        try {
            let id = await agent.Notes.create(note);
            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    edit = async (note: Note) => {
        this.isLoading = true;

        try {
            let id = await agent.Notes.edit(note);

            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    delete = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.Notes.delete(id);
            this.isLoading = false;
            return id;
        } catch (e: any) {
            this.isLoading = false;
            return Promise.reject(e.response.data!);
        }
    }

    filterNotes = async (initialLoad: boolean = false) => {
        this.setLoading(true);
        try {
            if (this.filterValues.agreementId === this.lastAgreementIdLoaded) {
                this.setLoading(false);
                return;
            }

            const { total, notes } = await agent.Notes.filter(this.filterValues);
            this.lastAgreementIdLoaded = this.filterValues.agreementId!;

            runInAction(() => {
                this.notes = notes;
                this.total = total;
            });

            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }

    get = async (id: string) => {
        this.setLoading(true);
        let note = await agent.Notes.get(id);

        runInAction(() => {
            this.setLoading(false);
        });

        return note;
    }

    selectNote = (selectedNote: Note) => this.note = selectedNote;

    setLoading = (state: boolean) => this.isLoading = state;

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterNotes();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterNotes();
    }

    setFilterValues = (filters: Filters) => this.filterValues = filters;
}