import { observer } from 'mobx-react-lite';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { OptionSelect } from '../models/option';


const ContainerStyled = styled.div<{ isMobile?: boolean }>`
    width:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: ${p => p.isMobile ? 'self-start' : 'flex-end'};
    justify-content: flex-start;

    & .field input {
        width: 245px !important;
    }
    z-index: 1;
    padding-right: 15px;
`;

interface Props {
    text?: string
    placeHolder?: string
    options: OptionSelect[]
    onChange?: (value: string, text: string) => void;
    isMobile?: boolean
}

const DropdownFilter = ({ placeHolder, options, onChange, text, isMobile }: Props) => {

    return (
        <ContainerStyled isMobile={isMobile}>
            <Dropdown
                style={{ backgroundColor: '#0099da', color: '#fff', width: isMobile? 240: 'auto' }}
                text={text}
                icon={<Icon name='filter' />}
                labeled
                button
                className='icon'
            >
                <Dropdown.Menu>
                    {[{ text: 'All', key: 'All', value: 'All' }, ...options].map(a => {
                        return (<Dropdown.Item
                            onClick={(e: any, { text, value }: any) => {
                                let newText = 'All';
                                if (text !== 'All') {
                                    newText = 'By ' + text
                                }
                                onChange!(value, text);
                            }}
                            key={a.key}
                            text={a.text}
                            value={a.value}
                        />);
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </ContainerStyled>
    );
}

export default observer(DropdownFilter);